import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { Col, Row, Button, Space } from "antd";
import { If, Then, Else } from "react-if";

import { EditOutlined } from "@ant-design/icons";
import "../../../scss/module_Worker/monthlyReport.scss";

import EditWorkerTimeSheet from "./EditWorkerTimeSheet";
import { margin, textAlign } from "styled-system";

type Props = {
   daily_Info: any;
};

const WorkerDailyTimeCard: React.FC<Props> = ({ daily_Info }) => {
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [timeStart, setTimeStart] = useState<any>("-- : --");
   const [timeEnd, setTimeEnd] = useState<any>("-- : --");
   const [hubbleCompareStart, setHubbleCompareStart] = useState(false);
   const [hubbleCompareEnd, setHubbleCompareEnd] = useState(false);

   const showModal = () => {
      setIsModalVisible(true);
   };

   let hubble_start = "-- : --";
   let hubble_end = "-- : --";
   let isSunday = false;

   console.log("day:", daily_Info.shifts);
   console.log("day:", moment(daily_Info.shifts.date).day());
   if (moment(daily_Info.shifts.date).day() === 0) {
      isSunday = true;
   }

   if (daily_Info.HubbleStart !== "na") {
      const hs = moment(daily_Info.HubbleStart).format("HH:mm");
      if (hs !== "Invalid date") {
         hubble_start = hs;
      }
   }
   if (daily_Info.HubbleEnd !== "na") {
      const he = moment(daily_Info.HubbleEnd).format("HH:mm");
      if (he !== "Invalid date") {
         hubble_end = he;
      }
   }

   useEffect(() => {
      if (daily_Info.submitedAt !== null) {
         const shift = daily_Info.shifts.workingShifts;
         if (shift !== undefined) {
            let ts = moment(shift[0].start, "HH:mm").format("HH:mm");
            setTimeStart(ts);
            if (hubble_start !== "-- : --") {
               const dhubble = moment(hubble_start, "HH:mm");
               const dkeyin = moment(ts, "HH:mm");
               const delta_ts = moment.duration(dhubble.diff(dkeyin)).asMinutes();
               if (delta_ts > 30) {
                  setHubbleCompareStart(true);
               }
            }

            let te = moment(shift[shift.length - 1].end, "HH:mm").format("HH:mm");
            setTimeEnd(te);
            if (hubble_end !== "-- : --") {
               const dhubble = moment(hubble_end, "HH:mm");
               const dkeyin = moment(te, "HH:mm");
               const delta_te = moment.duration(dkeyin.diff(dhubble)).asMinutes();
               if (delta_te > 30) {
                  setHubbleCompareEnd(true);
               }
            }
         }
      }
   }, [daily_Info, hubble_start, hubble_end]);

   function getCostCodeDescription(daily_Info: any) {
      console.log("daily_Info", daily_Info);
      if (_.isArray(daily_Info.shifts.workingShifts)) {
         let descriptions = daily_Info.shifts.workingShifts
            .map((x: any) => x.costCodeDescription)
            .filter((x: any) => _.isString(x));
         return descriptions[0];
      }

      return "";
   }

   return (
      <div>
         <If condition={daily_Info.isDayOff}>
            <div className={isSunday ? "worker-daily-timecard-sunday" : "worker-daily-timecard-offday"}>
               <Row>
                  <Col span={3}>
                     <div>
                        <h2 style={{ position: "absolute", top: "0px", left: "5px" }}>
                           {moment(daily_Info.date).format("DD")}
                        </h2>
                        <h5
                           style={{
                              position: "absolute",
                              top: "25px",
                              left: "6px",
                              fontWeight: 300,
                           }}
                        >
                           {moment(daily_Info.date).format("MMM")}
                        </h5>
                     </div>
                  </Col>
                  <Col span={18}>
                     <div className="worker-daily-timecard-time">
                        <div style={{ textAlign: "center", marginTop: "12px" }}>
                           Off Day : <span style={{ fontWeight: 500 }}>{getCostCodeDescription(daily_Info)}</span>
                        </div>
                     </div>
                  </Col>

                  <Col span={3}>
                     <Button
                        // shape="circle"
                        icon={<EditOutlined />}
                        style={{ marginTop: "5px", float: "right", marginRight: "5px" }}
                        onClick={() => showModal()}
                     ></Button>
                  </Col>
               </Row>
            </div>
         </If>
         <If condition={!daily_Info.isDayOff}>
            <div className={isSunday ? "worker-daily-timecard-sunday" : "worker-daily-timecard"}>
               <Row>
                  <Col span={3}>
                     <div>
                        <h2 style={{ position: "absolute", top: "0px", left: "5px" }}>
                           {moment(daily_Info.date).format("DD")}
                        </h2>
                        <h5
                           style={{
                              position: "absolute",
                              top: "25px",
                              left: "6px",
                              fontWeight: 300,
                           }}
                        >
                           {moment(daily_Info.date).format("MMM")}
                        </h5>
                     </div>
                  </Col>
                  <Col span={9}>
                     <div className="worker-daily-timecard-time">
                        <Space>
                           {hubbleCompareStart === false && timeStart !== "-- : --" ? (
                              <div className="time-keyin">{timeStart}</div>
                           ) : null}
                           {hubbleCompareStart === true && timeStart !== "-- : --" ? (
                              <div className="time-keyin-hubble-discrepancy">{timeStart}</div>
                           ) : null}
                           {timeStart === "-- : --" && !isSunday ? (
                              <div className="time-keyin-notyet">{timeStart}</div>
                           ) : null}

                           <div className="time-hubble">{hubble_start}</div>
                        </Space>
                     </div>
                  </Col>

                  <Col span={9}>
                     <Space>
                        {hubbleCompareEnd === false && timeEnd !== "-- : --" ? (
                           <div className="time-keyin">{timeEnd}</div>
                        ) : null}
                        {hubbleCompareEnd === true && timeEnd !== "-- : --" ? (
                           <div className="time-keyin-hubble-discrepancy">{timeEnd}</div>
                        ) : null}
                        {timeEnd === "-- : --" && !isSunday ? <div className="time-keyin-notyet">{timeEnd}</div> : null}

                        <div className="time-hubble">{hubble_end}</div>
                     </Space>
                  </Col>

                  <Col span={3}>
                     <Button
                        // shape="circle"
                        icon={<EditOutlined />}
                        style={{ marginTop: "5px", float: "right", marginRight: "5px" }}
                        onClick={() => showModal()}
                     ></Button>
                  </Col>
               </Row>
            </div>
         </If>

         <EditWorkerTimeSheet
            isModalVisible={isModalVisible}
            daily_Info={daily_Info}
            setIsModalVisible={setIsModalVisible}
         />
      </div>
   );
};

export default WorkerDailyTimeCard;
