import { useEffect, useState } from 'react';
import {
  VStack,
  Center,
  Button,
  Box,
  Pressable,
  HStack,
  Text,
  Heading,
  Spinner,
} from 'native-base';
import {
  FormatPainterOutlined,
  AppstoreOutlined,
  TableOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { selectProject, setProject } from '../../features/app/projectSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
  Tooltip as TooltipChart,
  ReferenceLine,
  Line,
  LineChart,
} from 'recharts';
import axios from 'axios';
import { message } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { controlProject } from '../module_BimApp/AdminFunction'

let moduleData: any = null;
export default function ProjectPage(props: any) {
  const [dataPPVC_PrecastBar, setDataPPVC_PrecastBar] = useState([]);
  const dispatch = useAppDispatch();
  const project = useAppSelector<any>(selectProject);
  useEffect(() => {
    if (!project.projectId)
      controlProject(props, dispatch, setProject)
  }, [])
  useEffect(() => {
    if (project.projectId)
      handlePPVC();
  }, [project]);

  const handlePPVC = async () => {
    try {
      if (
        project.typeProject !== 'None' &&
        project.sharePointId !== ''
      ) {
        if (project.typeProject === 'PPVC/PBU') {
          let res = await axios.get('/api/wohhup/getModules', {
            params: { siteCode: project.sharePointId },
          });
          if (res && res.data.isSuccessful) {
            moduleData = res.data.result;
            setupPieChartPPVC();
          }
        } else {
          let res = await axios.get('/api/wohhup/getModules', {
            params: { siteCode: project.sharePointId },
          });
          if (res && res.data.isSuccessful) {
            moduleData = res.data.result;
            let res1 = await axios.get('/api/customproperty/get-custom-ppvc', {
              params: { projectKey: project.sharePointId },
            });
            combineData(res1.data.ppvc);
          }
        }
      } else {
        if (
          project.typeProject === 'None' &&
          project.sharePointId !== ''
        )
          message.warning(
            'Please, select type of project if you want to see PPVC/Precast DashBoard'
          );
      }
    } catch { }
  };
  const combineData = (ppvc: any) => {
    _.forEach(moduleData, (item) => {
      let index = _.findIndex(ppvc, (o: any) => {
        return o.code.toLowerCase() === item.code.toLowerCase();
      });
      if (index >= 0) {
        if (ppvc[index].newCode !== '' && ppvc[index].date) {
          item.actions.push({
            actionName: `Module Installed on ${project.sharePointId}`,
            completedDate: moment(ppvc[index].date, 'DD-MM-YYYY').toDate(),
            currentSiteCode: project.sharePointId,
            currentSiteStorage: null,
          });
        }
      }
    });
    setupPieChartPPVC();
  };
  const setupPieChartPPVC = () => {
    let { dataPPVC, actionMaxDate, actionMinDate } = handleModulePPVCAndPrecast(
      moduleData,
      project.typeProject,
      project.sharePointId
    );
    let state = {
      loading: false,
      timeValue: {
        min: moment(actionMinDate.getTime()).startOf('months'),
        max: moment(actionMaxDate.getTime()).endOf('months'),
      },
      dataPPVC: dataPPVC,
      format: 'MM/YY',
    };

    let tempPPVC: any =
      project.typeProject === 'PPVC/PBU'
        ? {
          'Installed on Site': [],
          'Delivered to Site': [],
          'Fit Out Completed': [],
          'Delivered to Fit-out Yard': [],
          'Casting Completed': [],
        }
        : {
          'Installed on Site': [],
          'Delivered to Site': [],
          'Casting Completed': [],
        };
    let tempData: any =
      project.typeProject === 'PPVC/PBU'
        ? {
          'Installed on Site': {},
          'Delivered to Site': {},
          'Fit Out Completed': {},
          'Delivered to Fit-out Yard': {},
          'Casting Completed': {},
        }
        : {
          'Installed on Site': {},
          'Delivered to Site': {},
          'Casting Completed': {},
        };
    let countAll: any =
      project.typeProject === 'PPVC/PBU'
        ? {
          'Installed on Site': 0,
          'Delivered to Site': 0,
          'Fit Out Completed': 0,
          'Delivered to Fit-out Yard': 0,
          'Casting Completed': 0,
        }
        : {
          'Installed on Site': 0,
          'Delivered to Site': 0,
          'Casting Completed': 0,
        };
    let color: any =
      project.typeProject === 'PPVC/PBU'
        ? {
          'Installed on Site': '#722ed1',
          'Delivered to Site': '#fa8c16',
          'Fit Out Completed': '#fadb14',
          'Delivered to Fit-out Yard': '#52c41a',
          'Casting Completed': '#1890ff',
        }
        : {
          'Installed on Site': '#722ed1',
          'Delivered to Site': '#fa8c16',
          'Casting Completed': '#1890ff',
        };
    let countPart: any = {};
    let tempBarChart: any = {};
    let allDataCount = dataPPVC.length;
    _.forEach(dataPPVC, (v) => {
      let code = v.mark.split('-')[0];
      if (v.infoData.status !== '' && v.modifiedDate !== null) {
        _.forEach(v.listTime, (list) => {
          if (
            state.timeValue.min.valueOf() <= list.time &&
            state.timeValue.max.valueOf() >= list.time
          ) {
            let time = moment(list.time).format(state.format);
            if (!tempBarChart[time])
              tempBarChart[time] =
                project.typeProject === 'PPVC/PBU'
                  ? {
                    'Casting Completed': 0,
                    'Delivered to Fit-out Yard': 0,
                    'Fit Out Completed': 0,
                    'Delivered to Site': 0,
                    'Installed on Site': 0,
                    date: list.time,
                  }
                  : {
                    'Casting Completed': 0,
                    'Delivered to Site': 0,
                    'Installed on Site': 0,
                    date: list.time,
                  };
            if (list.status === 'Casting Completed') {
              tempBarChart[time]['Casting Completed']++;
            }
            if (
              project.typeProject === 'PPVC/PBU' &&
              list.status === 'Delivered to Fit-out Yard'
            ) {
              tempBarChart[time]['Delivered to Fit-out Yard']++;
            }
            if (
              project.typeProject === 'PPVC/PBU' &&
              list.status === 'Fit Out Completed'
            ) {
              tempBarChart[time]['Fit Out Completed']++;
            }
            if (list.status === 'Delivered to Site') {
              tempBarChart[time]['Delivered to Site']++;
            }
            if (list.status === 'Installed on Site') {
              tempBarChart[time]['Installed on Site']++;
            }
          }
        });
      }
      if (!countPart[code]) countPart[code] = 0;
      countPart[code] = countPart[code] + 1;
      if (v.status.includes('Installed on Site')) {
        countAll['Installed on Site']++;

        if (!tempData['Installed on Site'][code])
          tempData['Installed on Site'][code] = 0;
        tempData['Installed on Site'][code] =
          tempData['Installed on Site'][code] + 1;
      }
      if (v.status.includes('Delivered to Site')) {
        countAll['Delivered to Site']++;
        if (!tempData['Delivered to Site'][code])
          tempData['Delivered to Site'][code] = 0;
        tempData['Delivered to Site'][code] =
          tempData['Delivered to Site'][code] + 1;
      }
      if (
        project.typeProject === 'PPVC/PBU' &&
        v.status.includes('Fit Out Completed')
      ) {
        countAll['Fit Out Completed']++;
        if (!tempData['Fit Out Completed'][code])
          tempData['Fit Out Completed'][code] = 0;
        tempData['Fit Out Completed'][code] =
          tempData['Fit Out Completed'][code] + 1;
      }
      if (
        project.typeProject === 'PPVC/PBU' &&
        v.status.includes('Delivered to Fit-out Yard')
      ) {
        countAll['Delivered to Fit-out Yard']++;
        if (!tempData['Delivered to Fit-out Yard'][code])
          tempData['Delivered to Fit-out Yard'][code] = 0;
        tempData['Delivered to Fit-out Yard'][code] =
          tempData['Delivered to Fit-out Yard'][code] + 1;
      }
      if (v.status.includes('Casting Completed')) {
        countAll['Casting Completed']++;
        if (!tempData['Casting Completed'][code])
          tempData['Casting Completed'][code] = 0;
        tempData['Casting Completed'][code] =
          tempData['Casting Completed'][code] + 1;
      }
    });
    _.forEach(tempData, (item, k) => {
      let percentage = parseFloat(
        ((countAll[k] / allDataCount) * 100).toFixed(1)
      );
      tempPPVC[k].push({
        name: k,
        value: percentage,
        color: color[k],
        string: `${k}: ${countAll[k]}/${allDataCount} (${percentage}%)`,
      });
    });
    let tempBar: any = [];
    _.forEach(tempBarChart, (v, k) => {
      tempBar.push(
        project.typeProject === 'PPVC/PBU'
          ? {
            time: k,
            'Casting Completed': v['Casting Completed'],
            'Delivered to Fit-out Yard': v['Delivered to Fit-out Yard'],
            'Fit Out Completed': v['Fit Out Completed'],
            'Delivered to Site': v['Delivered to Site'],
            'Installed on Site': v['Installed on Site'],
            date: v.date,
          }
          : {
            time: k,
            'Casting Completed': v['Casting Completed'],
            'Delivered to Site': v['Delivered to Site'],
            'Installed on Site': v['Installed on Site'],
            date: v.date,
          }
      );
    });
    tempBar.sort((a: any, b: any) => {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    });
    let tempAccumulative =
      project.typeProject === 'PPVC/PBU'
        ? {
          'Casting Completed Accumulative': 0,
          'Delivered to Fit-out Yard Accumulative': 0,
          'Fit Out Completed Accumulative': 0,
          'Delivered to Site Accumulative': 0,
          'Installed on Site Accumulative': 0,
        }
        : {
          'Casting Completed Accumulative': 0,
          'Delivered to Site Accumulative': 0,
          'Installed on Site Accumulative': 0,
        };
    _.forEach(tempBar, (v) => {
      tempAccumulative['Casting Completed Accumulative'] =
        tempAccumulative['Casting Completed Accumulative'] +
        v['Casting Completed'];
      if (project.typeProject === 'PPVC/PBU') {
        tempAccumulative['Delivered to Fit-out Yard Accumulative'] =
          tempAccumulative['Delivered to Fit-out Yard Accumulative'] +
          v['Delivered to Fit-out Yard'];
        tempAccumulative['Fit Out Completed Accumulative'] =
          tempAccumulative['Fit Out Completed Accumulative'] +
          v['Fit Out Completed'];
      }
      tempAccumulative['Delivered to Site Accumulative'] =
        tempAccumulative['Delivered to Site Accumulative'] +
        v['Delivered to Site'];
      tempAccumulative['Installed on Site Accumulative'] =
        tempAccumulative['Installed on Site Accumulative'] +
        v['Installed on Site'];

      v['Casting Completed Accumulative'] =
        tempAccumulative['Casting Completed Accumulative'];
      if (project.typeProject === 'PPVC/PBU') {
        v['Delivered to Fit-out Yard Accumulative'] =
          tempAccumulative['Delivered to Fit-out Yard Accumulative'];
        v['Fit Out Completed Accumulative'] =
          tempAccumulative['Fit Out Completed Accumulative'];
      }
      v['Delivered to Site Accumulative'] =
        tempAccumulative['Delivered to Site Accumulative'];
      v['Installed on Site Accumulative'] =
        tempAccumulative['Installed on Site Accumulative'];
    });
    setDataPPVC_PrecastBar(tempBar);
    // this.setState({
    //   dataPPVC_Precast: tempPPVC,
    //   dataPPVC_PrecastBar: tempBar,
    //   fixDataPPVC_PrecastBar: tempBar,
    // });
  };
  const handleChartPPVC = (value: any) => {
    this.setState({ format: value }, () => {
      let temp: any = {};
      _.forEach(this.state.fixDataPPVC_PrecastBar, (v) => {
        let time = moment(v.date).format(value);
        if (!temp[time])
          temp[time] =
            this.project.typeProject === 'PPVC/PBU'
              ? {
                'Casting Completed': 0,
                'Delivered to Fit-out Yard': 0,
                'Fit Out Completed': 0,
                'Delivered to Site': 0,
                'Installed on Site': 0,
                'Casting Completed Accumulative': 0,
                'Delivered to Fit-out Yard Accumulative': 0,
                'Fit Out Completed Accumulative': 0,
                'Delivered to Site Accumulative': 0,
                'Installed on Site Accumulative': 0,
                time: time,
                date: v.date,
              }
              : {
                'Casting Completed': 0,
                'Delivered to Site': 0,
                'Installed on Site': 0,
                'Casting Completed Accumulative': 0,
                'Delivered to Site Accumulative': 0,
                'Installed on Site Accumulative': 0,
                time: time,
                date: v.date,
              };
        temp[time]['Casting Completed'] =
          temp[time]['Casting Completed'] + v['Casting Completed'];
        if (this.project.typeProject === 'PPVC/PBU') {
          temp[time]['Delivered to Fit-out Yard'] =
            temp[time]['Delivered to Fit-out Yard'] +
            v['Delivered to Fit-out Yard'];
          temp[time]['Fit Out Completed'] =
            temp[time]['Fit Out Completed'] + v['Fit Out Completed'];
        }
        temp[time]['Delivered to Site'] =
          temp[time]['Delivered to Site'] + v['Delivered to Site'];
        temp[time]['Installed on Site'] =
          temp[time]['Installed on Site'] + v['Installed on Site'];
      });
      let tempBar = _.values(temp);
      let tempAccumulative =
        this.project.typeProject === 'PPVC/PBU'
          ? {
            'Casting Completed Accumulative': 0,
            'Delivered to Fit-out Yard Accumulative': 0,
            'Fit Out Completed Accumulative': 0,
            'Delivered to Site Accumulative': 0,
            'Installed on Site Accumulative': 0,
          }
          : {
            'Casting Completed Accumulative': 0,
            'Delivered to Site Accumulative': 0,
            'Installed on Site Accumulative': 0,
          };
      _.forEach(tempBar, (v) => {
        tempAccumulative['Casting Completed Accumulative'] =
          tempAccumulative['Casting Completed Accumulative'] +
          v['Casting Completed'];
        if (this.project.typeProject === 'PPVC/PBU') {
          tempAccumulative['Delivered to Fit-out Yard Accumulative'] =
            tempAccumulative['Delivered to Fit-out Yard Accumulative'] +
            v['Delivered to Fit-out Yard'];
          tempAccumulative['Fit Out Completed Accumulative'] =
            tempAccumulative['Fit Out Completed Accumulative'] +
            v['Fit Out Completed'];
        }
        tempAccumulative['Delivered to Site Accumulative'] =
          tempAccumulative['Delivered to Site Accumulative'] +
          v['Delivered to Site'];
        tempAccumulative['Installed on Site Accumulative'] =
          tempAccumulative['Installed on Site Accumulative'] +
          v['Installed on Site'];

        v['Casting Completed Accumulative'] =
          tempAccumulative['Casting Completed Accumulative'];
        if (this.project.typeProject === 'PPVC/PBU') {
          v['Delivered to Fit-out Yard Accumulative'] =
            tempAccumulative['Delivered to Fit-out Yard Accumulative'];
          v['Fit Out Completed Accumulative'] =
            tempAccumulative['Fit Out Completed Accumulative'];
        }
        v['Delivered to Site Accumulative'] =
          tempAccumulative['Delivered to Site Accumulative'];
        v['Installed on Site Accumulative'] =
          tempAccumulative['Installed on Site Accumulative'];
      });

      this.setState({ dataPPVC_PrecastBar: tempBar });
    });
  };
  const handleModulePPVCAndPrecast = (
    moduleData: any,
    typeProject: any,
    projectKey: any
  ) => {
    let actionMinDate: any = null;
    let actionMaxDate: any = null;
    let dataPPVC: any = [];
    let tempData: any = [];
    let currentData: any = {
      castingCompletedData: [],
      deliveredToFitouYardData: [],
      completedFitoutData: [],
      devliveredToSiteData: [],
      installOnSiteData: [],

      castingCompletedDataTargetCount: 0,
      deliveredToFitouYardDataTargetCount: 0,
      completedFitoutTargetCount: 0,
      devliveredToSiteDataTargetCount: 0,
      installOnSiteDataTargetCount: 0,
    };
    _.forEach(moduleData, (item) => {
      let check = true;
      if (item.actions && item.actions.length > 0) {
        let tempItem: any = null;
        let temp: any = {
          dbId: item.dbId,
          mark: item.code,
          modifiedDate: null,
          status: [],
          listTime: [],
          infoData: {
            name: item.code,
            length: item.length ? item.length : 'N/A',
            height: item.height ? item.height : 'N/A',
            width: item.width ? item.width : 'N/A',
            volume: item.volume ? item.volume : 'N/A',
            carcassWeight: item.carcassWeight ? item.carcassWeight : 'N/A',
            ppvcWeight: item.ppvcWeight ? item.ppvcWeight : 'N/A',
            status: '',
          },
        };
        let checkDeliveryFitOut: any = true;
        let currentSiteCode: any = '';
        _.forEach(item.actions, (action) => {
          if (action.completedDate) {
            let completedDate = new Date(action.completedDate);
            //#region //? get time min max
            if (actionMinDate) {
              if (actionMinDate > completedDate) {
                actionMinDate = completedDate;
              }
            } else {
              actionMinDate = completedDate;
            }
            if (actionMaxDate) {
              if (actionMaxDate < completedDate) {
                actionMaxDate = completedDate;
              }
            } else {
              actionMaxDate = completedDate;
            }
            //#endregion
            if (typeProject === 'PPVC/PBU') {
              if (action.actionName.includes('Start Cast at')) {
                currentSiteCode = action.currentSiteCode;
              }
              if (action.actionName === 'Casting Completed') {
                currentData.castingCompletedData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                });
                let index = _.findIndex(temp.listTime, (o: any) => {
                  return o.status === 'Casting Completed';
                });
                if (index >= 0) {
                  temp.listTime[index].time = completedDate.getTime();
                } else {
                  temp.listTime.push({
                    time: completedDate.getTime(),
                    status: 'Casting Completed',
                  });
                }
                temp.modifiedDate = completedDate;
                temp.infoData.status = 'Casting Completed';
                temp.infoData.date = completedDate
                  ? moment(completedDate).format('DD/MM/YYYY')
                  : 'N/A';
                temp.status.push('Casting Completed');
                check = false;
                tempItem = {
                  content: item.code,
                  status: 'Casting Completed',
                  startPlan: '',
                  endPlan: '',
                  startActual: '',
                  endActual: moment(completedDate).format('DD-MM-YYYY'),
                };
              } else if (
                action.actionName.includes('Received Delivery at') &&
                checkDeliveryFitOut &&
                currentSiteCode !== action.currentSiteCode
              ) {
                //&&projectKey.toLowerCase() !== action.currentSiteCode.toLowerCase()
                currentData.deliveredToFitouYardData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                });
                let index = _.findIndex(temp.listTime, (o: any) => {
                  return o.status === 'Delivered to Fit-out Yard';
                });
                if (index >= 0) {
                  temp.listTime[index].time = completedDate.getTime();
                } else {
                  temp.listTime.push({
                    time: completedDate.getTime(),
                    status: 'Delivered to Fit-out Yard',
                  });
                }
                temp.modifiedDate = completedDate;
                temp.infoData.status = 'Delivered to Fit-out Yard';
                temp.infoData.date = completedDate
                  ? moment(completedDate).format('DD/MM/YYYY')
                  : 'N/A';
                temp.status.push('Delivered to Fit-out Yard');
                check = false;
                checkDeliveryFitOut = false;
                tempItem = {
                  content: item.code,
                  status: 'Delivered to Fit-out Yard',
                  startPlan: '',
                  endPlan: '',
                  startActual: '',
                  endActual: moment(completedDate).format('DD-MM-YYYY'),
                };
              } else if (
                action.actionName.includes('Ready for Delivery from') &&
                currentSiteCode !== action.currentSiteCode
              ) {
                currentData.completedFitoutData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                });
                let index = _.findIndex(temp.listTime, (o: any) => {
                  return o.status === 'Fit Out Completed';
                });
                if (index >= 0) {
                  temp.listTime[index].time = completedDate.getTime();
                } else {
                  temp.listTime.push({
                    time: completedDate.getTime(),
                    status: 'Fit Out Completed',
                  });
                }
                temp.modifiedDate = completedDate;
                temp.infoData.status = 'Fit Out Completed';
                temp.infoData.date = completedDate
                  ? moment(completedDate).format('DD/MM/YYYY')
                  : 'N/A';
                temp.status.push('Fit Out Completed');
                check = false;
                tempItem = {
                  content: item.code,
                  status: 'Fit Out Completed',
                  startPlan: '',
                  endPlan: '',
                  startActual: '',
                  endActual: moment(completedDate).format('DD-MM-YYYY'),
                };
              } else if (
                action.actionName.includes('Received Delivery at') &&
                projectKey.toLowerCase() ===
                action.currentSiteCode.toLowerCase()
              ) {
                currentData.devliveredToSiteData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                });
                let index = _.findIndex(temp.listTime, (o: any) => {
                  return o.status === 'Delivered to Site';
                });
                if (index >= 0) {
                  temp.listTime[index].time = completedDate.getTime();
                } else {
                  temp.listTime.push({
                    time: completedDate.getTime(),
                    status: 'Delivered to Site',
                  });
                }
                temp.modifiedDate = completedDate;
                temp.infoData.status = 'Delivered to Site';
                temp.infoData.date = completedDate
                  ? moment(completedDate).format('DD/MM/YYYY')
                  : 'N/A';
                temp.status.push('Delivered to Site');
                check = false;
                tempItem = {
                  content: item.code,
                  status: 'Delivered to Site',
                  startPlan: '',
                  endPlan: '',
                  startActual: '',
                  endActual: moment(completedDate).format('DD-MM-YYYY'),
                };
              } else if (action.actionName.includes('Module Installed on')) {
                currentData.installOnSiteData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                });
                let index = _.findIndex(temp.listTime, (o: any) => {
                  return o.status === 'Installed on Site';
                });
                if (index >= 0) {
                  temp.listTime[index].time = completedDate.getTime();
                } else {
                  temp.listTime.push({
                    time: completedDate.getTime(),
                    status: 'Installed on Site',
                  });
                }
                temp.modifiedDate = completedDate;
                temp.infoData.status = 'Installed on Site';
                temp.infoData.date = completedDate
                  ? moment(completedDate).format('DD/MM/YYYY')
                  : 'N/A';
                temp.status.push('Installed on Site');
                check = false;
                tempItem = {
                  content: item.code,
                  status: 'Installed on Site',
                  startPlan: '',
                  endPlan: '',
                  startActual: '',
                  endActual: moment(completedDate).format('DD-MM-YYYY'),
                };
              }
            } else if (typeProject === 'PreCast') {
              if (
                (action.actionName.includes('Delivering to') &&
                  projectKey.toLowerCase() !==
                  action.currentSiteCode.toLowerCase()) ||
                (action.actionName.includes('Move into') &&
                  projectKey.toLowerCase() !==
                  action.currentSiteCode.toLowerCase()) ||
                (action.actionName.includes('Ready for Delivery') &&
                  projectKey.toLowerCase() !==
                  action.currentSiteCode.toLowerCase()) ||
                (action.actionName.includes('Received Delivery') &&
                  projectKey.toLowerCase() !==
                  action.currentSiteCode.toLowerCase()) ||
                action.actionName === 'Casting Completed'
              ) {
                currentData.castingCompletedData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                });
                let index = _.findIndex(temp.listTime, (o: any) => {
                  return o.status === 'Casting Completed';
                });
                if (index >= 0) {
                  temp.listTime[index].time = completedDate.getTime();
                } else {
                  temp.listTime.push({
                    time: completedDate.getTime(),
                    status: 'Casting Completed',
                  });
                }
                temp.modifiedDate = completedDate;
                temp.infoData.status = 'Casting Completed';
                temp.infoData.date = completedDate
                  ? moment(completedDate).format('DD/MM/YYYY')
                  : 'N/A';
                temp.status.push('Casting Completed');
                check = false;
                tempItem = {
                  content: item.code,
                  status: 'Casting Completed',
                  startPlan: '',
                  endPlan: '',
                  startActual: '',
                  endActual: moment(completedDate).format('DD-MM-YYYY'),
                };
              } else if (
                (action.actionName.includes('Received Delivery at') &&
                  projectKey.toLowerCase() ===
                  action.currentSiteCode.toLowerCase()) ||
                (action.actionName.includes('Move into at') &&
                  projectKey.toLowerCase() ===
                  action.currentSiteCode.toLowerCase())
              ) {
                currentData.devliveredToSiteData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                });
                let index = _.findIndex(temp.listTime, (o: any) => {
                  return o.status === 'Delivered to Site';
                });
                if (index >= 0) {
                  temp.listTime[index].time = completedDate.getTime();
                } else {
                  temp.listTime.push({
                    time: completedDate.getTime(),
                    status: 'Delivered to Site',
                  });
                }
                temp.modifiedDate = completedDate;
                temp.infoData.status = 'Delivered to Site';
                temp.infoData.date = completedDate
                  ? moment(completedDate).format('DD/MM/YYYY')
                  : 'N/A';
                temp.status.push('Delivered to Site');
                check = false;
                tempItem = {
                  content: item.code,
                  status: 'Delivered to Site',
                  startPlan: '',
                  endPlan: '',
                  startActual: '',
                  endActual: moment(completedDate).format('DD-MM-YYYY'),
                };
              } else if (action.actionName.includes('Module Installed on')) {
                currentData.installOnSiteData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: completedDate,
                });
                let index = _.findIndex(temp.listTime, (o: any) => {
                  return o.status === 'Installed on Site';
                });
                if (index >= 0) {
                  temp.listTime[index].time = completedDate.getTime();
                } else {
                  temp.listTime.push({
                    time: completedDate.getTime(),
                    status: 'Installed on Site',
                  });
                }
                temp.modifiedDate = completedDate;
                temp.infoData.status = 'Installed on Site';
                temp.infoData.date = completedDate
                  ? moment(completedDate).format('DD/MM/YYYY')
                  : 'N/A';
                temp.status.push('Installed on Site');
                check = false;
                tempItem = {
                  content: item.code,
                  status: 'Installed on Site',
                  startPlan: '',
                  endPlan: '',
                  startActual: '',
                  endActual: moment(completedDate).format('DD-MM-YYYY'),
                };
              }
            }
          }
        });
        if (temp.modifiedDate !== null) {
          if (
            _.findIndex(temp.listTime, (o: any) => {
              return o.status === 'Installed on Site';
            }) >= 0
          ) {
            let index = _.findIndex(temp.listTime, (o: any) => {
              return o.status === 'Delivered to Site';
            });
            if (index < 0) {
              currentData.devliveredToSiteData.push({
                dbId: item.dbId,
                mark: item.code,
                completedDate: temp.modifiedDate,
              });
              temp.status.splice(
                temp.listTime[temp.listTime.length - 2],
                0,
                'Delivered to Site'
              );
              temp.listTime.splice(temp.listTime[temp.listTime.length - 2], 0, {
                time: temp.listTime[temp.listTime.length - 1].time,
                status: 'Delivered to Site',
              });
              tempItem = {
                content: item.code,
                status: 'Delivered to Site',
                startPlan: '',
                endPlan: '',
                startActual: '',
                endActual: moment(temp.modifiedDate).format('DD-MM-YYYY'),
              };
            }
          }
          if (typeProject === 'PPVC/PBU') {
            if (
              _.findIndex(temp.listTime, (o: any) => {
                return o.status === 'Delivered to Site';
              }) >= 0
            ) {
              let index = _.findIndex(temp.listTime, (o: any) => {
                return o.status === 'Fit Out Completed';
              });
              if (index < 0) {
                currentData.completedFitoutData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: temp.modifiedDate,
                });
                temp.status.splice(
                  temp.listTime[temp.listTime.length - 3],
                  0,
                  'Fit Out Completed'
                );
                temp.listTime.splice(
                  temp.listTime[temp.listTime.length - 3],
                  0,
                  {
                    time: temp.listTime[temp.listTime.length - 2].time,
                    status: 'Fit Out Completed',
                  }
                );
                tempItem = {
                  content: item.code,
                  status: 'Fit Out Completed',
                  startPlan: '',
                  endPlan: '',
                  startActual: '',
                  endActual: moment(temp.modifiedDate).format('DD-MM-YYYY'),
                };
              }
            }

            if (
              _.findIndex(temp.listTime, (o: any) => {
                return o.status === 'Fit Out Completed';
              }) >= 0
            ) {
              let index = _.findIndex(temp.listTime, (o: any) => {
                return o.status === 'Delivered to Fit-out Yard';
              });
              if (index < 0) {
                currentData.deliveredToFitouYardData.push({
                  dbId: item.dbId,
                  mark: item.code,
                  completedDate: temp.modifiedDate,
                });
                temp.status.splice(
                  temp.listTime[temp.listTime.length - 4],
                  0,
                  'Delivered to Fit-out Yard'
                );
                temp.listTime.splice(
                  temp.listTime[temp.listTime.length - 4],
                  0,
                  {
                    time: temp.listTime[temp.listTime.length - 3].time,
                    status: 'Delivered to Fit-out Yard',
                  }
                );
                tempItem = {
                  content: item.code,
                  status: 'Delivered to Fit-out Yard',
                  startPlan: '',
                  endPlan: '',
                  startActual: '',
                  endActual: moment(temp.modifiedDate).format('DD-MM-YYYY'),
                };
              }
            }
          }
          dataPPVC.push(temp);
          if (tempItem) {
            tempData.push(tempItem);
          }
        }
      }
      if (check) {
        dataPPVC.push({
          dbId: item.dbId,
          mark: item.code,
          modifiedDate: null,
          status: [''],
          infoData: {
            name: item.code,
            length: item.length ? item.length : 'N/A',
            height: item.height ? item.height : 'N/A',
            width: item.width ? item.width : 'N/A',
            volume: item.volume ? item.volume : 'N/A',
            carcassWeight: item.carcassWeight ? item.carcassWeight : 'N/A',
            ppvcWeight: item.ppvcWeight ? item.ppvcWeight : 'N/A',
            status: '',
          },
        });
      }
    });
    return { dataPPVC, actionMinDate, actionMaxDate, tempData };
  };
  return (
    <>
      {/* <Center height={'100%'} justifyContent='center'>
      <HStack space={2}>
        <Heading color='yellow.600'>Idd App</Heading>
        <Spinner color='yellow.600' accessibilityLabel='Loading posts' />
      </HStack>
    </Center> */}
      <VStack space={2}>
        <Center>
          <Box bg='white' marginTop={5} safeAreaTop width={'95%'}>
            <Center>
              <Box
                bg='white'
                shadow={2}
                width={'100%'}
                height={300}
              >
                <ResponsiveContainer width='100%' height='100%'>
                  <BarChart syncId='anyId' data={dataPPVC_PrecastBar}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='time' />
                    <YAxis />
                    <TooltipChart />
                    <ReferenceLine y={0} stroke='#000' />
                    <Brush dataKey='time' height={30} stroke='#8884d8' />
                    <Bar
                      dataKey='Casting Completed'
                      fill='#1890ff'
                    // onClick={this.handleClickBar.bind(this, 'Casting Completed')}
                    />
                    {project.typeProject === 'PPVC/PBU' && (
                      <Bar
                        dataKey='Delivered to Fit-out Yard'
                        fill='#52c41a'
                      // onClick={this.handleClickBar.bind(
                      //   this,
                      //   'Delivered to Fit-out Yard'
                      // )}
                      />
                    )}
                    {project.typeProject === 'PPVC/PBU' && (
                      <Bar
                        dataKey='Fit Out Completed'
                        fill='#fadb14'
                      // onClick={this.handleClickBar.bind(
                      //   this,
                      //   'Fit Out Completed'
                      // )}
                      />
                    )}
                    <Bar
                      dataKey='Delivered to Site'
                      fill='#fa8c16'
                    // onClick={this.handleClickBar.bind(this, 'Delivered to Site')}
                    />
                    <Bar
                      dataKey='Installed on Site'
                      fill='#722ed1'
                    //onClick={this.handleClickBar.bind(this, 'Installed on Site')}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>

              <Box
                bg='white'
                shadow={2}
                // rounded='lg'
                width={'100%'}
                height={300}
              >
                <ResponsiveContainer width='100%' height='100%'>
                  <LineChart syncId='anyId' data={dataPPVC_PrecastBar}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='time' />
                    <YAxis />
                    <TooltipChart />
                    <ReferenceLine y={0} stroke='#000' />
                    <Line
                      dataKey='Casting Completed Accumulative'
                      stroke='#1890ff'
                      dot={<></>}
                    // activeDot={{
                    //   onClick: this.handleClickLine.bind(
                    //     this,
                    //     'Casting Completed'
                    //   ),
                    // }}
                    />
                    {project.typeProject === 'PPVC/PBU' && (
                      <Line
                        dataKey='Delivered to Fit-out Yard Accumulative'
                        stroke='#52c41a'
                        dot={<></>}
                      // activeDot={{
                      //   onClick: this.handleClickLine.bind(
                      //     this,
                      //     'Delivered to Fit-out Yard'
                      //   ),
                      // }}
                      />
                    )}
                    {project.typeProject === 'PPVC/PBU' && (
                      <Line
                        dataKey='Fit Out Completed Accumulative'
                        stroke='#fadb14'
                        dot={<></>}
                      // activeDot={{
                      //   onClick: this.handleClickLine.bind(
                      //     this,
                      //     'Fit Out Completed'
                      //   ),
                      // }}
                      />
                    )}
                    <Line
                      dataKey='Delivered to Site Accumulative'
                      stroke='#fa8c16'
                      dot={<></>}
                    // activeDot={{
                    //   onClick: this.handleClickLine.bind(
                    //     this,
                    //     'Delivered to Site'
                    //   ),
                    // }}
                    />
                    <Line
                      dataKey='Installed on Site Accumulative'
                      stroke='#722ed1'
                      dot={<></>}
                    // activeDot={{
                    //   onClick: this.handleClickLine.bind(
                    //     this,
                    //     'Installed on Site'
                    //   ),
                    // }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </Center>

          </Box>
        </Center>
      </VStack>
    </>
  );
}
