import { Icon } from "antd-mobile";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { apiFetchPublicUrl } from "../../module_DMS/api/dmsApi";
import { colorTextRow, colorType } from "../../module_DMS/constants";
import { getFileNameFromLink, getInfoValueFromRefDataForm, getRefStringWithVersion } from "../../module_DMS/utils";
import { trackingUserUsing } from "../../module_BimApp/AdminFunction";
import { useSelector } from "react-redux";

const preLink = window.location.origin.includes("https://idd.wohhup.com")
   ? "https://idd.wohhup.com/dms-viewer/"
   : "http://localhost:3000/dms-viewer/";

const DmsPageItem = (props: any) => {
   const {
      history,
      location: { state },
   } = props;

   const {
      location: {
         state: { projectId, data },
      },
   } = history;

   const { rfaInfo, status, company, refType, rowMultiForm: rowMulti, rfaDrawings } = data;

   const {
      location: { state: historyState },
   } = history;

   const [rowsToDisplay, setRowsToDisplay] = useState([]);

   const [rowMultiData, setRowMultiData] = useState<any>({});
   const email = useSelector((state: any) => state.user?.value?.email);

   useEffect(() => {
      trackingUserUsing(email, projectId, "DMS (Mobile)");
      if (refType === "rfa") {
         const refName = getRefStringWithVersion(rfaInfo, "rfa");

         setRowsToDisplay(rfaDrawings);

         const date =
            company === "Woh Hup"
               ? moment(getInfoValueFromRefDataForm(rfaInfo, "submission", "rfa", "date")).format("DD/MM/YY")
               : moment(getInfoValueFromRefDataForm(rfaInfo, "reply", "rfa", "date", company)).format("DD/MM/YY");

         setRowMultiData({ refName, date });
      } else {
         if (company === "Woh Hup") {
            const linkForm =
               refType === "mm"
                  ? getInfoValueFromRefDataForm(rowMulti, "submission", refType, "linkFormNoSignature")
                  : getInfoValueFromRefDataForm(rowMulti, "submission", refType, "linkSignedOffFormSubmit");

            const linkDocuments = [
               ...(getInfoValueFromRefDataForm(rowMulti, "submission", refType, "linkDrawings") || []),
               ...(getInfoValueFromRefDataForm(rowMulti, "submission", refType, "linkDrawingsRfa") || []),
               ...(getInfoValueFromRefDataForm(rowMulti, "submission", refType, "linkRfamAttached") || []),
               ...(getInfoValueFromRefDataForm(rowMulti, "submission", refType, "linkRfiAttached") || []),
               ...(getInfoValueFromRefDataForm(rowMulti, "submission", refType, "linkCviAttached") || []),
            ];

            const refName = getRefStringWithVersion(rowMulti, refType);
            const date = moment(getInfoValueFromRefDataForm(rowMulti, "submission", refType, "date")).format(
               "DD/MM/YY"
            );

            setRowMultiData({
               linkForm,
               linkDocuments,
               refName,
               date,
            });
         } else {
            const linkForm = getInfoValueFromRefDataForm(rowMulti, "reply", refType, "linkFormReply", company);
            const linkDocuments = getInfoValueFromRefDataForm(
               rowMulti,
               "reply",
               refType,
               "linkDocumentsReply",
               company
            );
            const refName = getRefStringWithVersion(rowMulti, refType);
            const date = moment(getInfoValueFromRefDataForm(rowMulti, "reply", refType, "date", company)).format(
               "DD/MM/YY"
            );

            setRowMultiData({
               linkForm,
               linkDocuments,
               refName,
               date,
            });
         }
      }
   }, []);

   const openFile = async (key: string) => {
      try {
         const res = await apiFetchPublicUrl({ key, expire: 1000 });

         window.open(res.data, "_blank");
      } catch (err) {
         console.log(err);
      }
   };

   return (
      <div
         className="flex-container"
         style={{
            margin: 0,
            padding: 10,
            // touchAction: 'pan-down', // SCROLL ERROR
            // overscrollBehaviorY: 'contain' // SCROLL ERROR
         }}
      >
         <Icon
            type="ellipsis"
            onClick={() =>
               history.push({
                  pathname: `/dms/${projectId}`,
                  state: { ...historyState },
               })
            }
            size={"lg"}
            style={{
               position: "fixed",
               bottom: 15,
               left: 15,
               zIndex: 10,
               padding: 3,
               background: colorType.primaryMobile,
               borderRadius: 5,
            }}
         />

         <div style={{ fontWeight: "bold", fontSize: 17, margin: "10px 0" }}>{rowMultiData["refName"]}</div>
         <div style={{ width: "100%", background: colorTextRow[status], paddingLeft: 5 }}>
            <div>
               <span style={{ width: 50 }}>Company: </span>
               <span style={{ fontWeight: "bold" }}>{company}</span>
            </div>

            <div>
               <span style={{ width: 50 }}>Status: </span>
               <span style={{ fontWeight: "bold" }}>{status}</span>
            </div>

            <div>
               <span style={{ width: 50 }}>{company === "Woh Hup" ? "Submission Date: " : "Reply Date: "}</span>
               <span style={{ fontWeight: "bold" }}>{rowMultiData["date"]}</span>
            </div>
         </div>

         <div style={{ marginTop: 10 }}>
            {refType === "rfa" ? (
               rowsToDisplay.map((row: any, i: number) => {
                  return (
                     <div
                        key={i}
                        onClick={() => {
                           const dwgLink =
                              company === "Woh Hup"
                                 ? getInfoValueFromRefDataForm(row, "submission", "rfa", "drawing")
                                 : getInfoValueFromRefDataForm(row, "reply", "rfa", "drawing", company);

                           openFile(dwgLink);
                        }}
                        style={{
                           background: colorType.grey3,
                           padding: 5,
                           marginBottom: 10,
                        }}
                     >
                        <div>
                           {i + 1}) - <span style={{ fontWeight: "bold" }}>{row["Drawing Number"]}</span>
                        </div>
                        <div>{row["Drawing Name"]}</div>
                     </div>
                  );
               })
            ) : (
               <>
                  <div style={{ fontWeight: "bold" }}>{company === "Woh Hup" ? "Submission Form" : "Reply Form"}</div>
                  <div
                     onClick={() => openFile(rowMultiData["linkForm"])}
                     style={{
                        padding: 5,
                        marginBottom: 15,
                        background: colorType.grey3,
                     }}
                  >
                     {getFileNameFromLink(rowMultiData["linkForm"])}
                  </div>

                  <div style={{ fontWeight: "bold" }}>Attachments</div>
                  {(rowMultiData["linkDocuments"] || []).map((fileLink: string, i: number) => (
                     <div
                        key={i}
                        onClick={() => openFile(fileLink)}
                        style={{
                           border: `1px solid ${colorType.grey3}`,
                           padding: 5,
                           marginBottom: 10,
                           background: colorType.grey3,
                        }}
                     >
                        {getFileNameFromLink(fileLink)}
                     </div>
                  ))}
               </>
            )}
         </div>
      </div>
   );
};

export default DmsPageItem;
