import React, { FunctionComponent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Empty, Button, Skeleton } from "antd";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectProject, setProject } from "../../features/app/projectSlice";
import QrArchi from "../module_QrArchi/QrArchi/QrArchi";
import { controlProject, trackingUserUsing } from "../module_BimApp/AdminFunction";
import { LocaleProvider } from "antd-mobile";
import enUS from "antd-mobile/lib/locale-provider/en_US";

function WorkerPage(props: any) {
   const [isLoading, setIsLoading] = useState<boolean>(false);

   return (
      <div className="qr-page">
         <div className="class-content">
            <Skeleton loading={isLoading}>
               <LocaleProvider locale={enUS}>
                  <QrArchi {...props} />
               </LocaleProvider>
            </Skeleton>
         </div>
      </div>
   );
}

export default WorkerPage;
