import { WorkerTimesheetModel, dateInfo } from "../models/WorkerTimeSheetModel";

const fakedata = (): WorkerTimesheetModel[] => {
   const arr_ts: WorkerTimesheetModel[] = [
      {
         id: "1",
         workerName: "Satartha Kumar",
         isCompleted: true,
         dateInfo: [
            {
               date: new Date("2022-08-12"),
               timeStart: new Date("2022-08-12T08:30:00"),
               timeEnd: new Date("2022-08-12T20:30:00"),
               HubbleStart: new Date("2022-08-12T08:32:00"),
               HubbleEnd: new Date("2022-08-12T20:20:00"),
            },
            {
               date: new Date("2022-08-13"),
               timeStart: new Date("2022-08-13T08:32:00"),
               timeEnd: new Date("2022-08-13T21:30:00"),
               HubbleStart: new Date("2022-08-13T08:32:00"),
               HubbleEnd: new Date("2022-08-13T20:20:00"),
            },
            {
               date: new Date("2022-08-14"),
               timeStart: new Date("2022-08-14T07:30:00"),
               timeEnd: new Date("2022-08-14T23:30:00"),
               HubbleStart: new Date("2022-08-14T08:32:00"),
               HubbleEnd: new Date("2022-08-14T20:20:00"),
            },
            {
               date: new Date("2022-08-15"),
               timeStart: new Date("2022-08-15T08:30:00"),
               timeEnd: new Date("2022-08-15T20:30:00"),
               HubbleStart: new Date("2022-08-15T08:32:00"),
               HubbleEnd: new Date("2022-08-15T20:20:00"),
            },
            {
               date: new Date("2022-08-16"),
               timeStart: new Date("2022-08-16T08:30:00"),
               timeEnd: new Date("2022-08-16T20:30:00"),
               HubbleStart: new Date("2022-08-16T08:32:00"),
               HubbleEnd: new Date("2022-08-16T20:20:00"),
            },
            {
               date: new Date("2022-08-17"),
               timeStart: new Date("2022-08-17T08:30:00"),
               timeEnd: new Date("2022-08-17T20:30:00"),
               HubbleStart: new Date("2022-08-17T08:32:00"),
               HubbleEnd: new Date("2022-08-17T20:20:00"),
            },
            {
               date: new Date("2022-08-18"),
               timeStart: new Date("2022-08-18T08:30:00"),
               timeEnd: new Date("2022-08-18T20:30:00"),
               HubbleStart: new Date("2022-08-18T08:32:00"),
               HubbleEnd: new Date("2022-08-18T20:20:00"),
            },
         ],
         isFold: true,
      },
      {
         id: "2",
         workerName: "Ko Ko Oo",
         isCompleted: false,
         dateInfo: [
            {
               date: new Date("2022-08-12"),
               timeStart: new Date("2022-08-12T08:30:00"),
               timeEnd: new Date("2022-08-12T20:30:00"),
               HubbleStart: new Date("2022-08-12T08:32:00"),
               HubbleEnd: new Date("2022-08-12T20:20:00"),
            },
            {
               date: new Date("2022-08-13"),
               timeStart: new Date("2022-08-13T08:30:00"),
               timeEnd: new Date("2022-08-13T20:30:00"),
               HubbleStart: new Date("2022-08-13T08:32:00"),
               HubbleEnd: new Date("2022-08-13T20:20:00"),
            },
            {
               date: new Date("2022-08-14"),
               timeStart: new Date("2022-08-14T08:30:00"),
               timeEnd: new Date("2022-08-14T20:30:00"),
               HubbleStart: new Date("2022-08-14T08:32:00"),
               HubbleEnd: new Date("2022-08-14T20:20:00"),
            },
            {
               date: new Date("2022-08-15"),
               timeStart: new Date("2022-08-15T08:30:00"),
               timeEnd: new Date("2022-08-15T20:30:00"),
               HubbleStart: new Date("2022-08-15T08:32:00"),
               HubbleEnd: new Date("2022-08-15T20:20:00"),
            },
            {
               date: new Date("2022-08-16"),
               timeStart: new Date("2022-08-16T08:30:00"),
               timeEnd: new Date("2022-08-16T20:30:00"),
               HubbleStart: new Date("2022-08-16T08:32:00"),
               HubbleEnd: new Date("2022-08-16T20:20:00"),
            },
            {
               date: new Date("2022-08-17"),
               timeStart: new Date("2022-08-17T08:30:00"),
               timeEnd: new Date("2022-08-17T20:30:00"),
               HubbleStart: new Date("2022-08-17T08:32:00"),
               HubbleEnd: new Date("2022-08-17T20:20:00"),
            },
            {
               date: new Date("2022-08-18"),
               timeStart: new Date("2022-08-18T08:30:00"),
               timeEnd: new Date("2022-08-18T20:30:00"),
               HubbleStart: new Date("2022-08-18T08:32:00"),
               HubbleEnd: new Date("2022-08-18T20:20:00"),
            },
         ],
         isFold: true,
      },

      {
         id: "3",
         workerName: "Thin Zan Tuo",
         isCompleted: true,
         dateInfo: [
            {
               date: new Date("2022-08-12"),
               timeStart: new Date("2022-08-12T08:30:00"),
               timeEnd: new Date("2022-08-12T20:30:00"),
               HubbleStart: new Date("2022-08-12T08:32:00"),
               HubbleEnd: new Date("2022-08-12T20:20:00"),
            },
            {
               date: new Date("2022-08-13"),
               timeStart: new Date("2022-08-13T08:30:00"),
               timeEnd: new Date("2022-08-13T20:30:00"),
               HubbleStart: new Date("2022-08-13T08:32:00"),
               HubbleEnd: new Date("2022-08-13T20:20:00"),
            },
            {
               date: new Date("2022-08-14"),
               timeStart: new Date("2022-08-14T08:30:00"),
               timeEnd: new Date("2022-08-14T20:30:00"),
               HubbleStart: new Date("2022-08-14T08:32:00"),
               HubbleEnd: new Date("2022-08-14T20:20:00"),
            },
            {
               date: new Date("2022-08-15"),
               timeStart: new Date("2022-08-15T08:30:00"),
               timeEnd: new Date("2022-08-15T20:30:00"),
               HubbleStart: new Date("2022-08-15T08:32:00"),
               HubbleEnd: new Date("2022-08-15T20:20:00"),
            },
            {
               date: new Date("2022-08-16"),
               timeStart: new Date("2022-08-16T08:30:00"),
               timeEnd: new Date("2022-08-16T20:30:00"),
               HubbleStart: new Date("2022-08-16T08:32:00"),
               HubbleEnd: new Date("2022-08-16T20:20:00"),
            },
            {
               date: new Date("2022-08-17"),
               timeStart: new Date("2022-08-17T08:30:00"),
               timeEnd: new Date("2022-08-17T20:30:00"),
               HubbleStart: new Date("2022-08-17T08:32:00"),
               HubbleEnd: new Date("2022-08-17T20:20:00"),
            },
            {
               date: new Date("2022-08-18"),
               timeStart: new Date("2022-08-18T08:30:00"),
               timeEnd: new Date("2022-08-18T20:30:00"),
               HubbleStart: new Date("2022-08-18T08:32:00"),
               HubbleEnd: new Date("2022-08-18T20:20:00"),
            },
         ],
         isFold: true,
      },
   ];

   return arr_ts;
};

export default fakedata;
