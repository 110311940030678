import axios from 'axios';

const axiosClient = axios.create({
  headers: {
    'content-type': 'application/json',
  },
});

axiosClient.interceptors.request.use(async (config) => {
  try {
    if (
      config.method == 'get' ||
      (config.data != undefined && config.data instanceof FormData)
    ) {
      config.params = { ...config.params, token: getLocalToken() };
    } else {
      config.data = { ...config.data, token: getLocalToken() };
    }
  } catch (error) {
    config.params = { ...config.params, token: getLocalToken() };
  }

  return config;
});

getLocalToken();

function getLocalToken() {
  const token = window.localStorage.getItem('bookwormJWT');
  return token;
}

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    throw error;
  }
);

export default axiosClient;
