import React, { FunctionComponent, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import WorkingTime from "../module_Worker/WorkingTime/WorkingTime";
import _ from "lodash";
import { Empty, Button, Skeleton } from "antd";
import { VStack, Center, Box } from "native-base";
import { getSupervisorByEmail } from "../module_Worker/api/setWorkingTimeApi";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectProject, setProject } from "../../features/app/projectSlice";
import { controlProject, trackingUserUsing } from "../module_BimApp/AdminFunction";
// import { LocaleProvider } from "antd-mobile";
import enUS from "antd-mobile/lib/locale-provider/en_US";

function WorkerPage(props: any) {
   const { history } = props;

   const {
      location: { pathname, state: historyState },
   } = history;

   const [supervisor, setSupervisor] = useState<any>();
   const [isLoading, setIsLoading] = useState<boolean>(true);
   const dispatch = useAppDispatch();
   const project = useAppSelector<any>(selectProject);
   const email = useSelector((state: any) => state.user?.value?.email);
   // const projectId = useSelector((state: any) =>{
   //   console.log("state",state)
   //   return state.project.value.projectId
   // });
   useEffect(() => {
      if (!project.projectId) controlProject(props, dispatch, setProject);
   }, []);
   useEffect(() => {
      if (project.projectId) {
         getSupervisor();
         trackingUserUsing(email, project.projectId, "Worker (Mobile)");
      }
   }, [project, email]);

   async function getSupervisor() {
      if (project.projectId) {
         try {
            let res = await getSupervisorByEmail({ projectId: project.projectId, email });
            setSupervisor(res.data);
            setIsLoading(false);
         } catch (error) {
            setSupervisor(null);
            setIsLoading(false);
         }
      }
   }

   return (
      <div className="worker-page">
         <div style={{ position: "fixed", height: "100%", width: "100%" }}>
            <Center height={"100%"} justifyContent="center">
               <Button
                  style={{
                     width: "70%",
                     height: "80px",
                     marginBottom: 10,
                     background: "rgb(234, 179, 8)",
                     color: "black",
                     fontWeight: "bold",
                  }}
                  onClick={() => {
                     history.push({
                        pathname: "/worker/daily",
                        state: {
                           ...historyState,
                        },
                     });
                  }}
               >
                  <h2>Daily Record</h2>
               </Button>

               <Button
                  style={{
                     width: "70%",
                     height: "80px",
                     marginBottom: 10,
                     background: "rgb(234, 179, 8)",
                     color: "black",
                     fontWeight: "bold",
                  }}
                  onClick={() => {
                     history.push({
                        pathname: "/worker/monthly",
                        state: {
                           ...historyState,
                        },
                     });
                  }}
               >
                  <h2>Monthly Report</h2>
               </Button>
            </Center>
         </div>

         {/* <div className="class-content">
            
            <Skeleton loading={isLoading}>
               <LocaleProvider locale={enUS}>
                  <WorkingTime idSupervisor={supervisor ? supervisor.id : null} />
               </LocaleProvider>
            </Skeleton>
         </div> */}
      </div>
   );
}

export default WorkerPage;
