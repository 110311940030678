import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { store } from "../src/app/store";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { initMicrosoft, trackingUser, controlUrl, checkWohhupUser } from "./components/module_BimApp/AdminFunction";
import decode from "jwt-decode";
import { login, logout } from "./features/app/userSlice";
import { LocaleProvider } from "antd-mobile";
import enUS from "antd-mobile/lib/locale-provider/en_US";

import "./scss/module_BimApp/style.scss";
import "./scss/module_Worker/style.scss";
import "./scss/module_QrArchi/style.scss";
import "antd/dist/antd.css";
import "antd-mobile/dist/antd-mobile.css";

initMicrosoft();

// controlUrl()
if (localStorage.bookwormJWT) {
   const payload: any = decode(localStorage.bookwormJWT);
   // controlUrl(payload)
   if (payload.email === "admin@wohhup.com" || !checkWohhupUser(payload.email)) {
      trackingUser(payload.email);
      const user = {
         token: localStorage.bookwormJWT,
         email: payload.email,
         role: payload.role,
         confirmed: payload.confirmed,
         projects: payload.projects,
         permission: payload.permission,
         contractors: payload.contractors,
      };
      store.dispatch(login(user));
   } else if (localStorage.userWh5Dapp) {
      if (payload.email === JSON.parse(localStorage.userWh5Dapp).mail.toLowerCase()) {
         trackingUser(payload.email);
         const user = {
            token: localStorage.bookwormJWT,
            email: payload.email,
            role: payload.role,
            confirmed: payload.confirmed,
            projects: payload.projects,
            permission: payload.permission,
            imgUrl: payload.imgUrl,
            contractors: payload.contractors,
         };
         store.dispatch(login(user));
      } else {
         store.dispatch(logout(payload.email));
      }
   }
} else {
   // controlUrl(null)
}

ReactDOM.render(
   <React.StrictMode>
      <BrowserRouter>
         <Switch>
            <LocaleProvider locale={enUS}>
               <Provider store={store}>
                  <Route component={App} />
               </Provider>
            </LocaleProvider>
         </Switch>
      </BrowserRouter>
   </React.StrictMode>,
   document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
