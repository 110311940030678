import { Badge } from 'antd';
import { Button, Card, WhiteSpace } from 'antd-mobile';
import React, { useEffect, useState } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { colorTextRow, colorType, statusOrderArray } from '../constants';
import { tradeArrayForm, tradeArrayMeetingMinutesForm } from '../utils';


const transmittedForArray = ['Information / Action', 'Comments / Approval', 'Construction', 'Record'];

const meetingFolderArray = [
   'Project Progress Meeting',
   'Technical Meeting',
   'ICE Meeting',
];

const statusOutstandingArray = [
   'Overdue',
   'Due in the next 1 - 3 days',
   'Due in the next 4 - 14 days',
   'Replied'
];

const statusRfiArray = [
   'replied',
];

const PanelChart = (props: any) => {

   const { type, input } = props;

   const data = input[type];


   const [tradeType, setTradeType] = useState('Overall');


   const LabelCustom = (props: any) => {
      const { cx, cy, midAngle, innerRadius, outerRadius, value } = props;
      const RADIAN = Math.PI / 180;
      const radius = 28 + innerRadius + (outerRadius - innerRadius);
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      return (
         <text
            x={x} y={y} fill='black'
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline='central'
         >{value}</text>
      );
   };


   const [currentWindow, setCurrentWindow] = useState({ width: window.innerWidth, height: window.innerHeight });
   useEffect(() => {
      const handleResize = () => {
         setCurrentWindow({ width: window.innerWidth, height: window.innerHeight });
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   });



   return (
      <>
         <Card style={{ width: '100%' }}>
            <Card.Header
               title={
                  <div
                     style={{
                        fontWeight: 'bold',
                        fontSize: 17
                     }}
                  >{type === 'rfa' ? 'Request For Approval'
                     : type === 'rfam' ? 'Request For Approval Of Material'
                        : type === 'rfi' ? 'Request For Information'
                           : type === 'cvi' ? 'Confirmation Of Verbal Instruction'
                              : type === 'dt' ? 'Document Transmittal'
                                 : 'Meeting Minutes'
                     }
                  </div>
               }
            />
            <Card.Body>

               <>
                  {(type === 'rfa' || type === 'rfam' || type === 'rfi') && ['Overall', ...tradeArrayForm].map(btn => (
                     <Button
                        key={btn}
                        style={{
                           display: 'inline-block',
                           height: 22,
                           lineHeight: 1.5,
                           padding: 0,
                           paddingRight: 5,
                           paddingLeft: 5,
                           marginLeft: 3,
                           textAlign: 'center',
                           fontSize: 15,
                           backgroundColor: btn === tradeType ? colorType.primaryMobile : colorType.grey4,
                           color: btn === tradeType ? 'white' : 'black',
                        }}
                        size='small'
                        onClick={() => setTradeType(btn)}
                     >{btn}</Button>
                  ))}

                  <div style={{ width: '100%', textAlign: 'center' }}>

                     {((type === 'rfa' || type === 'rfam' || type === 'dt')
                        ? ['chart-1', 'chart-2']
                        : ['chart-1']).map((chart, i) => {
                           return (
                              <ChartComp
                                 key={i}
                                 chartType={chart}
                                 data={data}
                                 type={type}
                                 currentWindow={currentWindow}
                                 tradeType={tradeType}
                              />
                           );
                        })}
                  </div>
               </>


            </Card.Body>
         </Card>

         <WhiteSpace size='lg' />
      </>
   );
};



export default PanelChart;












const ChartComp = (props: any) => {

   const { chartType, data: dataInput, type, currentWindow, tradeType } = props;



   let data: any;

   if (type === 'rfa') {
      if (chartType === 'chart-1') {
         data = dataInput['refStatistics'];
      } else {
         data = dataInput['drawingStatus'];
      };
   } else if (type === 'rfam') {
      if (chartType === 'chart-1') {
         const dataOverdue = dataInput['refStatistics'];
         const dataStatus = dataInput['drawingStatus'];

         data = {};
         ['Overall', ...tradeArrayForm].forEach(trade => {
            data[trade] = {};
            data[trade]['Overdue'] = dataOverdue[trade]['Overdue'];
            data[trade]['Due in the next 1 - 3 days'] = dataOverdue[trade]['Due in the next 1 - 3 days'];
            data[trade]['Due in the next 4 - 14 days'] = dataOverdue[trade]['Due in the next 4 - 14 days'];

            data[trade]['Replied'] = dataStatus[trade]['Approved with Comment, no submission Required']
               + dataStatus[trade]['Approved for Construction']
               + dataStatus[trade]['Approved with comments, to Resubmit']
               + dataStatus[trade]['Reject and resubmit'];
         });
      } else {
         const dataStatus = dataInput['drawingStatus'];
         data = {};
         ['Overall', ...tradeArrayForm].forEach(trade => {
            data[trade] = {};
            data[trade]['Approved with Comment, no submission Required'] = dataStatus[trade]['Approved with Comment, no submission Required'];
            data[trade]['Approved for Construction'] = dataStatus[trade]['Approved for Construction'];
            data[trade]['Approved with comments, to Resubmit'] = dataStatus[trade]['Approved with comments, to Resubmit'];
            data[trade]['Reject and resubmit'] = dataStatus[trade]['Reject and resubmit'];
         });
      };


   } else if (type === 'rfi') {
      const dataOverdue = dataInput['refStatistics'];
      const dataStatus = dataInput['drawingStatus'];
      data = {};
      ['Overall', ...tradeArrayForm].forEach(trade => {
         data[trade] = {};
         data[trade]['Overdue'] = dataOverdue[trade]['Overdue'];
         data[trade]['Due in the next 1 - 3 days'] = dataOverdue[trade]['Due in the next 1 - 3 days'];
         data[trade]['Due in the next 4 - 14 days'] = dataOverdue[trade]['Due in the next 4 - 14 days'];

         data[trade]['Replied'] = dataStatus[trade]['replied'];

      });
   } else if (type === 'dt') {
      if (chartType === 'chart-1') {
         data = dataInput['refStatistics'];
      } else {
         data = dataInput['objTransmittedForDt'];
      };
   } else {
      data = dataInput['refStatistics'];
   };


   return (
      <div style={{ paddingTop: 20, paddingBottom: 20, borderBottom: `1px solid ${colorType.grey4}` }}>
         {(chartType === 'chart-1'
            ? ((type === 'cvi' || type == 'dt' || type === 'mm') ? ['Submission'] : ['Ref Status'])
            : (type == 'dt' ? ['Transmitted'] : type === 'rfam' ? ['Ref Replied'] : ['Drawing Status'])).map(tabName => {


               let isThereNoData = true;
               Object.keys(data[tradeType]).forEach(item => {
                  if (data[tradeType][item] > 0) {
                     isThereNoData = false
                  };
               });



               let tabNameText = '';
               if ((type === 'rfa' || type === 'rfam' || type === 'rfi') && tabName === 'Ref Status') {

                  const sum = getTotalCountInObj(data[tradeType]);
                  tabNameText = `${type.toUpperCase()} Status (${sum})`;

               } else if (type === 'rfa' && tabName === 'Drawing Status') {
                  const sum = getTotalCountInObj(data[tradeType]);
                  tabNameText = `Drawing Status (${sum})`;

               } else if (type === 'rfam' && tabName === 'Ref Replied') {
                  const sum = getTotalCountInObj(data[tradeType]);
                  tabNameText = `RFAM Replied (${sum})`;

               } else if (tabName === 'Submission') {
                  const sum = getTotalCountInObj(data[tradeType]);
                  tabNameText = `${type.toUpperCase()} Submission (${sum})`;
               } else if (tabName === 'Transmitted') {
                  tabNameText = `${type.toUpperCase()} Transmitted`;
               };


               const arrayLegendAndPortion = (type === 'rfa' && tabName === 'Ref Status') ? statusOutstandingArray
                  : (type === 'rfam' && tabName === 'Ref Status') ? statusOutstandingArray
                     : (type === 'rfam' && tabName === 'Ref Replied') ? statusOrderArray.filter(x => x !== 'Consultant reviewing')
                        : (type === 'rfi' && tabName === 'Ref Status') ? statusOutstandingArray
                           : ((type === 'cvi' || type === 'dt') && tabName === 'Submission') ? tradeArrayForm
                              : (type === 'mm' && tabName === 'Submission') ? meetingFolderArray
                                 : (type === 'dt' && tabName === 'Transmitted') ? transmittedForArray
                                    : tabName === 'Drawing Status' ? ((type === 'rfi' || type === 'cvi' || type === 'dt') ? ['replied'] : statusOrderArray)
                                       : [];

               const chartWidth = currentWindow.width * 0.8;


               let outputTradeBreakdown: any = {};
               if (
                  (tradeType === 'Overall' && (type === 'rfa' || type === 'rfam' || type === 'rfi')) ||
                  (type === 'dt' && tabName === 'Transmitted')
               ) {
                  if (type === 'dt') {
                     arrayLegendAndPortion.forEach((item: string) => {
                        outputTradeBreakdown[item] = {};
                        tradeArrayForm.forEach(trade => {
                           if (data[trade]) {
                              outputTradeBreakdown[item][trade] = data[trade][item];
                           };
                        });
                     });
                  } else {
                     arrayLegendAndPortion.forEach((item: string) => {

                        outputTradeBreakdown[item] = {};
                        tradeArrayForm.forEach(trade => {
                           outputTradeBreakdown[item][trade] = data[trade][item];
                        });
                     });
                  };
               };

               

               return (
                  <div key={tabName}>

                     {isThereNoData ? (
                        <div style={{
                           width: chartWidth, height: 250,
                           textAlign: 'center',
                           fontSize: 25, verticalAlign: 'middle',
                           paddingTop: 100
                        }}>{`No ${type.toUpperCase()} Status`}</div>
                     ) : (
                        <PieChart width={chartWidth} height={250} style={{
                           // margin: '0 auto' 
                        }}>
                           <Pie
                              // https://github.com/recharts/recharts/issues/490

                              data={getInputDataForChart(data, tradeType, tabName, type)}
                              cx={'50%'} cy={'50%'}
                              dataKey='value'
                              outerRadius={90}
                              labelLine

                              label={(props) => {
                                 const { outerRadius, innerRadius, midAngle, cy, cx, value, name } = props;
                                 const RADIAN = Math.PI / 180;
                                 let radius = 20 + innerRadius + (outerRadius - innerRadius);
                                 let x = cx + radius * Math.cos(-midAngle * RADIAN);
                                 let y = cy + radius * Math.sin(-midAngle * RADIAN);
                                 if (value <= 0) return null;



                                 return (
                                    <g>
                                       <text
                                          x={x} y={y}
                                          fill='#000' fontWeight='300'
                                          fontSize='13px'
                                          textAnchor={x > cx ? 'start' : 'end'}
                                          dominantBaseline='central'
                                       >
                                          <tspan fontWeight='bold'>{value}</tspan>
                                       </text>
                                    </g>
                                 );
                              }}
                           >
                              {arrayLegendAndPortion.map(stt => {
                                 return (
                                    <Cell
                                       key={`cell-${stt}`}
                                       fill={stt === 'Consultant reviewing' ? colorType.grey4 : colorTextRow[stt]}
                                    />
                                 );
                              })}

                           </Pie>
                        </PieChart>
                     )}



                     <div style={{ 
                        // textAlign: 'center' ,
                    
                     }}>
                        <>
                           {arrayLegendAndPortion.map((item: string) => (
                              <div key={item} style={{
                                 // display: 'flex',
                                 // width: '100%',
                                 // textOverflow: 'ellipsis',
                                 // overflow: 'hidden',
                                 // whiteSpace: 'nowrap',
                                 textAlign: 'left'
                              }}>
                                 <Badge
                                    key={item}
                                    size='small'
                                    color={item === 'Consultant reviewing' ? colorType.grey4 : colorTextRow[item]}
                                    text={item}
                                 />
                                 <div style={{
                                    marginLeft: 5,
                                    marginBottom: 7,
                                    fontWeight: 'bold',
                                    color: 'black',
                                    fontSize: 12
                                 }}>{breakdownByTrade(outputTradeBreakdown[item] || {})}</div>
                              </div>
                           ))}
                        </>
                     </div>

                  </div>
               );
            })}
      </div>
   );
};


const breakdownByTrade = (objOutput: any) => {
   let str = '';
   let total = 0;
   tradeArrayForm.forEach(trade => {
      if (objOutput[trade] > 0) {
         total += objOutput[trade];
         str += `${objOutput[trade]} ${trade} + `;
      };
   });
   if (str[str.length - 2] === '+') {
      str = str.slice(0, str.length - 3);
   };
   str = `${total} - (${str})`;
   return total === 0 ? '' : str;
};


const getInputDataForChart = (data: any, tradeType: string, tabName: string, type: string) => {

   let output: any = [];

   if (tabName === 'Ref Status') {
      statusOutstandingArray.forEach(item => {
         output.push({
            name: item,
            value: data[tradeType][item]
         });
      });

   } else if (tabName === 'Ref Replied') {
      statusOrderArray.filter((x: string) => x !== 'Consultant reviewing').forEach((status: string) => {
         output.push({
            name: status,
            value: data[tradeType][status]
         });
      });

   } else if (tabName === 'Drawing Status') {
      (type === 'rfa' ? statusOrderArray : []).forEach((status: string) => {
         output.push({
            name: status,
            value: data[tradeType][status]
         });
      });
   } else if (tabName === 'Submission') {
      (type === 'mm' ? tradeArrayMeetingMinutesForm : tradeArrayForm).forEach((trade: string) => {
         if (trade !== 'Overall') {
            output.push({
               name: trade,
               value: getTotalCountInObj(data[trade])
            });
         };
      });
   } else if (tabName === 'Transmitted') {
      transmittedForArray.forEach((method: string) => {
         output.push({
            name: method,
            value: data[tradeType][method]
         });
      });
   };

   return output;
};


const getTotalCountInObj = (obj: any) => {
   let result = 0;
   for (const key in obj) {
      result += (obj[key] || 0);
   };
   return result;
};

