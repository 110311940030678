import BaseResponse from "../../module_Worker/models/BaseResponse";
import axios from "../../module_Worker/api/axiosClient";

async function apigetRoomQrByProjectId(projectId: any): Promise<BaseResponse> {
  return await axios.get(
    "/api/archi-library/get-room-qr-by-project-id/" + projectId
  );
}

async function apiSaveDataRoomQr(payload: any): Promise<BaseResponse> {
  return await axios.put("/api/archi-library/save-data-room-qr/", {
    data: payload,
  });
}

export { apigetRoomQrByProjectId ,apiSaveDataRoomQr};
