import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
// import "antd-mobile/dist/antd-mobile.css";

import WorkerPage from "./components/pages/WorkerPage";
import WorkingTime from "./components/module_Worker/WorkingTime/WorkingTime";
import MonthlyReport from "./components/module_Worker/MonthlyReport/_MonthlyReport";
import QrArchiPage from "./components/pages/QrArchiPage";

import UserRoute from "./routes/UserRoute";

import HomePage from "./components/pages/HomePage";
import ProjectsPage from "./components/pages/ProjectsPage";
import LoginPage from "./components/pages/LoginPage";
import TopNavigation from "./components/navigation/TopNavigation";
import { NativeBaseProvider } from "native-base";
import { useAppSelector } from "./app/hooks";
import { selectUser } from "./features/app/userSlice";
import { Client } from "@microsoft/microsoft-graph-client";
import ProjectPage from "./components/pages/ProjectPage";
import NoPage from "./components/pages/No Page";
import DashboardPage from "./components/pages/DashboardPage";

import DmsPageType from "./components/pages/dms/DmsPageType";
import DmsPageItem from "./components/pages/dms/DmsPageItem";
import DmsPageSum from "./components/pages/dms/DmsPageSum";
import DmsPageAllForm from "./components/pages/dms/DmsPageAllForm";
import { QueryClient, QueryClientProvider } from "react-query";
import DmsPageAllDiscipline from "./components/pages/dms/DmsPageAllDiscipline";
const queryClient = new QueryClient();

function App(props: any) {
   const user = useAppSelector<any>(selectUser);
   const isAuthenticated = !!user.email;

   useEffect(() => {
      if (window.hello("aad").getAuthResponse()) {
         //!note
         window.client = Client.init({
            authProvider: (done) => {
               done(null, window.hello("aad").getAuthResponse().access_token);
            },
         });
      }
   }, []);
   return (
      <React.Suspense fallback={null}>
         <NativeBaseProvider>
            {isAuthenticated && (
               <div style={{ width: "100%", position: "sticky", zIndex: 100, top: 0 }}>
                  <TopNavigation {...props} history={props.history} />
               </div>
            )}
            <Switch>
               <Route path="/" exact component={HomePage} />
               <Route path="/login-admin" exact component={LoginPage} />
               <UserRoute path="/projects" exact Component={ProjectsPage} />
               <UserRoute path="/project/:projectId" exact Component={ProjectPage} />
               <UserRoute path="/project-dashboard/:projectId" exact Component={DashboardPage} />

               <UserRoute path="/worker" exact Component={WorkerPage} />
               <UserRoute path="/worker/daily" exact Component={WorkingTime} />
               <UserRoute path="/worker/monthly" exact Component={MonthlyReport} />
               {/* </LocaleProvider> */}

               {["rfa", "rfam", "rfi", "cvi", "dt", "qrm", "qr", "mm"].map((type) => (
                  <UserRoute path={`/dms/${type}`} exact Component={DmsPageAllDiscipline} />
               ))}

               <UserRoute path="/dms/item" exact Component={DmsPageItem} />

               <QueryClientProvider client={queryClient}>
                  <UserRoute path="/qr-archi/:projectId" exact Component={QrArchiPage} />

                  <UserRoute path="/dms/sum/chart" exact Component={DmsPageSum} />

                  <UserRoute path="/dms/:projectId" exact Component={DmsPageType} />

                  {["rfa", "rfam", "rfi", "cvi", "dt", "mm"].map((item) => (
                     <UserRoute path={`/dms/${item}/:discipline`} exact Component={DmsPageAllForm} />
                  ))}
               </QueryClientProvider>

               <Route path="*" component={NoPage} />
            </Switch>
         </NativeBaseProvider>
      </React.Suspense>
   );
}

export default App;
