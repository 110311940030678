import { DatePicker, List } from "antd-mobile";
import enUs from "antd-mobile/lib/date-picker/locale/en_US";
import { useState } from "react";

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);

const DatePickerComp = (props: any) => {
   const { pickDateFilter } = props;

   const [date, setDate] = useState(now);

   return (
      <DatePicker
         className="dms-date-picker"
         locale={enUs}
         mode="date"
         title="Select Date"
         extra="Optional"
         value={date}
         onChange={(date) => {
            setDate(date);
            pickDateFilter(date);
         }}
      >
         <List.Item arrow="horizontal">Date</List.Item>
      </DatePicker>
   );
};

export default DatePickerComp;
