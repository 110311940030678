import React, { FunctionComponent, useState, useEffect } from 'react';
import queryString from 'query-string';
import { Button, Select, Switch, notification, AutoComplete } from 'antd';
import { connect, useSelector } from 'react-redux';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import moment from 'moment';
import { colorTextRow, colorType, statusOrderArray } from '../constants';
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import _ from 'lodash';
import {
  LeftOutlined,
  RightOutlined,
  QrcodeOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import {
  DatePicker as DatePickerMobile,
  Picker,
  List,
  Checkbox,
} from 'antd-mobile';
import { apigetRoomQrByProjectId, apiSaveDataRoomQr } from '../api/archiApi';
const { Option } = Select;
const CheckboxItem = Checkbox.CheckboxItem;
const format = 'DD/MM/YYYY';

const QrArchi = (props: any) => {
  const [pickerTimeVisible, setPickerTimeVisible] = useState<boolean>(false);
  const [currentTimeValue, setCurrentTimeValue] = useState<Date>(new Date());
  const projectId = props?.match?.params?.projectId;
  const [dataArchi, setDataArchi] = useState<Array<any>>([]);
  const [selectedRoom, setSelectedRoom] = useState<any>(null);
  const [allRooms, setAllRooms] = useState<Array<any>>([]);
  const [activies, setActivities] = useState<any>([]);
  const [current, setCurrent] = useState<any>([]);
  const [showQrScanner, setShowQrScanner] = useState<boolean>(false);
  const [searchRoomKey, setSearchRoomKey] = useState<string>('');

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let query = queryString.parse(props.location.search);
    if (query && query.room && selectedRoom == null) {
      setSelectedRoom([query.room]);
    }
  }, []);

  useEffect(() => {
    getActivities();
  }, [selectedRoom, dataArchi]);

  async function getData() {
    let res = await apigetRoomQrByProjectId(projectId);

    setDataArchi(res.data);
    if (res.data && _.isArray(res.data)) {
      let rooms = _.uniq(res.data.map((x: any) => x.roomId));
      rooms.sort();
      if (!rooms || rooms.length > 0) {
        setAllRooms(rooms);
      }
    }
  }

  function getOptRooms() {
    let rooms = _.uniq(dataArchi.map((x) => x.roomId));
    rooms.sort();

    return rooms
      .filter(
        (x: string) =>
          _.isEmpty(searchRoomKey) ||
          x.toLowerCase().includes(searchRoomKey.toLowerCase())
      )
      .map((x) => ({
        value: x,
        label: x,
      }));
  }

  function getActivities() {
    if (selectedRoom) {
      console.log('dataArchi', dataArchi);
      let datas = dataArchi.filter((x) => x.roomId == selectedRoom);
      let groupByBeakdown1 = _.groupBy(datas, 'breakdown3');

      let gActivities = Object.keys(groupByBeakdown1).map((key) => {
        let items = groupByBeakdown1[key];
        let item = {
          breakdown3: _.isEmpty(key) ? 'Unknown' : key,
          items,
          actualDateFinish: items[0].actualDateFinish,
          Status: items[0].Status,
        };

        return item;
      });

      setActivities(gActivities);
    } else {
      setActivities([]);
    }
  }

  function pickerMobileOk(date: any) {
    setPickerTimeVisible(false);
    let ac = _.clone(activies);
    let currentArchiData = ac.find(
      (x: any) => x.breakdown3 == current.breakdown3
    );

    let dateString = moment(date).format(format);
    currentArchiData.actualDateFinish = dateString;
    currentArchiData.items.forEach(
      (x: any) => (x.actualDateFinish = dateString)
    );
    setActivities(ac);
  }

  function handleSwitchChange(e: any, c: any) {
    let ac = _.clone(activies);
    let currentArchiData = ac.find((x: any) => x.breakdown3 == c.breakdown3);

    console.log('currentArchiData', currentArchiData);

    let date = currentArchiData.actualDateFinish;
    if (_.isEmpty(date)) {
      date = moment().format(format);
      currentArchiData.actualDateFinish = date;
    }

    currentArchiData.Status = e.target.checked ? 'Completed' : 'Incomplete';
    currentArchiData.items.forEach((x: any) => {
      x.Status = e.target.checked ? 'Completed' : 'Incomplete';
      x.actualDateFinish = date;
    });

    setActivities(ac);
  }

  async function handleSave() {
    let payload = new Array<any>();
    console.log('activies', activies);
    for (const item of activies) {
      item.items.forEach((x: any) => {
        let i = {
          id: x.id,
          Status: x.Status,
          'Actual date finish': x.actualDateFinish,
        };
        payload.push(i);
      });
    }

    await apiSaveDataRoomQr(payload);

    notification['success']({
      message: 'Submit',
      description: 'Save Data Successfully !',
    });
  }

  const onSearchRoom = (searchText: string) => {
    console.log('searchText', searchText);
    setSearchRoomKey(searchText);
  };
  const onSelectRoom = (data: string) => {
    setSelectedRoom(data);
  };

  return (
    <div className='flex flex-col'>
      <div className='w-full content-box'>
        <Button
          onClick={handleSave}
          icon={<QrcodeOutlined />}
          style={{
            width: '100%',
            padding: 0,
            textAlign: 'center',
            fontSize: 15,
            backgroundColor: colorType.primaryMobile,
            color: 'black',
            fontWeight: 'bold',
          }}
        >
          Save Data
        </Button>
        <div className=' grid grid-cols-4 gap-2 mt-3'>
          <Button
            icon={<QrcodeOutlined />}
            onClick={() => {
              setShowQrScanner(!showQrScanner);
            }}
            style={{
              width: '100%',
              padding: 0,
              textAlign: 'center',
              fontSize: 15,
              backgroundColor: colorType.primaryMobile,
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            Scan
          </Button>

          <div className='col-span-3'>
            <AutoComplete
              value={selectedRoom}
              options={getOptRooms()}
              style={{ width: '100%' }}
              onSelect={onSelectRoom}
              onChange={(e) => setSelectedRoom(e)}
              onSearch={onSearchRoom}
              placeholder='Select Room'
            />
          </div>
        </div>
      </div>

      {showQrScanner && (
        <BarcodeScannerComponent
          width='100%'
          facingMode='environment'
          height='100%'
          torch={false}
          onUpdate={(err, result) => {
            if (result) {
              console.log('result', result);
              if (allRooms.includes(result['text'])) {
                setSelectedRoom(result['text']);
                setShowQrScanner(false);
              }
            } else {
              console.log('false', err);
            }
          }}
        />
      )}

      {activies.map((x: any, index: any) => {
        return (
          <div className='content-box mt-3' key={index}>
            <p>
              Activity : <strong>{x.breakdown3}</strong>
            </p>
            <div className='grid grid-cols-2 gap-4'>
              <div className='flex flex-row items-center align-center'>
                <span>Status:</span>
                <div className='ml-1'>
                  <CheckboxItem
                    checked={x.Status && x.Status == 'Completed'}
                    onChange={(e: any) => handleSwitchChange(e, x)}
                  >
                    Completed
                  </CheckboxItem>
                </div>
              </div>

              <div className='flex flex-row items-center align-center'>
                <span>Date :</span>

                <Button
                  className='ml-1'
                  onClick={() => {
                    setCurrent(x);
                    setPickerTimeVisible(true);
                    setCurrentTimeValue(
                      _.isEmpty(x.actualDateFinish)
                        ? moment().toDate()
                        : moment(x.actualDateFinish, format).toDate()
                    );
                  }}
                  icon={<ClockCircleOutlined />}
                >
                  {_.isEmpty(x.actualDateFinish) ? 'N/A' : x.actualDateFinish}
                </Button>
              </div>
            </div>
          </div>
        );
      })}

      <DatePickerMobile
        mode='date'
        locale={enUs}
        visible={pickerTimeVisible}
        value={currentTimeValue}
        onOk={pickerMobileOk}
        onDismiss={() => setPickerTimeVisible(false)}
      ></DatePickerMobile>
    </div>
  );
};

export default QrArchi;
