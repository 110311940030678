import { Icon } from "antd-mobile";
import React, { useEffect, useState } from "react";
import Loading from "../../module_DMS/components/Loading";
import PanelChart from "../../module_DMS/components/PanelChart";
import { colorType, statusOrderArray } from "../../module_DMS/constants";
import {
   convertTradeCodeInverted,
   getDataForMultiFormSheet,
   getDataForRfaSheet,
   getDrawingStatusByConsultant,
   getInfoValueFromRefDataForm,
   tradeArrayForm,
   tradeArrayMeetingMinutesForm,
} from "../../module_DMS/utils";
import { Center } from "native-base";
import { useQuery } from "react-query";
import {
   apiMobileFetchDataSum,
   apiMobileFetchDataThisMultiFormThisDiscipline,
   apiMobileFetchDataThisRfaFormThisDiscipline,
} from "../../module_DMS/api/dmsApi";
import { useSelector } from "react-redux";

const arrayMultiForm = ["rfam", "rfi", "cvi", "dt", "mm"];

const DmsPageSum = (props: any) => {
   const {
      history,
      match: { path },
      location,
   } = props;

   const {
      location: { state: historyState },
   } = history;

   const { projectId } = historyState;

   const {
      state: { data },
   } = location;

   const { email, token } = useSelector((state: any) => {
      return {
         email: state.user?.value?.email,
         token: state.user?.value?.token,
      };
   });

   let dataRefOutstanding: any = {};

   const { isLoading, isError, error, isRefetching } = useQuery(
      "query-dms-sum-page",
      () => {
         return apiMobileFetchDataSum({ projectId, email, token });
      },
      {
         select: (resSum) => {
            dataRefOutstanding = resSum.data;
         },
      }
   );

   return (
      <div className="flex-container" style={{ margin: 0, padding: 10 }}>
         {isLoading ? (
            <div style={{ position: "fixed", top: 0, height: window.innerHeight, width: "100%" }}>
               <Center height={"100%"} justifyContent="center">
                  <Loading />
               </Center>
            </div>
         ) : (
            <>
               <Icon
                  type="ellipsis"
                  onClick={() =>
                     history.push({
                        pathname: `/dms/${projectId}`,
                        state: { ...historyState },
                     })
                  }
                  size={"lg"}
                  style={{
                     position: "fixed",
                     bottom: 15,
                     left: 15,
                     zIndex: 10,
                     padding: 3,
                     background: colorType.primaryMobile,
                     borderRadius: 5,
                  }}
               />

               <PanelChart type="rfa" input={dataRefOutstanding} />
               <PanelChart type="rfam" input={dataRefOutstanding} />
               <PanelChart type="rfi" input={dataRefOutstanding} />
               <PanelChart type="cvi" input={dataRefOutstanding} />
               <PanelChart type="dt" input={dataRefOutstanding} />
               <PanelChart type="mm" input={dataRefOutstanding} />
            </>
         )}
      </div>
   );
};

export default DmsPageSum;
