import React, { FunctionComponent, useState, useEffect, useRef } from 'react'; // importing FunctionComponent
import moment from 'moment';
import { DatePicker as DatePickerMobile, Picker, List } from 'antd-mobile';
import { Input } from 'native-base';
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import _ from 'lodash';
import {
  Row,
  Col,
  Avatar,
  Checkbox,
  Button,
  Drawer,
  AutoComplete,
  Select,
} from 'antd';
import { useSelector } from 'react-redux';
import {
  PlusOutlined,
  MinusOutlined,
  ClockCircleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import BaseTypeModel from '../models/BaseTypeModel';
import WorkerWorkingShiftModel from '../models/WorkerWorkingShiftModel';
import WorkingShiftModel from '../models/WorkingShiftModel';
import { getAvatar, setTradeForWorker } from '../api/setWorkingTimeApi';
import { dateCols } from './data.js';
const { Option } = Select;
const format = 'HH:mm';

type WorkingTimeProps = {
  date: Date;
  idAllocation: number;
  startHubble?: any;
  endHubble?: any;
  idWorker: number;
  levels?: BaseTypeModel[];
  blocks?: BaseTypeModel[];
  supervisors?: BaseTypeModel[];
  doSave: number;
  isDisabled: boolean;
  allCostCodes?: any[];
  trade: string;
  shifts: WorkerWorkingShiftModel;
  updateShifts: Function;
  id: any;
};

const DESIGNATIONS = [
  'ALL',
  'GW',
  'PRELIM',
  'STRUCTURE',
  'ARCHI',
  'MEP',
  'QAQC',
  'QM',
  'SAFETY',
  'DLP',
  'COVID-19',
  'OFF DAY',
];

const WorkerShifts: FunctionComponent<WorkingTimeProps> = (props) => {
  const setWorkerWorkingShift = props.updateShifts;
  const [pickerTimeVisible, setPickerTimeVisible] = useState<boolean>(false);
  const [isStart, setIsStart] = useState<boolean>(true);
  const [searchTextUnitName, setSearchTextUnitName] = useState<String>('');
  const [currentId, setCurrentId] = useState<any>(null);
  const [currentTimeValue, setCurrentTimeValue] = useState<Date>(new Date());
  const [currentTimeValueArray, setCurrentTimeValueArray] = useState<
    Array<any>
  >([]);
  const [image, setImage] = useState<any>(null);
  const [keyPlan, setKeyPlan] = useState<any>(null);
  const [keyPlanImage, setKeyPlanImage] = useState<any>(null);
  const [zones, setZones] = useState(new Array<BaseTypeModel>());
  const [units, setUnits] = useState(new Array<BaseTypeModel>());
  const [tasks, setTasks] = useState(new Array<any>());
  const [isWorkOvernight, setIsWorkOvernight] = useState(false);
  const [trade, setTrade] = useState<any>([props.trade]);
  const projectId = useSelector((state: any) => state.project.value.projectId);

  const Trades = DESIGNATIONS.map((x) => ({
    label: x,
    value: x,
  }));

  useEffect(() => {
    onChangeTrade([props.trade], false);
  }, [props.allCostCodes, props.trade]);

  useEffect(() => {
    if (!image) {
      getAllWorkerShifts();
    }
  }, [projectId, props.shifts]);

  function getDateCols() {
    if (props.shifts?.isWorkOvernight == true) {
      return dateCols;
    } else {
      return dateCols.filter((x, index) => index > 0);
    }
  }

  async function getAllWorkerShifts() {
    let s = props.shifts;
    if (!s || _.isNil(s.avatar)) {
      setImage(null);
    } else {
      let res = await getAvatar({ key: s.avatar, time: 600 });
      setImage(res);
    }
  }

  function handleOnChangeCostCode(id: any, e: any) {
    let newData = Object.assign({}, props.shifts);
    let item = newData.workingShifts.find((x) => x.id === id);
    if (item) {
      item.idTask = e[0];
      if (item.action != 1) {
        item.action = 2;
      }
    }

    props.updateShifts(id, newData);
  }

  async function handleOnChangeLevel(id: any, e: any) {
    let newData = Object.assign({}, props.shifts);
    let item = newData.workingShifts.find((x) => x.id === id);
    if (item) {
      item.idLevel = e[0];
      if (item.action != 1) {
        item.action = 2;
      }
    }

    if (props.levels) {
      let selectedLevel = props.levels.find((x) => x.id == e[0]);
      if (selectedLevel) {
        let zoneDatas = selectedLevel.more.map((z: any) => ({
          id: z.id,
          name: z.name,
        })) as BaseTypeModel[];
        setZones(zoneDatas);

        if (zoneDatas.length === 1) {
          handleOnChangeZone(id, [zoneDatas[0].id]);
        }

        // let res = await getAvatar({ key: selectedLevel.add, time: 600 });
        // setKeyPlanImage(res);
      } else {
        // setZones(new Array<BaseTypeModel>());
        // setKeyPlanImage('');
      }
    }

    setWorkerWorkingShift(props.id, newData);
  }

  async function handleOnChangeBlockName(id: any, e: any) {
    console.log('handleOnChangeBlockName e', e);
    let newData = Object.assign({}, props.shifts);
    let item = newData.workingShifts.find((x) => x.id === id);
    if (item) {
      item.blockName = e[0];
      if (item.action != 1) {
        item.action = 2;
      }
    }

    if (props.blocks) {
      let selectedBlock = props.blocks.find((x) => x.name == e[0]);
      if (selectedBlock) {
        let unitDatas = selectedBlock.more.map((z: any) => ({
          id: z.id,
          name: z.name,
        })) as BaseTypeModel[];

        setUnits(unitDatas);

        console.log('unitDatas', unitDatas);

        if (unitDatas.length === 1) {
          handleOnChangeUnit(id, [unitDatas[0].id]);
        }
      } else {
      }
    }

    console.log('newData', newData);

    setWorkerWorkingShift(props.id, newData);
  }
  async function handleOnChangeUnitName(id: any, e: any) {
    let newData = Object.assign({}, props.shifts);
    let item = newData.workingShifts.find((x) => x.id === id);
    if (item) {
      item.idBlockUnit = e;
      if (item.action != 1) {
        item.action = 2;
      }
    }

    setWorkerWorkingShift(props.id, newData);
  }

  function handleOnChangeZone(id: any, e: any) {
    let newData = Object.assign({}, props.shifts);
    let item = newData.workingShifts.find((x) => x.id === id);
    if (item) {
      item.idZone = e[0];
      item.action = 2;
    }

    setWorkerWorkingShift(props.id, newData);
  }

  function handleOnChangeRemarkOfShift(id: any, e: any) {
    let newData = Object.assign({}, props.shifts);
    let item = newData.workingShifts.find((x) => x.id === id);
    if (item) {
      item.remark = e.target.value;
      item.action = 2;
    }

    setWorkerWorkingShift(props.id, newData);
  }

  function handleOnChangeUnit(id: any, e: any) {
    let newData = Object.assign({}, props.shifts);
    let item = newData.workingShifts.find((x) => x.id === id);
    if (item) {
      item.idBlockUnit = e[0];
      item.action = 2;
    }

    setWorkerWorkingShift(props.id, newData);
  }

  function onChangeWorkOverLunch(id: any, e: any) {
    let newData = Object.assign({}, props.shifts);
    newData.isWorkOverLunch = e.target.checked;
    newData.isEditWorkOverLunch = true;

    setWorkerWorkingShift(props.id, newData);
  }

  function onChangeWorkOverTeaTime(id: any, e: any) {
    let newData = Object.assign({}, props.shifts);
    newData.isWorkOverTeaTime = e.target.checked;
    newData.isEditWorkTeaTime = true;

    console.log('newData', newData);
    setWorkerWorkingShift(props.id, newData);
  }

  function onChangeWorkOvernight(id: any, e: any) {
    let newData = Object.assign({}, props.shifts);
    newData.isWorkOvernight = e.target.checked;
    newData.isEditWorkOvernight = true;

    setWorkerWorkingShift(props.id, newData);
  }

  function onChangeWorkDayOff(id: any, e: boolean) {
    let newData = Object.assign({}, props.shifts);
    newData.isDayOff = e;
    newData.isEditWorkDayOff = true;

    setWorkerWorkingShift(props.id, newData);
  }

  function handleAddNewShift() {
    let newData = Object.assign({}, props.shifts);

    console.log('newData', newData);
    if (newData) {
      let max = Math.max(
        ...newData.workingShifts
          .filter((x) => x != null)
          .map((x) => x.id as number)
      );

      let item = newData.workingShifts[newData.workingShifts.length - 1];
      let newItem = Object.assign({}, item);
      newItem.id = max + 1;
      newItem.action = 1;
      newItem.start = item.end;
      newItem.end = addTime(item.end);

      newData.workingShifts.push(newItem);

      setWorkerWorkingShift(props.id, newData);
    }
  }

  async function handleDeleteShift(x: WorkingShiftModel) {
    let newData = Object.assign({}, props.shifts);

    let item = newData.workingShifts.find((y) => y.id === x.id);
    if (item) {
      item.action = 3;
    }

    setWorkerWorkingShift(props.id, newData);
  }

  function addTime(time: string): string {
    let s = moment(time, format);
    let n = s.add(1, 'hours');
    return n.format(format);
  }

  function onChangeTimeStartEnd(
    isS: Boolean,
    timeString: any,
    id: any,
    day: string
  ) {
    let newData = Object.assign({}, props.shifts);
    let item = newData.workingShifts.find((x) => x.id === id);
    if (item) {
      if (isS) {
        item.start = timeString;
        item.startDate = day;
      } else {
        item.end = timeString;
        item.endDate = day;
      }

      if (item.action != 1) {
        item.action = 2;
      }
    }

    setWorkerWorkingShift(props.id, newData);
  }

  function setCurrentTimeFromTimeString(timeString: any, startDate: string) {
    let d = moment(timeString, format).toDate();
    setCurrentTimeValue(d);
    let h = moment(timeString, format).format('HH');
    let m = moment(timeString, format).format('mm');
    if (props.shifts?.isWorkOvernight) {
      setCurrentTimeValueArray([startDate, h, m]);
    } else {
      setCurrentTimeValueArray([h, m]);
    }
  }

  function pickerMobileOk(date: any) {
    setPickerTimeVisible(false);
    let m = moment(date).format(format);

    onChangeTimeStartEnd(isStart, m, currentId, 'today');
  }

  function pickerMobileOk2(selected: any) {
    setPickerTimeVisible(false);
    // let m = moment(selected[0],"HH").format(format);
    let m =
      selected.length > 2
        ? `${selected[1]}:${selected[2]}`
        : `${selected[0]}:${selected[1]}`;
    let day = selected.length > 2 ? `${selected[0]}` : `today`;
    onChangeTimeStartEnd(isStart, m, currentId, day);
  }

  function getShortName(name = '') {
    let shortName = '';
    let fs = name.split(' ');
    let i = 0;
    fs.forEach((x) => {
      if (i > 0) {
        shortName += '.' + x.charAt(0);
      } else {
        shortName += x.charAt(0);
      }
      i++;
    });

    return shortName;
  }

  async function onChangeTrade(e: any, isCallApi: boolean = true) {
    setTrade(e);

    if (!props.shifts) {
      return;
    }

    if (e[0] == 'OFF DAY') {
      let currentShifts = props.shifts?.workingShifts?.filter(
        (x) => x.action !== 3
      );

      if (currentShifts.length > 1) {
        currentShifts.forEach((x: WorkingShiftModel, index: number) => {
          if (index > 0) {
            handleDeleteShift(x);
          }
        });
      }
    }

    //SET DAY OFF
    onChangeWorkDayOff(props.shifts?.id, e[0] == 'OFF DAY');

    if (isCallApi) {
      try {
        await setTradeForWorker(props.shifts?.id, { trade: e[0] });
      } catch (error) {}
    }

    if (e.length > 0 && Array.isArray(props.allCostCodes)) {
      let first = e[0];
      let costCodesByTrade = props.allCostCodes
        .filter((x) => x.type == first || first == 'ALL')
        .map((x) => ({
          value: x.id,
          label: x.description,
        }));

      if (first != 'ALL') {
        let idTasks = props.shifts?.workingShifts
          ?.filter((x) => x.idTask > 0)
          .map((x) => x.idTask);

        let currents = props.allCostCodes
          .filter(
            (x) =>
              x.type != first &&
              Array.isArray(idTasks) &&
              idTasks.includes(x.id)
          )
          .map((x) => ({
            value: x.id,
            label: x.description,
          }));

        if (currents.length > 0) {
          costCodesByTrade = [...costCodesByTrade, ...currents];
        }
      }

      setTasks(costCodesByTrade);
    }
  }

  function handleChangeRemark(id: any, e: any) {
    let newData = Object.assign({}, props.shifts);
    newData.remark = e.target.value;
    newData.isEditRemark = true;

    setWorkerWorkingShift(props.id, newData);
  }

  const levelOpts = () => {
    if (Array.isArray(props.levels)) {
      return props.levels.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }

    return [];
  };

  const blockOpts = () => {
    if (Array.isArray(props.blocks)) {
      return props.blocks.map((x) => ({
        value: x.name,
        label: x.name,
      }));
    }

    return [];
  };

  const unitOpts = (selectedBlockString: String) => {
    if (props.blocks) {
      let block = props.blocks.find((x) => x.name == selectedBlockString);

      if (block && Array.isArray(block.more)) {
        return block.more
          .map((x) => ({
            value: x.id,
            label: x.unitName,
          }))
          .filter((x) => x.label.includes(searchTextUnitName));
      }
    }

    return [];
  };

  const zoneOpts = (idLevel: any) => {
    if (props.levels) {
      let level = props.levels.find((x) => x.id == idLevel);

      if (level && Array.isArray(level.more)) {
        return level.more.map((x) => ({
          value: x.id,
          label: x.name,
        }));
      }
    }

    return [];
  };

  async function handleShowImageKeyPlan(idLevel: any) {
    if (props.levels) {
      let level = props.levels.find((x) => x.id == idLevel);

      if (level) {
        let res = await getAvatar({ key: level.add, time: 600 });
        setKeyPlan(true);
        setKeyPlanImage(res);
      }
    } else {
      setZones(new Array<BaseTypeModel>());
      setKeyPlanImage('');
    }
  }

  function checkIsDisable() {
    return props.isDisabled || props.shifts?.isDayOff;
  }

  function getTimeString(time: string, date: string) {
    if (date == 'tomorrow') {
      return `${time} (tmr)`;
    }
    return time;
  }

  return (
    <div className='working-card'>
      <p className='working-trade'>
        <Picker
          data={Trades}
          cols={1}
          title=''
          value={trade}
          onChange={onChangeTrade}
          className='forss'
        >
          <List.Item></List.Item>
        </Picker>
      </p>
      {props.shifts?.isSubmit && (
        <p className='working-card-submit'>Submitted</p>
      )}

      <div className='worker-info'>
        <Avatar className='ml-2' size={40} src={image}>
          {image ? '' : getShortName(props.shifts?.name)}
        </Avatar>

        <div className='flex flex-row ml-2'>
          <p className='name'>{props.shifts?.name}</p>
          <div style={{ width: '110px' }}></div>
        </div>
      </div>

      <div className='working-card-wrapper'>
        {props.shifts?.workingShifts
          ?.filter((x) => x.action !== 3)
          .map((x: WorkingShiftModel, index: number) => (
            <div className='time-box-form'>
              <Row>
                <Col span={24}>
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <Button
                        disabled={checkIsDisable()}
                        onClick={() => {
                          setCurrentId(x.id);
                          setPickerTimeVisible(true);
                          setCurrentTimeFromTimeString(x.start, x.startDate);
                          setIsStart(true);
                        }}
                        className={
                          x.isConflict ? 'w-100 h-20 text-red' : 'w-100 h-20 '
                        }
                        icon={<ClockCircleOutlined />}
                      >
                        {getTimeString(x.start, x.startDate)}
                        {index == 0 && props.startHubble && (
                          <span
                            style={{ fontSize: '14px', color: ' gray' }}
                            className='ml-2'
                          >
                            SH {props.startHubble}
                          </span>
                        )}
                      </Button>
                    </Col>

                    <Col span={12}>
                      <Button
                        disabled={checkIsDisable()}
                        onClick={() => {
                          setCurrentId(x.id);
                          setPickerTimeVisible(true);
                          setCurrentTimeFromTimeString(x.end, x.endDate);
                          setIsStart(false);
                        }}
                        icon={<ClockCircleOutlined />}
                        className={
                          x.isConflict ? 'w-100 h-20 text-red' : 'w-100 h-20 '
                        }
                      >
                        {getTimeString(x.end, x.endDate)}
                        {index ==
                          props.shifts?.workingShifts?.filter(
                            (x) => x.action !== 3
                          ).length -
                            1 &&
                          props.endHubble && (
                            <span
                              style={{ fontSize: '14px', color: ' gray' }}
                              className='ml-2'
                            >
                              EH {props.endHubble}
                            </span>
                          )}
                      </Button>
                    </Col>
                  </Row>
                  <Row className='mt-3' gutter={[8, 8]}>
                    <Col span={24}>
                      <div className='box-picker'>
                        <Picker
                          // disabled={checkIsDisable()}
                          data={tasks}
                          cols={1}
                          title=''
                          value={[x.idTask]}
                          onChange={(e) => handleOnChangeCostCode(x.id, e)}
                        >
                          <List.Item arrow='horizontal'>Activity :</List.Item>
                        </Picker>
                      </div>
                    </Col>
                  </Row>
                  {trade == 'QM'
                    ? [
                        <Row className='mt-3' gutter={[8, 8]}>
                          <Col span={24}>
                            <div className='box-picker'>
                              <Picker
                                disabled={checkIsDisable()}
                                data={blockOpts()}
                                cols={1}
                                title=''
                                value={[x.blockName]}
                                onChange={(e) =>
                                  handleOnChangeBlockName(x.id, e)
                                }
                              >
                                <List.Item
                                  disabled={checkIsDisable()}
                                  arrow='horizontal'
                                >
                                  Block :
                                </List.Item>
                              </Picker>
                            </div>
                          </Col>
                        </Row>,

                        <Row className='mt-3' gutter={[8, 8]}>
                          <Col span={24}>
                            <Select
                              showSearch
                              style={{
                                width: '100%',
                              }}
                              value={x.idBlockUnit}
                              placeholder='Unit Name'
                              optionFilterProp='children'
                              filterOption={(input, option) => {
                                return (
                                  option!.children as unknown as string
                                ).includes(input);
                              }}
                              filterSort={(optionA, optionB) =>
                                (optionA!.children as unknown as string)
                                  .toLowerCase()
                                  .localeCompare(
                                    (
                                      optionB!.children as unknown as string
                                    ).toLowerCase()
                                  )
                              }
                              onChange={(e) => handleOnChangeUnitName(x.id, e)}
                            >
                              {unitOpts(x.blockName).map((x) => (
                                <Option value={x.value} key={x.value}>
                                  {x.label}
                                </Option>
                              ))}
                            </Select>
                          </Col>
                        </Row>,
                      ]
                    : [
                        <Row className='mt-3' gutter={[8, 8]}>
                          <Col span={24}>
                            <div className='box-picker'>
                              <Picker
                                disabled={checkIsDisable()}
                                data={levelOpts()}
                                cols={1}
                                title=''
                                value={[x.idLevel]}
                                onChange={(e) => handleOnChangeLevel(x.id, e)}
                              >
                                <List.Item
                                  disabled={checkIsDisable()}
                                  arrow='horizontal'
                                >
                                  Level :
                                </List.Item>
                              </Picker>
                            </div>
                          </Col>
                        </Row>,

                        <Row className='mt-3' gutter={[8, 8]}>
                          <Col span={24}>
                            <div className='box-picker'>
                              <Picker
                                disabled={checkIsDisable()}
                                data={zoneOpts(x.idLevel)}
                                cols={1}
                                title=''
                                value={[x.idZone]}
                                onChange={(e) => handleOnChangeZone(x.id, e)}
                              >
                                <List.Item
                                  disabled={checkIsDisable()}
                                  arrow='horizontal'
                                >
                                  Zone :
                                </List.Item>
                              </Picker>
                            </div>
                          </Col>
                        </Row>,
                      ]}
                  <Row className='mt-3' gutter={[8, 8]}>
                    <Col span={20}>
                      <div className='w-100'>
                        <Input
                          value={x.remark}
                          onChange={(e) => handleOnChangeRemarkOfShift(x.id, e)}
                          w={{
                            base: '100%',
                          }}
                          placeholder='Remark'
                        />
                      </div>
                    </Col>
                    <Col span={4}>
                      <Button
                        className='button-icon'
                        disabled={checkIsDisable()}
                        type='primary'
                        danger
                        icon={<MinusOutlined />}
                        onClick={() => handleDeleteShift(x)}
                      ></Button>
                    </Col>
                  </Row>
                  ,
                </Col>
              </Row>
            </div>
          ))}
      </div>

      <div className='w-100' id='input-remark'>
        <Input
          mx='3'
          value={props.shifts?.remark}
          onChange={(e) => handleChangeRemark(props.shifts?.id, e)}
          placeholder='Remark'
          w={{
            base: '95%',
          }}
        />
      </div>

      <div className='flex justify-between items-center mt-2'>
        <Checkbox
          disabled={checkIsDisable()}
          checked={props.shifts?.isWorkOverLunch}
          onChange={(e) => onChangeWorkOverLunch(props.shifts?.id, e)}
        >
          Over Lunch
        </Checkbox>

        <Checkbox
          disabled={checkIsDisable()}
          checked={props.shifts?.isWorkOverTeaTime}
          onChange={(e) => onChangeWorkOverTeaTime(props.shifts?.id, e)}
        >
          Tea Time
        </Checkbox>

        <Checkbox
          checked={props.shifts?.isWorkOvernight}
          onChange={(e) => {
            onChangeWorkOvernight(props.shifts?.id, e);
            setIsWorkOvernight(e.target.checked);
          }}
        >
          Overnight
        </Checkbox>

        <Button
          disabled={checkIsDisable()}
          type='primary'
          className='button-icon'
          icon={<PlusOutlined />}
          onClick={() => handleAddNewShift()}
        ></Button>
      </div>

      <Picker
        onDismiss={() => setPickerTimeVisible(false)}
        data={getDateCols()}
        visible={pickerTimeVisible}
        title='time picker'
        cascade={false}
        onOk={pickerMobileOk2}
        value={currentTimeValueArray}
      ></Picker>

      <Drawer
        title='Key Plan'
        width='100%'
        placement='right'
        onClose={() => setKeyPlan(null)}
        visible={keyPlan}
      >
        <img
          className='center-image '
          style={{ maxWidth: '100%' }}
          src={keyPlanImage}
          alt='Key plan'
        ></img>
      </Drawer>
    </div>
  );
};

export default WorkerShifts;
