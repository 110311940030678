import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userSlice from '../features/app/userSlice';
import projectSlice from '../features/app/projectSlice';

import dmsRowSlice from '../features/dms/dmsRowSlice';

export const store = configureStore({
  reducer: {
    user:userSlice,
    project:projectSlice,
    dmsRow: dmsRowSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
