import {
  FormatPainterOutlined,
  TableOutlined,
  EditOutlined,
  DashboardOutlined,
  AuditOutlined,
  QrcodeOutlined 
  
} from '@ant-design/icons';
export const modules = (project: any, size: number) => {
  return [
    // {
    //   name: 'Dashboard',
    //   icon: <DashboardOutlined style={{ fontSize: size }} />,
    //   path: `/project-dashboard/${project.projectId}`,
    // },
    { name: 'Worker Allocation', icon: <FormatPainterOutlined style={{ fontSize: size }} />, path: '/worker' },
    { name: 'Archi Work', icon: <QrcodeOutlined style={{ fontSize: size }} />, path: `/qr-archi/${project.projectId}` },
    // { name: 'Data Entry', icon: <TableOutlined style={{ fontSize: size }} />, path: '/' },
    {
      name: 'DMS',
      icon: <AuditOutlined style={{ fontSize: size }} />,
      path: `/dms/${project.projectId}`,
    }
  ];
};
