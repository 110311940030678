import React from 'react';
import socketIOClient from 'socket.io-client';
import $ from 'jquery';
import { isMobile,isMobileOnly } from 'react-device-detect';
import hello from 'hellojs';
import decode from 'jwt-decode';
import axios from 'axios';
import _ from 'lodash'
export const socket =
  window.location.origin.includes('https://idd.wohhup.com') ||
  window.location.origin.includes('https://m.idd.wohhup.com')
    ? socketIOClient(window.location.origin, { transports: ['websocket'] })
    : socketIOClient(window.location.origin);

declare global {
  interface Window {
    hello: any;
    client: any;
  }
}
export const bucketS3 =
  window.location.origin.includes('https://idd.wohhup.com') ||
  window.location.origin.includes('https://m.idd.wohhup.com')
    ? 'https://bimapp1.s3.ap-southeast-1.amazonaws.com'
    : 'https://bqltest.s3.ap-southeast-1.amazonaws.com';
export function getPublicUrl(url: string, time = 300 * 5) {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/issue/get-public-url', { params: { key: url, expire: time } })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject('');
      });
  });
}
export function trackingUser(
  email: string,
  projectName = null,
  folderName = null,
  itemName = null,
  functionName = null
) {
  try {
    socket.on('message', function (message) {
      localStorage.setItem('whapp5d-socketid', message);
    });
    $.getJSON('https://ipapi.co/json/', (data: any) => {
      socket.emit('client-send-username', {
        email: email,
        isMobile: isMobile,
        projectName: projectName,
        folderName: folderName,
        itemName: itemName,
        functionName: functionName,
        browser: browserDetection(),
        country: data.country.toLowerCase(),
        IP: data.ip,
        system: getOperatorSystem(),
        location: [],
      });
    }).fail(function () {
      socket.emit('client-send-username', {
        email: email,
        isMobile: isMobile,
        projectName: projectName,
        folderName: folderName,
        itemName: itemName,
        functionName: functionName,
        browser: browserDetection(),
        country: '',
        IP: '0.0.0.0',
        system: getOperatorSystem(),
        location: [],
      });
    });
  } catch {}
}
export function trackingUserUsing(email: any, projectId:any, platform:any, functionName = 'None') {
  try {
    let data = {
      email: email, isMobile: isMobile, projectId, functionName, platform,
      browser: browserDetection(), system: getOperatorSystem(),
    }
    axios.post("/api/users/record-usage", { data })
  } catch { }
}
export function getOperatorSystem() {
  let OSName = 'question';
  if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'windows';
  if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'ios';
  // if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'linux';
  return OSName;
}
export function browserDetection() {
  var ua = navigator.userAgent.match(
    /(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i
  );
  let browser = '';
  if (
    navigator.userAgent.match(/Edge/i) ||
    navigator.userAgent.match(/Trident.*rv[ :]*11\./i)
  ) {
    browser = 'msie';
  } else {
    browser = ua ? ua[1].toLowerCase() : '';
  }
  return browser;
}
export const initMicrosoft = () => {
  window.hello = hello.init({
    aad: {
      name: 'Azure Active Directory',
      oauth: {
        version: 2,
        auth: 'https://login.microsoftonline.com/wohhup.com/oauth2/v2.0/authorize',
      },
      form: false,
    },
  });
};

export function handleErrAuthMicrosoft(err: any, token: string) {
  if (err.statusCode === 401 && err.message === 'Access token has expired.') {
    localStorage.removeItem('hello');
    if (token) {
      const payload: any = decode(token);
      localStorage.removeItem('userWh5Dapp');
      localStorage.removeItem('bookwormJWT');
      socket.emit('leave', payload.email);
    }
  }
}

export function handleLoginMs() {
  window.hello.init(
    {
      aad: ( window.location.origin.includes('http://localhost:3000') || window.location.origin.includes('https://test.bql-app.com') || window.location.origin.includes('https://m-test.bql-app.com') )?
      '6094dea1-c853-4296-92d3-177b8844e1e2' :
         'ad6859d7-4dbe-4854-b00a-c83dcd7d73aa',
    },
    {
      redirect_uri: process.env.PUBLIC_URL,
      scope: 'user.read',
    }
  );
  window.hello.login(
    'aad',
    {
      display: 'popup',
      scope: 'user.read',
    },
    () => {
      window.location.href = window.location.origin;
      //  window.location.origin.includes(
      //   'http://localhost:3000'
      // )
      //   ? 'http://localhost:3000/'
      //   : window.location.origin.includes('https://test.bql-app.com')
      //   ? 'https://test.bql-app.com/'
      //   : 'https://idd.wohhup.com/';
    }
  );
}

export const controlUrl = () => {
  if (window.location.hostname !== 'localhost') {
    if (isMobileOnly && window.location.hostname !== 'm-idd.wohhup.com') {
      window.location.href = `https://m-idd.wohhup.com`;
    } else if (!isMobileOnly && window.location.hostname !== 'idd.wohhup.com') {
      window.location.href = `https://idd.wohhup.com/`;
    }
  }
};

export const checkWohhupUser = (email:string) => {
  const mail = [
    '@wohhup.com',
    '@wohhup.co.id'
  ]
  let check = false
  _.forEach(mail, v => {
    if (email.includes(v)){
      check = true
      return false
    }
  })
  return check
}

export const controlProject =(props:any,dispatch:any,setProject:any )=>{
  dispatch(
    setProject({
      userCreatedProject: props.location.state.userCreatedProject,
      projectId: props.location.state.projectId,
      projectName: props.location.state.projectName,
      sharePointId: props.location.state.sharePointId,
      issueKey: props.location.state.issueKey,
      typeProject: props.location.state.typeProject,
      trimbleKey: props.location.state.trimbleKey,
      useRfa: props.location.state.useRfa,
      isDetail: true,
    })
  );
}