import { Button, Card, Flex, Toast, WhiteSpace, WingBlank } from "antd-mobile";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { colorTextRow, colorType, statusOrderArray } from "../constants";
import {
   compareDatesForm,
   getCompanyNameShort,
   getDisciplineOfRefString,
   getInfoValueFromRefDataForm,
   getKeyTextForSheet,
   getRefStringWithVersion,
} from "../utils";

const PanelFormTypeOne = (props: any) => {
   const { pageSheetTypeName, cardData, history, stateRow } = props;

   const {
      location: { state: historyState },
   } = history;

   const refType = getKeyTextForSheet(pageSheetTypeName);

   const { rfaData, companies } = stateRow;

   const { refNumber, arrayBtnVersion, rowsInThisRef } = cardData;

   const [btnActive, setBtnActive] = useState("All");

   const [rowsInThisVersion, setRowsInThisVersion] = useState([]);

   const [rfaInfo, setRfaInfo] = useState(null);
   const [refTitle, setRefTitle] = useState("");

   const [dueType, setDueType] = useState("");

   useEffect(() => {
      if (pageSheetTypeName === "page-rfa") {
         const rowsInThisVersion = rowsInThisRef.filter((row: any) => {
            if (btnActive === "All") {
               const latestVersion = arrayBtnVersion[arrayBtnVersion.length - 1];
               const rfaRef = row["RFA Ref"];
               if (latestVersion === "0") {
                  return row["rfaNumber"] === row["RFA Ref"];
               } else {
                  return rfaRef.slice(row["rfaNumber"].length, rfaRef.length) === latestVersion;
               }
            } else if (btnActive === "0") {
               return row["rfaNumber"] === row["RFA Ref"];
            } else {
               return row["rfaNumber"] + btnActive === row["RFA Ref"];
            }
         });
         setRowsInThisVersion(rowsInThisVersion);

         const oneRowInThisRef = rowsInThisVersion[0] || {};

         const rfaInfo =
            rfaData.find((r: any) => getRefStringWithVersion(r, "rfa") === oneRowInThisRef["RFA Ref"]) || {};
         const consultantLead = (getInfoValueFromRefDataForm(rfaInfo, "submission", "rfa", "consultantMustReply") ||
            [])[0];
         const stateLead = getInfoValueFromRefDataForm(oneRowInThisRef, "reply", "rfa", "status", consultantLead);

         if (!stateLead) {
            const due = getInfoValueFromRefDataForm(rfaInfo, "submission", "rfa", "due");
            if (due) {
               const nosOfDate = compareDatesForm(due);
               if (nosOfDate >= 0 && nosOfDate < 3) {
                  setDueType("Due in the next 1 - 3 days");
               } else if (nosOfDate < 0) {
                  setDueType("Overdue");
               } else {
                  setDueType("Due in the next 4 - 14 days");
               }
            }
         } else {
            setDueType("");
         }

         setRfaInfo(rfaInfo);
         const refTitle = getInfoValueFromRefDataForm(rfaInfo, "submission", "rfa", "emailTitle");
         setRefTitle(refTitle);
      } else {
         const rowsInThisVersion = rowsInThisRef.filter((row: any) => {
            if (btnActive === "All") {
               const latestVersion = arrayBtnVersion[arrayBtnVersion.length - 1];
               return row["revision"] === latestVersion;
            } else {
               return row["revision"] === btnActive;
            }
         });

         setRowsInThisVersion(rowsInThisVersion);

         const oneRowInThisRef = rowsInThisVersion[0];

         if (refType === "rfam" || refType === "rfi") {
            const consultantLead = (getInfoValueFromRefDataForm(
               oneRowInThisRef,
               "submission",
               refType,
               "consultantMustReply"
            ) || [])[0];
            const stateLead = getInfoValueFromRefDataForm(oneRowInThisRef, "reply", refType, "status", consultantLead);

            if (!stateLead) {
               const due = getInfoValueFromRefDataForm(oneRowInThisRef, "submission", refType, "due");
               if (due) {
                  const nosOfDate = compareDatesForm(due);

                  if (nosOfDate >= 0 && nosOfDate < 3) {
                     setDueType("Due in the next 1 - 3 days");
                  } else if (nosOfDate < 0) {
                     setDueType("Overdue");
                  } else {
                     setDueType("Due in the next 4 - 14 days");
                  }
               }
            } else {
               setDueType("");
            }
         }

         const refTitle = getInfoValueFromRefDataForm(oneRowInThisRef, "submission", refType, "emailTitle");
         setRefTitle(refTitle);
      }
   }, [btnActive, rowsInThisRef]);

   return rowsInThisVersion.length > 0 ? (
      <div className="flex-container" style={{ margin: 0 }}>
         <WingBlank size="lg" style={{ margin: 0 }}>
            <Card
               style={{
                  width: window.innerWidth - 20,
                  border: `1px solid ${colorType.grey1}`,
                  boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
               }}
            >
               <Card.Header
                  style={{ padding: 10, height: 35 }}
                  title={
                     <div
                        style={{
                           fontWeight: "bold",
                           fontSize: 17,
                           color:
                              dueType === "Due in the next 4 - 14 days"
                                 ? colorType.grey2
                                 : dueType
                                 ? colorTextRow[dueType]
                                 : "black",
                        }}
                     >
                        {trimRefName(refNumber)}
                     </div>
                  }
                  extra={
                     <Flex justify="end">
                        {[...arrayBtnVersion, "All"].map((btn: string, i: number) => (
                           <Button
                              key={i}
                              style={{
                                 width: 25,
                                 height: 23,
                                 lineHeight: 1.5,
                                 padding: 0,
                                 marginLeft: 3,
                                 textAlign: "center",
                                 fontSize: 15,
                                 backgroundColor: btn === btnActive ? colorType.primaryMobile : colorType.grey4,
                                 color: "black",
                                 fontWeight: "bold",
                              }}
                              size="small"
                              onClick={() => {
                                 setBtnActive(btn);
                              }}
                           >
                              {btn}
                           </Button>
                        ))}
                     </Flex>
                  }
               />

               <Card.Body style={{ padding: 10 }}>
                  <div
                     style={{
                        fontWeight: "bold",
                        marginBottom: 5,
                        color:
                           dueType === "Due in the next 4 - 14 days"
                              ? colorType.grey2
                              : dueType
                              ? colorTextRow[dueType]
                              : "black",
                     }}
                  >
                     {refTitle}
                  </div>

                  <TableDrawings
                     col={["45%", "55%"]}
                     dataInput={rowsInThisVersion}
                     history={history}
                     btnActive={btnActive}
                     arrayBtnVersion={arrayBtnVersion}
                     rfaInfo={rfaInfo}
                     dueType={dueType}
                     refType={refType}
                     historyState={historyState}
                     companies={companies}
                  />
               </Card.Body>
            </Card>

            <WhiteSpace size="lg" />
         </WingBlank>
      </div>
   ) : (
      <div></div>
   );
};

export default PanelFormTypeOne;

const TableDrawings = (props: any) => {
   const { col, dataInput, arrayBtnVersion, btnActive, history, rfaInfo, dueType, refType, historyState, companies } =
      props;

   return (
      <div
         style={{
            width: "100%",
            borderTop: "1px solid black",
            borderLeft: "1px solid black",
            display: "flex",
            flexDirection: "column",
            borderCollapse: "collapse",
         }}
      >
         {getTableInputData(dataInput, refType, rfaInfo, companies).map((row: any, i: number) => {
            return (
               <div
                  key={i}
                  style={{
                     display: "flex",
                     flexDirection: "row",
                     height: 25,
                  }}
               >
                  {row.map((cell: any, j: number) => {
                     return (
                        <div
                           key={j}
                           style={{
                              borderRight: "1px solid black",
                              borderBottom: "1px solid black",
                              display: "flex",
                              alignContent: "center",
                              flexWrap: "wrap",
                              width: col[j],
                              padding: j === 0 ? 5 : 0,
                              color:
                                 j === 0 && dueType === "Due in the next 4 - 14 days"
                                    ? colorType.grey2
                                    : j === 0 && dueType
                                    ? colorTextRow[dueType]
                                    : "black",
                              overflow: "hidden",
                              background: i === 0 ? "#F5F5F5" : "transparent",
                           }}
                        >
                           {j === 0 ? (
                              <>
                                 <div style={{ width: 40 }}>{cell.companyShort}</div>
                                 <div>{cell.date ? ` - ${cell.date}` : ""}</div>
                              </>
                           ) : (
                              <>
                                 {sortTotalStatusRfaOrder(cell).map((stt: any, i: number) => {
                                    return (
                                       <div
                                          key={i}
                                          style={{
                                             width: `${(100 * cell[stt]) / getTotalStatusInRfa(cell)}%`,
                                             background: stt === "Consultant reviewing" ? "white" : colorTextRow[stt],
                                             textAlign: "center",
                                             paddingTop: 1,

                                             fontWeight: "bold",
                                             height: "100%",
                                          }}
                                          onClick={() => {
                                             if (stt) {
                                                const company = row[0].company;

                                                const objData =
                                                   refType === "rfa"
                                                      ? {
                                                           rfaDrawings:
                                                              company === "Woh Hup"
                                                                 ? dataInput
                                                                 : dataInput.filter(
                                                                      (r: any) =>
                                                                         getInfoValueFromRefDataForm(
                                                                            r,
                                                                            "reply",
                                                                            "rfa",
                                                                            "status",
                                                                            company
                                                                         ) === stt
                                                                   ),
                                                        }
                                                      : {
                                                           rowMultiForm: dataInput[0],
                                                        };

                                                history.push({
                                                   pathname: "/dms/item",
                                                   state: {
                                                      ...historyState,
                                                      data: {
                                                         rfaInfo,
                                                         revision:
                                                            btnActive === "All"
                                                               ? arrayBtnVersion[arrayBtnVersion.length - 1]
                                                               : btnActive,
                                                         status: stt,
                                                         company,
                                                         refType,
                                                         ...objData,
                                                      },
                                                   },
                                                });
                                             } else {
                                                Toast.info("There is no drawing!!!", 2);
                                             }
                                          }}
                                       >
                                          {cell[stt]}
                                       </div>
                                    );
                                 })}
                              </>
                           )}
                        </div>
                     );
                  })}
               </div>
            );
         })}
      </div>
   );
};

const getTableInputData = (rows: Array<any>, refType: string, rfaInfo: any, companies: Array<any>) => {
   const oneRowInThisRef = rows[0];

   const listConsultantMustReply =
      refType === "rfa"
         ? getInfoValueFromRefDataForm(rfaInfo, "submission", "rfa", "consultantMustReply")
         : getInfoValueFromRefDataForm(oneRowInThisRef, "submission", refType, "consultantMustReply");

   return ["Woh Hup", ...listConsultantMustReply].map((cmp) => {
      const { date } = getReplyInfoEachCompany(cmp, oneRowInThisRef, refType, rfaInfo);
      let allStatus: any = {};
      rows.forEach((row: any) => {
         const { status } = getReplyInfoEachCompany(cmp, row, refType, rfaInfo);
         if (status) {
            allStatus[status] = (allStatus[status] || 0) + 1;
         }
      });

      const cmpFound = companies.find((cm) => cm["company"] === cmp) || {};
      const companyShort = cmpFound["shortName"] || getCompanyNameShort(cmp);

      return [
         {
            company: cmp,
            companyShort: cmp === "Woh Hup" ? "WH" : companyShort.slice(0, 3),
            date,
         },
         allStatus,
      ];
   });
};

const trimRefName = (ref: string) => {
   const dis = getDisciplineOfRefString(ref);
   const parts = ref.split("/");
   return dis + "/" + parts[parts.length - 1];
};
const convertDate = (date: string) => {
   return date ? moment(date).format("DD/MM/YY") : "";
};
const getReplyInfoEachCompany = (company: string, oneRowInThisRef: any, refType: string, rfaInfo: any) => {
   if (refType === "rfa") {
      return {
         status:
            company === "Woh Hup"
               ? "Submitted"
               : getInfoValueFromRefDataForm(oneRowInThisRef, "reply", "rfa", "status", company),
         date:
            company === "Woh Hup"
               ? convertDate(getInfoValueFromRefDataForm(rfaInfo, "submission", "rfa", "date"))
               : convertDate(getInfoValueFromRefDataForm(oneRowInThisRef, "reply", "rfa", "date", company)),
      };
   } else {
      return {
         status:
            company === "Woh Hup"
               ? "Submitted"
               : getInfoValueFromRefDataForm(oneRowInThisRef, "reply", refType, "status", company),
         date:
            company === "Woh Hup"
               ? convertDate(getInfoValueFromRefDataForm(oneRowInThisRef, "submission", refType, "date"))
               : convertDate(getInfoValueFromRefDataForm(oneRowInThisRef, "reply", refType, "date", company)),
      };
   }
};
const getTotalStatusInRfa = (obj: any) => {
   let output = 0;
   Object.keys(obj).forEach((stt) => {
      output += obj[stt];
   });
   return output;
};
const sortTotalStatusRfaOrder = (obj: any) => {
   let output: Array<string> = [];
   statusOrderArray.forEach((stt: string) => {
      if (obj[stt]) {
         output.push(stt);
      }
   });
   return output;
};
