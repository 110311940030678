import { useEffect } from 'react';
import { VStack, Center, Button, Box } from 'native-base';
import { setProject } from '../../features/app/projectSlice';
import { useAppDispatch } from '../../app/hooks';
import _ from 'lodash';
import { modules } from '../../routes/ControlRoute';
import { controlProject } from '../module_BimApp/AdminFunction'

export default function ProjectPage(props: any) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    controlProject(props, dispatch, setProject)
    // dispatch(
    //   setProject({
    //     userCreatedProject: props.location.state.userCreatedProject,
    //     projectId: props.location.state.projectId,
    //     projectName: props.location.state.projectName,
    //     sharePointId: props.location.state.sharePointId,
    //     issueKey: props.location.state.issueKey,
    //     typeProject: props.location.state.typeProject,
    //     trimbleKey: props.location.state.trimbleKey,
    //     useRfa: props.location.state.useRfa,
    //     isDetail: true,
    //   })
    // );
  }, []);
  const routes = modules(props.location.state, 20);
  const handlePage = (path: string) => {
    // props.history.push(path);
    props.history.push({
      pathname: path,
      state: {
        userCreatedProject: props.location.state.userCreatedProject,
        projectId: props.location.state.projectId,
        projectName: props.location.state.projectName,
        sharePointId: props.location.state.sharePointId,
        issueKey: props.location.state.issueKey,
        typeProject: props.location.state.typeProject,
        trimbleKey: props.location.state.trimbleKey,
        useRfa: props.location.state.useRfa,
        isDetail: true,
      },
    });
  };
  return (
    <div style={{ position: 'fixed', height: '100%', width: '100%' }}>
      <Center height={'100%'} justifyContent='center'>
        <VStack space={5} alignItems='center'>
          {routes.map((i: any) => (
            <Box>
              <Button
                bg='yellow.500'
                colorScheme='yellow'
                width={300}
                height={90}
                //  maxHeight={80}
                startIcon={i.icon}
                onPress={handlePage.bind(this, i.path)}
                _text={{
                  fontSize: 22
                }}
              >
                {i.name}
              </Button>
            </Box>
          ))}
        </VStack>
      </Center>
    </div>
  );
}
