import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface DmsRowState {
   value: Object;
   status: 'idle' | 'loading' | 'failed';
}

const initialState: DmsRowState = {
   value: {},
   status: 'idle',
};


export const dmsRowSlice = createSlice({
   name: 'dmsRow',
   initialState,
   reducers: {
      fetchAllRows: (state, action: PayloadAction<any>) => {
         state.value = action.payload;

      },
   },
});

export const { fetchAllRows } = dmsRowSlice.actions;

export const getDmsStateRow = (state: RootState) => state.dmsRow.value;



export default dmsRowSlice.reducer;
