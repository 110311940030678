import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { notification, Checkbox, Modal } from 'antd';
import { useSelector } from 'react-redux';
//import { apiCreateTimeCard } from '../api/apiLambda';
 import { apiCreateTimeCard } from '../api/setWorkingTimeApi';
const CreateTimecardPdf: React.FC<any> = ({
  isModalVisible,
  setIsModalVisible,
  worker_ts,
  month,
  toggle_refresh,
}) => {
  const [selectedWorkerIds, setSelectedWorkerIds] = useState<any>([]);
  const projectId = useSelector((state: any) => state.project.value.projectId);
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  async function handleSummit() {
    //call lambda api gateway

    let monthYear = moment(month, 'MMM-YYYY').format('YYYY-M');
    console.log('monthYear', monthYear);
    let request = {
      ids: selectedWorkerIds,
      projectId: projectId,
      month: monthYear,
    };

    console.log('request', request);

    let r = await apiCreateTimeCard(request);
    console.log('r', r);
    setIsModalVisible(false);
    toggle_refresh();
    //reload data

    notification.info({
      message: 'we have success created timecard, please check email.',
    });
  }

  function getOptsForCheckBox() {
    let submiteds = worker_ts.filter((x: any) => x.no_of_not_submit === 0);
    let notYetSubmited = worker_ts.filter((x: any) => x.no_of_not_submit !== 0);

    let opts = submiteds.map((x: any) => ({
      label: x.workerName,
      value: x.id,
    }));

    opts = [
      ...opts,
      ...notYetSubmited.map((x: any) => ({
        label: `${x.workerName} -> not complete key in`,
        value: x.id,
        disabled: true,
      })),
    ].filter((x: any) => x.value > 0);

    return opts;
  }

  const onChange = (checkedValues: any) => {
    setSelectedWorkerIds(checkedValues);
  };

  return (
    <div>
      <Modal
        title='Select the worker to create timecard'
        visible={isModalVisible}
        onOk={handleSummit}
        onCancel={handleCancel}
        okText='Create'
        cancelText='Exit'
      >
        <Checkbox.Group
          options={getOptsForCheckBox()}
          defaultValue={['Apple']}
          onChange={onChange}
        />
      </Modal>
    </div>
  );
};

export default CreateTimecardPdf;
