import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  Button,
  Select,
  Input,
  DatePicker,
  Badge,
  Tooltip,
  Divider,
} from 'antd';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { getSubmitStatusForRangeDays } from '../api/setWorkingTimeApi';
const { Option } = Select;

const DayNotSubmitBox: FunctionComponent<any> = (props: any) => {
  const {
    onChange,
    idSupervisor,
    isShowSetting,
    setIsShowSetting,
    selectedDay,
    setSelectedDay,
    reloadDaysNotSubmit,
  } = props;
  const [days, setDays] = useState<Array<any>>([]);
  const [allDaysOfMonth, setAllDayOfMonth] = useState<any>([1, 2]);
  const [currentDay, setCurrentDay] = useState<any>(moment());

  const [today, setToday] = useState<any>(moment());
  const [submitData, setSubmitData] = useState<any>([]);

  const projectId = useSelector((state: any) => state.project.value.projectId);

  useEffect(() => {
    checkDaySubmit();
  }, []);

  useEffect(() => {
    if (reloadDaysNotSubmit > 0) {
      console.log('reloadDaysNotSubmit', reloadDaysNotSubmit);
      checkDaySubmit();
    }
  }, [reloadDaysNotSubmit]);

  useEffect(() => {
    onChange(currentDay);
    setSelectedDay(currentDay);
  }, [currentDay]);

  async function checkDaySubmit() {
    let dt = await getSubmitStatusForRangeDays({
      projectId,
      idSupervisor: idSupervisor,
      isAllTime: 1,
    });

    await setSubmitData(dt.data);

    let momentDays = new Array<any>();

    if (_.isArray(dt.data)) {
      let i = 0;
      for (const data of dt.data) {
        if (i < 5) {
          momentDays.push(moment(data.date, 'DD-MM-YYYY'));
          i++;
        }
      }

      momentDays = _.sortBy(momentDays, (m) => m.toDate());

      setDays(momentDays);
    }
  }

  function handleDateBoxClick(x: any) {
    if (x.isAfter(moment())) {
      return;
    }
    setCurrentDay(x);
  }

  function getStyleDateBox(date: any, isNotSubmit: any) {
    let style = {};
    if (date.isSame(currentDay, 'day')) {
      style = { background: '#f5dcdc', border: '3px solid black' };
    } else {
      style = { background: '#f8f9ff' };
    }

    if (isNotSubmit) {
      style = { ...style, color: 'red' };
    }

    if (date.isSame(today, 'day')) {
      style = { ...style, border: '3px solid #1890ff', color: '#1890ff' };
    }

    if (date.isAfter(today, 'day')) {
      style = { ...style, cursor: 'not-allowed', background: 'lightgrey' };
    }

    return style;
  }

  function checkIsNotSubmit(date: any) {
    if (_.isArray(submitData)) {
      let item = submitData.find((x) => date.format('DD-MM-YYYY') == x.date);
      return item;
    }

    return null;
  }

  function generateDayBox() {
    return days.map((x: any, index: number) => {
      let item = checkIsNotSubmit(x);
      return (
        <div
          className='text-center'
          key={index}
          onClick={() => handleDateBoxClick(x)}
        >
          {item ? (
            <Tooltip
              placement='bottom'
              title={item.supervisorNotSubmits.toString()}
            >
              <div className='dayBox' style={getStyleDateBox(x, true)}>
                <span className='dateString text-uppercase block'>
                  {x.format('MMM')}
                </span>
                <span>{x.format('DD')}</span>
              </div>
            </Tooltip>
          ) : (
            <div>
              <div className='dayBox' style={getStyleDateBox(x, null)}>
                <span className='dateString text-uppercase block'>
                  {x.format('MMM')}
                </span>
                <span>{x.format('DD')}</span>
              </div>
            </div>
          )}
        </div>
      );
    });
  }

  return (
    <div>
      <div className=' grid grid-cols-4 gap-2 mb-2 '>
        {submitData.length > 0 ? (
          <div
            className='col-span-1 p-2 not-submit-day-box'
            style={{ color: 'red', fontSize: '14px' }}
            onClick={() => setIsShowSetting(!isShowSetting)}
          >
            <div>{submitData.length} days</div>
            <div> not submit</div>
          </div>
        ) : (
          <Button
            type='primary'
            size='large'
            style={{ fontSize: '16px' }}
            className='col-span-1'
            onClick={() => setIsShowSetting(!isShowSetting)}
          >
            Calender
          </Button>
        )}

        <div className='col-span-3 flex flex-row justify-between workerCalender'>
          <div
            className='flex flex-row 
justify-start items-end '
          >
            {generateDayBox()}
          </div>

          <div className='flex flex-row'>
            <div className='vl mr-1'></div>
            <div
              className='dayBoxCurrent '
              style={getStyleDateBox(selectedDay, null)}
            >
              <span className='dateString text-uppercase block'>
                {selectedDay.format('MMM')}
              </span>
              <span>{selectedDay.format('DD')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayNotSubmitBox;
