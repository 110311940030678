import { Button } from 'antd-mobile';
import { Center } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import '../../../scss/module_DMS/style.scss';
import { apiFetchProjectInfo, apiMobileFetchProjectPublicSetting } from '../../module_DMS/api/dmsApi';
import Loading from '../../module_DMS/components/Loading';
import { convertRowHistoryData, getDataForMultiFormSheet, getDataForRfaSheet } from '../../module_DMS/utils';
import { useQuery } from 'react-query';


const arrayPage = ['sum', 'rfa', 'rfam', 'rfi', 'cvi', 'dt', 'mm'];



const DmsPageType = (props: any) => {

   const { history } = props;

   const { location: { pathname, state: historyState } } = history;
   

   const parts = pathname.split('/');
   const projectId = parts[parts.length - 1];

   const { token, email } = useSelector((state: any) => {
      const { project: { value: valueProject }, user: { value: valueUser } } = state;
      return {
         projectId: valueProject.projectId,
         token: valueUser.token,
         email: valueUser.email,
      };
   });


   let disciplineForm: any = [];
   let companies: any = [];

   const { isLoading, isError, error } = useQuery('query-public-setting', () => {
      return Promise.all([
         apiMobileFetchProjectPublicSetting({ token, projectId }),
         apiFetchProjectInfo({ projectId, token }),
      ]);
   }, {
      // staleTime: 30000,
      select: ([resPublicSetting, resProjectInfo]) => {
         disciplineForm = resPublicSetting.data['disciplineForm'];
         companies = resProjectInfo.data['listContractor'];
      }
   });


   const [currentWindow, setCurrentWindow] = useState({ width: window.innerWidth, height: window.innerHeight });
   useEffect(() => {
      const handleResize = () => {
         setCurrentWindow({ width: window.innerWidth, height: window.innerHeight });
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   });



   return (
      <div>
         {isLoading ? (
            <div style={{ position: 'fixed', top: 0, height: currentWindow.height, width: '100%' }}>
               <Center height={'100%'} justifyContent='center'>
                  <Loading />
               </Center>
            </div>
         ) : (
            <div style={{ position: 'fixed', height: '100%', width: '100%' }}>
               <Center height={'100%'} justifyContent='center'>
                  {arrayPage.map(btn => (
                     <Button
                        key={btn}
                        onClick={() => {
                           history.push({
                              pathname: btn === 'sum' ? `/dms/sum/chart` : `/dms/${btn}`,
                              state: {
                                 ...historyState,
                                 disciplineForm,
                                 companies
                              }
                           });
                        }}
                        style={{
                           width: '70%',
                           marginBottom: 10,
                           background: 'rgb(234, 179, 8)',
                           color: 'black',
                           fontWeight: 'bold'
                        }}
                     >
                        {btn.toUpperCase()}
                     </Button>
                  ))}
               </Center>
            </div>
         )}
      </div>
   );
};




export default DmsPageType;











