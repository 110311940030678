import Axios from 'axios';
import BaseResponse from '../models/BaseResponse';


async function apiFetchPublicUrl(payload: any): Promise<BaseResponse> {

   const newPayload = window.location.origin.includes('https://m-idd.wohhup.com')
      ? { ...payload, type: 'idd-dms' }
      : { ...payload, type: 'wh-idd-dms' };

   return await Axios.get('/api/issue/get-public-url', { params: newPayload });
};


async function apiFetchProjectInfo(payload: any): Promise<BaseResponse> {
   return await Axios.post('/api/projects/get-information-project', payload);
};

async function apiMobileFetchProjectPublicSetting(payload: any): Promise<BaseResponse> {
   return await Axios.get('/api/row-mobile/mobile-get-public-setting-project', { params: payload });
};
async function apiMobileFetchDataThisMultiFormThisDiscipline(refType: string, payload: any): Promise<BaseResponse> {
   return await Axios.get(`/api/row-mobile/get-project-data-mobile-discipline/${refType}`, { params: payload });
};
async function apiMobileFetchDataThisRfaFormThisDiscipline(payload: any): Promise<BaseResponse> {
   return await Axios.get('/api/row-mobile/get-project-data-mobile-discipline', { params: payload });
};

async function apiMobileFetchDataSum(payload: any): Promise<BaseResponse> {
   return await Axios.get('/api/row-mobile/get-sum-data-mobile', { params: payload });
};


export {
   apiFetchPublicUrl,
   apiFetchProjectInfo,
   apiMobileFetchProjectPublicSetting,
   apiMobileFetchDataThisMultiFormThisDiscipline,
   apiMobileFetchDataThisRfaFormThisDiscipline,
   apiMobileFetchDataSum
};
