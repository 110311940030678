import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

import { socket } from '../../components/module_BimApp/AdminFunction';
export interface UserState {
  value: Object;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: UserState = {
  value: {},
  status: 'idle',
};


export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
      localStorage.bookwormJWT = action.payload.token;
    },
    logout: (state,action: PayloadAction<any>) => {
      localStorage.removeItem("hello");
      localStorage.removeItem("bookwormJWT");
      localStorage.removeItem("userWh5Dapp");
      state.value = {}
      socket.emit('leave', action.payload.email);
    },
  },
});

export const { login, logout} = userSlice.actions;

export const selectUser = (state: RootState) => state.user.value;



export default userSlice.reducer;
