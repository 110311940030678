import { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { LoginOutlined } from '@ant-design/icons';
import {
  Center,
  Button,
  Heading,
  Box,
  Image,
  HStack,
  useColorMode,
  VStack,
  Spinner, Container
} from 'native-base';
import {
  handleLoginMs,
  socket,
  handleErrAuthMicrosoft, checkWohhupUser
} from '../module_BimApp/AdminFunction';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectUser, login } from '../../features/app/userSlice';
import decode from 'jwt-decode';
import { Client } from '@microsoft/microsoft-graph-client';
import { message } from 'antd';
import axios from 'axios';
declare global {
  interface Window {
    hello: any;
  }
}
function HomePage(props: any) {
  const user = useAppSelector<any>(selectUser);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (user.token) {
      const payload = decode<any>(user.token);
      if (
        payload.email === 'admin@wohhup.com' ||
        !checkWohhupUser(payload.email)
      ) {
        setLoading(false);
        props.history.push('/projects');
        return;
      }
    }
    if (!localStorage.userWh5Dapp && window.hello('aad').getAuthResponse()) {
      window.client = Client.init({
        //!note
        authProvider: (done) => {
          done(null, window.hello('aad').getAuthResponse().access_token);
          // this.props.history.push("/projects")
        },
      });
      window.client.api('/me').get((err: any, res: any) => {
        if (!err) {
          if (res.mail === 'admin@wohhup.com') {
            message.warning('You have to use wohhup mail');
            localStorage.removeItem('hello');
          } else {
            let userInfo = {
              userName: res.displayName,
              title: res.jobTitle,
              company: 'Woh Hup Private Ltd',
              phone: res.mobilePhone === null ? '' : res.mobilePhone,
            };

            axios
              .post('/api/auth/microsoft-account', {
                email: res.mail.toLowerCase(),
                user: userInfo,
              })
              .then((user) => {
                props.history.push('/projects');
                localStorage.setItem('userWh5Dapp', JSON.stringify(res));
                socket.emit('client-send-username', res.mail);
                localStorage.bookwormJWT = user.data.user.token;
                dispatch(login(user.data.user));
                setLoading(false);
              })
              .catch((err) => {
                localStorage.removeItem('hello');
                setLoading(false);
              });
          }
        } else {
          handleErrAuthMicrosoft(err, user.token);
          setLoading(false);
        }
      });
    } else if (
      localStorage.userWh5Dapp &&
      window.hello('aad').getAuthResponse()
    ) {
      props.history.push('/projects');
    } else {
      setLoading(false);
    }
  }, []);
  return (
    <div style={{ position: 'fixed', height: '100%', width: '100%' }}>
      <Center height={'100%'} justifyContent='center' >
        {loading ? (
          <HStack space={2}>
            <Heading color='yellow.600'>Idd App</Heading>
            <Spinner color='yellow.600' accessibilityLabel='Loading posts' />
          </HStack>
        ) : (
          <Box
            bg={colorMode === 'light' ? 'coolGray.50' : 'coolGray.900'}
            justifyContent='center'
            px={4}

            width={'90%'}
          >
            <VStack space={5} alignItems='center'>
              <Image
                source={{
                  uri: 'https://rawcdn.githack.com/Woh-Hup/Resource/81c2b83561ed9d81a5d267265e0432d44cbd9e8f/image/WH_Logo_Text.png',
                }}
                resizeMode='contain'
                style={{ width: 300, height: 150 }}
                alt='NativeBase logo'
              />

              <Heading size='lg'>
                Welcome to <Heading color='yellow.600'>IDD APP</Heading>
              </Heading>
              <Button
                onPress={handleLoginMs}
                size='lg'
                bg='yellow.600'
                _text={{ color: 'white' }}
                startIcon={
                  <LoginOutlined style={{ fontSize: 20, color: 'white' }} />
                }
              >
                Login
              </Button>
            </VStack>
          </Box>
        )}
      </Center>
    </div>
  );
}



export default HomePage;
