import {
  Heading,
  Center,
  Button,
  VStack,
} from 'native-base';

// Example template which wraps component with NativeBaseProvider
export default function NoPage(props: any) {
  return (
    <div style={{position:'fixed', height:'100%', width:'100%'}}>
      <Center height={'100%'} justifyContent='center' >
      <VStack>
        <Heading>
          Page Not Found.
          <Heading color='red.500'>{' '}404</Heading>
        </Heading>
        <Button
          onPress={() => {
            props.history.push('/')
          }}
          colorScheme='danger'
          _text={{
            color: 'white',
          }}
        >
          Back To Home
        </Button>
      </VStack>
    </Center>
    </div>
  );
}
