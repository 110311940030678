import { useEffect, useState } from 'react';
import {
  HStack,
  IconButton,
  HamburgerIcon,
  Avatar,
  Image,
  Button,
  VStack,
  Text,
  Pressable,
  Divider,
  Box,
  Modal,
} from 'native-base';
import {
  BellOutlined,
  LogoutOutlined,
  HomeOutlined,
  ProjectOutlined,
} from '@ant-design/icons';

import { selectUser, logout, login } from '../../features/app/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Drawer, message } from 'antd';
import axios from 'axios';
import decode from 'jwt-decode';
import { bucketS3, getPublicUrl,checkWohhupUser } from '../module_BimApp/AdminFunction';
import { selectProject, setProject } from '../../features/app/projectSlice';
import { Client } from '@microsoft/microsoft-graph-client';
import { modules } from '../../routes/ControlRoute';

function AppBar(props: any) {
  const [showModal, setShowModal] = useState(false);

  const user = useAppSelector<any>(selectUser);
  const project = useAppSelector<any>(selectProject);
  const dispatch = useAppDispatch();
  const [avatar, setAvatar] = useState({ avatar: '', img: '' });
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [pathName, setPathName] = useState('');
  const routes = modules(project, 14);
  useEffect(() => {
    if (window.hello("aad").getAuthResponse()) {
      //!note
      window.client = Client.init({
        authProvider: (done) => {
          done(null, window.hello("aad").getAuthResponse().access_token);
        },
      });
    }
    const payload = decode(user.token);
    refreshToken(payload);
    setAvatarUser(payload);
  }, []);
  const refreshToken = (payload: any) => {
    var current_time = new Date().getTime() / 1000;
    if (payload.exp < current_time) {
      let userInfo = null;
      message.info('Refreshing token...');
      if (
        localStorage.userWh5Dapp &&
        payload.email !== 'admin@wohhup.com' &&
        checkWohhupUser(payload.email)
      ) {
        userInfo = {
          userName: null,
          title: null,
          company: '',
          phone: null,
        };
        let user = JSON.parse(localStorage.userWh5Dapp);
        userInfo.userName = user.displayName;
        userInfo.title = user.jobTitle;
        userInfo.company = 'Woh Hup Private Ltd';
        userInfo.phone = user.mobilePhone === null ? '' : user.mobilePhone;
      }
      axios
        .post('/api/auth/refresh_token', {
          token: user.token,
          email: payload.email,
          user: userInfo,
        })
        .then((res: any) => {
          dispatch(login(res.data.user));
        })
        .catch(() => {
          dispatch(logout(payload.email));
        });
    }
  };
  const setAvatarUser = (payload: any) => {
    if (
      payload.email === 'admin@wohhup.com' ||
      !checkWohhupUser(payload.email)
    ) {
      let temp = payload.imgUrl;
      if (payload.imgUrl.includes(bucketS3))
        temp = payload.imgUrl.split(`${bucketS3}/`)[1];
      getPublicUrl(temp)
        .then((res: any) => {
          setAvatar({ avatar: 'Ad', img: res });
        })
        .catch(() => {
          setAvatar({ avatar: 'Ad', img: '' });
        });
      return;
    }
    if (localStorage.userWh5Dapp) {
      if (checkWohhupUser(payload.email)) {
        try {
          window.client.api('/me/photo/$value').get((err1: any, res1: any) => {
            if (err1) return;
            const url = window.URL || window.webkitURL;
            const blobUrl = url.createObjectURL(res1);
            setAvatar({ avatar: '', img: blobUrl });
          });
        } catch {
          setAvatar({ avatar: 'UN', img: '' });
        }
      } else {
        try {
          let user = JSON.parse(localStorage.userWh5Dapp);
          let split = user.displayName.split(' ');
          let first = split[0].slice(0, 1);
          let second = '';
          if (split.length > 1) second = split[split.length - 1].slice(0, 1);
          let temp = payload.imgUrl;
          if (payload.imgUrl.includes(bucketS3))
            temp = payload.imgUrl.split(`${bucketS3}/`)[1];
          getPublicUrl(temp)
            .then((res: any) => {
              setAvatar({
                avatar:
                  second === ''
                    ? first.toUpperCase()
                    : (first + second).toUpperCase(),
                img: res,
              });
            })
            .catch(() => {
              setAvatar({
                avatar:
                  second === ''
                    ? first.toUpperCase()
                    : (first + second).toUpperCase(),
                img: '',
              });
            });
        } catch {
          setAvatar({ avatar: 'UN', img: '' });
        }
      }
    }
  };
  const onCloseDrawer = () => {
    setIsOpenDrawer(false);
  };
  const handleLogout = () => {
    dispatch(logout(user.email));
  };
  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };
  const handlePage = (path: string) => {
    if (path === '/projects') {
      props.history.push({
        pathname: path,
      });
      dispatch(setProject({}));
    } else if (path.includes('/project'))
      props.history.push({
        pathname: path,
        state: {
          userCreatedProject: project.userCreatedProject,
          projectId: project.projectId,
          projectName: project.projectName,
          sharePointId: project.sharePointId,
          issueKey: project.issueKey,
          typeProject: project.typeProject,
          trimbleKey: project.trimbleKey,
          useRfa: project.useRfa,
          isDetail: true,
        },
      });
    else {
      props.history.push({
        pathname: path,
      });
    }
    setIsOpenDrawer(false);
  };
  useEffect(() => {
    setPathName(window.location.pathname);
  }, [window.location.pathname]);
  const handleHomePage=()=>{
    props.history.push('/')
  }
  return (
    <>
      {/* <StatusBar backgroundColor='#3700B3' barStyle='light-content' />
      <Box safeAreaTop backgroundColor='#6200ee' /> */}
              <div id="notification">A new version of this app is available <a style={{ color: '#01b9ff', textDecoration: 'underline', cursor: 'pointer' }} id="reload">Reload</a></div>
      <HStack
        bg='white'
        px={1}
        py={3}
        justifyContent='space-between'
        alignItems='center'
      >
        <HStack space={4} alignItems='center'>
          <IconButton
            icon={<HamburgerIcon size={5} />}
            size='sm'
            onPress={handleOpenDrawer}
          />
          <Pressable onPress={handleHomePage}>
          <Image
            source={{
              uri: 'https://rawcdn.githack.com/Woh-Hup/Resource/81c2b83561ed9d81a5d267265e0432d44cbd9e8f/image/WH_Logo_Text.png',
            }}
            alt='Alternate Text'
            style={{ width: 120, height: 35 }}          
          />
          </Pressable>
         
        </HStack>
        <HStack space={2}>
          <IconButton
            icon={<BellOutlined style={{ fontSize: 20 }} />}
            size='sm'
          />
          {/* {avatar.img !== '' ? (
            <Avatar
              size='sm'
              source={{
                uri: avatar.img,
              }}
            >
              {avatar.avatar}
            </Avatar>
          ) : (
            <Avatar size='sm'>{avatar.avatar}</Avatar>
          )} */}
        </HStack>
      </HStack>

      <Drawer
        placement='left'
        closable={false}
        onClose={onCloseDrawer}
        visible={isOpenDrawer}
        width={'50%'}
        bodyStyle={{ padding: 0 }}
      >
        <VStack divider={<Divider />} space={2} my={2} mx={1}>
          <Box px={4}>
            <Text bold color='gray.700'>
              User
            </Text>
            <Text fontSize={14} mt={1} color='gray.500' fontWeight={500}>
              {user.email}
            </Text>
          </Box>
          <VStack divider={<Divider />} space={4}>
            <VStack space={3}>
              <Text fontWeight={500} fontSize={14} px={5} color='gray.500'>
                Route
              </Text>
              <VStack space={2}>
                <Pressable
                  px={5}
                  py={3}
                  rounded='md'
                  bg={'transparent'}
                  onPress={handlePage.bind(this, '/projects')}
                >
                  <HStack space={7} alignItems='center'>
                    <HomeOutlined />
                    <Text fontWeight={500} color={'gray.700'}>
                      Home
                    </Text>
                  </HStack>
                </Pressable>
                {project.projectName && (
                  <>
                    <Pressable
                      px={5}
                      py={3}
                      rounded='md'
                      bg={
                        pathName === `/project/${project.projectId}`
                          ? 'gray.200'
                          : 'transparent'
                      }
                      onPress={handlePage.bind(
                        this,
                        `/project/${project.projectId}`
                      )}
                    >
                      <HStack space={7} alignItems='center'>
                        <ProjectOutlined />
                        <Text fontWeight={500} color={'gray.700'}>
                          {project.projectName}
                        </Text>
                      </HStack>
                    </Pressable>
                  </>
                )}
              </VStack>
            </VStack>
            {project.projectName && (
              <VStack space={3}>
                <Text fontWeight={500} fontSize={14} px={5} color='gray.500'>
                  Module
                </Text>
                {routes.map((i: any) => (
                  <Pressable
                    px={5}
                    py={3}
                    rounded='md'
                    bg={pathName === i.path ? 'gray.200' : 'transparent'}
                    onPress={handlePage.bind(this, i.path)}
                  >
                    <HStack space={7} alignItems='center'>
                      {i.icon}
                      <Text fontWeight={500} color={'gray.700'}>
                        {i.name}
                      </Text>
                    </HStack>
                  </Pressable>
                ))}
              </VStack>
            )}
            <VStack space={3}>
              <VStack space={3}>
                <Pressable
                  px={5}
                  py={3}
                  rounded='md'
                  bg={'transparent'}
                  onPress={() => {
                    // setIsOpenDrawer(false);
                    // setShowModal(true);
                    if (!window.confirm('Are you want to log out')) return;
                    handleLogout();
                  }}
                >
                  <HStack space={7} alignItems='center'>
                    <LogoutOutlined style={{ color: 'red' }} />
                    <Text fontWeight={500} color={'red.500'}>
                      Logout
                    </Text>
                  </HStack>
                </Pressable>
              </VStack>
            </VStack>
          </VStack>
        </VStack>
      </Drawer>

      {/* <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        zIndex={1000000}
      >
        <Modal.Content maxWidth='400px'>
          <Modal.CloseButton />
          <Modal.Header>Notification</Modal.Header>
          <Modal.Body>Are you want to log out?</Modal.Body>
          <Modal.Footer>
            <Button.Group variant='ghost' space={2}>
              <Button
                onPress={() => {
                  handleLogout();
                }}
              >
                ACCEPT
              </Button>
              <Button
                colorScheme='secondary'
                onPress={() => {
                  setShowModal(false);
                }}
              >
                CANCEL
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal> */}
    </>
  );
}

export default function App(props: any) {
  return <AppBar {...props} />;
}
