import React, { FunctionComponent, useState, useEffect } from 'react';
import { Button, Select, Input, DatePicker, Badge, Tooltip } from 'antd';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { getSubmitStatusForRangeDays } from '../api/setWorkingTimeApi';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
const { Option } = Select;

const Calendar: FunctionComponent<any> = (props: any) => {
  const { onChange, idSupervisor, setSelectedDay, selectedDay } = props;
  const [days, setDays] = useState<any>([]);
  const [today, setToday] = useState<any>(moment());
  const [submitData, setSubmitData] = useState<any>([]);

  const projectId = useSelector((state: any) => state.project.value.projectId);

  useEffect(() => {
    if (days.length < 1 || !days.some((day: any) => day.isSame(selectedDay))) {
      let days14 = getISOWeekDates2(moment(), 0);
      setDays(days14);
      checkDaySubmit();
    }
  }, [selectedDay]);

  useEffect(() => {
    onChange(selectedDay);
  }, [selectedDay]);

  useEffect(() => {
    checkDaySubmit();
  }, [days]);

  async function checkDaySubmit() {
    if (_.isArray(days) && days.length > 0) {
      let start = days[0];
      let end = days[days.length - 1];
      let dt = await getSubmitStatusForRangeDays({
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),
        projectId,
        idSupervisor: idSupervisor,
      });

      await setSubmitData(dt.data);
    }
  }

  function nextPrevDays(isNext: boolean = true) {
    if (isNext) {
      let days14 = getISOWeekDates2(days[days.length - 1], 1);

      setDays(days14);
    } else {
      let days14 = getISOWeekDates2(days[0], -1);

      setDays(days14);
    }
  }

  function getISOWeekDates2(dayMoment: any, type: any = 1) {
    let dates = [];
    if (type === 1) {
      for (let i = 0; i < 7; i++) {
        dates.push(moment(dayMoment).add(i, 'day'));
      }
    }

    if (type === -1) {
      for (let i = 0; i < 7; i++) {
        dates.push(moment(dayMoment).add(i * -1, 'day'));
      }

      dates.reverse();
    }

    if (type === 0) {
      for (let i = 0; i < 7; i++) {
        dates.push(moment(dayMoment).add(i * -1, 'day'));
      }

      dates.reverse();
    }

    return dates;
  }

  function getMonthRangeName() {
    if (_.isArray(days) && days.length > 0) {
      let first = days[0];
      let last = days[days.length - 1];
      if (first.format('MMM YY') === last.format('MMM YY')) {
        return first.format('MMM YY');
      } else {
        return first.format('MMM YY') + ' - ' + last.format('MMM YY');
      }
    }
  }

  function handleDateBoxClick(x: any) {
    if (x.isAfter(moment())) {
      return;
    }

    setSelectedDay(x);
  }

  function getStyleDateBox(date: any, isNotSubmit: any) {
    let style = {};
    if (date.isSame(selectedDay, 'day')) {
      style = { background: '#f5dcdc', border: '3px solid black' };
    } else {
      style = { background: '#f8f9ff' };
    }

    if (isNotSubmit) {
      style = { ...style, color: 'red' };
    }

    if (date.isSame(today, 'day')) {
      style = { ...style, border: '3px solid #1890ff', color: '#1890ff' };
    }

    if (date.isAfter(today, 'day')) {
      style = { ...style, cursor: 'not-allowed', background: 'lightgrey' };
    }

    return style;
  }

  function checkIsNotSubmit(date: any) {
    if (_.isArray(submitData)) {
      let item = submitData.find((x) => date.format('DD-MM-YYYY') == x.date);
      return item;
    }

    return null;
  }

  function onChangeDatePicker(date: any, dateString: any) {
    let days14 = getISOWeekDates2(date, 0);
    // setCurrentDay(date);
    setSelectedDay(date);
    setDays(days14);
  }

  function disabledEndDate(endValue: any) {
    if (!endValue || !selectedDay) {
      return false;
    }
    return endValue.valueOf() > selectedDay.valueOf();
  }

  const dateFormat = 'DD/MM/YYYY';

  function generateDayBox() {
    return days.map((x: any, index: number) => {
      let item = checkIsNotSubmit(x);
      return (
        <div
          className='text-center'
          key={index}
          onClick={() => handleDateBoxClick(x)}
        >
          {item ? (
            <Tooltip
              placement='bottom'
              title={item.supervisorNotSubmits.toString()}
            >
              <span className='dateString text-uppercase'>
                {x.format('ddd')}
              </span>
              <div className='dayBox' style={getStyleDateBox(x, true)}>
                <span>{x.format('DD')}</span>
              </div>
            </Tooltip>
          ) : (
            <div>
              <span className='dateString text-uppercase'>
                {x.format('ddd')}
              </span>
              <div className='dayBox' style={getStyleDateBox(x, null)}>
                <span>{x.format('DD')}</span>
              </div>
            </div>
          )}
        </div>
      );
    });
  }

  return (
    <div>
      <div className='flex flex-row mb-2  justify-between'>
        <span className='text-center font-weight-bold'>
          {getMonthRangeName()}
        </span>
        <DatePicker
          disabledDate={disabledEndDate}
          value={selectedDay}
          format={dateFormat}
          allowClear={false}
          onChange={onChangeDatePicker}
        />
      </div>
      <div className='flex mb-2 flex-row justify-between items-end workerCalender'>
        <LeftOutlined
          onClick={() => nextPrevDays(false)}
          className='mb-2 mr-2'
          type='left'
          style={{ fontSize: '20px', color: '#08c' }}
        />

        {generateDayBox()}

        <RightOutlined
          onClick={() => nextPrevDays(true)}
          className='mb-2'
          style={{ fontSize: '20px', color: '#08c' }}
          height='50px'
          type='right'
        />
      </div>
    </div>
  );
};

export default Calendar;
