import React, { FunctionComponent, useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';

import {
  DatePicker,
  DatePickerProps,
  Button,
  Col,
  Row,
  Select,
  Spin,
} from 'antd';

import type { RangePickerProps } from 'antd/es/date-picker';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import {
  DoubleRightOutlined,
  DoubleLeftOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import fakedata from './fakedata';
import {
  worker_ts_store,
  worker_refresh_store,
  worker_shown_not_keyin_store,
} from '../../../store/workerStore';

import '../../../scss/module_Worker/monthlyReport.scss';

import './WorkerTimesheet';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectProject, setProject } from '../../../features/app/projectSlice';
import BaseTypeModel from '../models/BaseTypeModel';
import CreateTimecardPdf from './CreateTimecardPdf';

import { getSupervisorByEmail } from '../api/setWorkingTimeApi';

import {
  getMyInfomation,
  searchAllSupervisor,
  getWorkersByMonthAndSupervisor,
} from '../api/setWorkingTimeApi';
import WorkerTimesheet from './WorkerTimesheet';

import { WorkerTimesheetModel } from '../models/WorkerTimeSheetModel';

const dateFormat = 'MMM-YYYY';

const MonthlyReport: FunctionComponent<any> = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reloadDaysNotSubmit, setReloadDaysNotSubmit] = useState<number>(0);
  const [role, setRole] = useState<any>('');
  const [selectedDay, setSelectedDay] = useState<any>(moment());
  const [selectedSupervisorId, setSelectedSupervisorId] = useState<any>(null);
  const [isShowSetting, setIsShowSetting] = useState(false);
  const [save, setSave] = useState(0);
  const [month, setMonth] = useState(moment().format(dateFormat));
  const [supervisors, setSupervisors] = useState(new Array<BaseTypeModel>());
  const [noOfFold, setNoOfFold] = useState<number>(0);
  const [clickKeyIn, setClickKeyIn] = useState(false);

  const { set_Data, toggleIsFold, worker_ts } = worker_ts_store();
  const { refresh, toggle_refresh } = worker_refresh_store();
  const { not_keyin, toggle_not_keyin } = worker_shown_not_keyin_store();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [allCostCodes, setAllCostCodes] = useState(new Array<any>());
  const [workerDatas, setWorkerDatas] = useState(new Array<any>());
  const [workerDataFilters, setDataFilter] = useState(new Array<any>());
  const projectId = useSelector((state: any) => state.project.value.projectId);

  const [supervisor, setSupervisor] = useState<any>();

  const dispatch = useAppDispatch();
  const project = useAppSelector<any>(selectProject);
  const email = useSelector((state: any) => state.user?.value?.email);

  const get_test_data = fakedata();

  useEffect(() => {
    getSupervisors();
    // set_Data(get_test_data);
  }, []);

  useEffect(() => {
    getMyInfomation().then((e) => {
      if (e && e.user && e.user.contractors) {
        let item = e.user.contractors.find(
          (x: any) => x.projectId === projectId
        );

        if (item) {
          setRole(item.role);
        } else {
          setRole('');
        }
      }
    });
  }, [projectId]);

  //need change here to get idsupervisor.
  useEffect(() => {
    async function getSupervisor() {
      if (project.projectId) {
        try {
          let res = await getSupervisorByEmail({
            projectId: project.projectId,
            email,
          });

          setSupervisor(res.data);
          if (res.data !== undefined) {
            if (res.data.id !== undefined) {
              setSelectedSupervisorId(res.data.id);
            }
          }
        } catch (error) {
          return null;
        }
      }
    }

    getSupervisor();
  }, [projectId]);

  useEffect(() => {
    if (selectedSupervisorId) {
      getWorkerDatas();
    }
  }, [month, selectedSupervisorId, refresh]);

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setMonth(dateString);
    console.log('dateString', dateString);
    console.log(date, dateString);
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('month');
  };

  async function getSupervisors(isLevel: boolean = true) {
    let payload = {
      filterBy: '',
      pageIndex: 1,
      pageSize: 1000,
      type: isLevel ? 1 : 2,
      projectId,
    };

    let res = await searchAllSupervisor(payload);
    let lvs = res.data.items.map((x: any) => ({
      id: x.id,
      name: x.name,
    })) as BaseTypeModel[];

    setSupervisors(lvs);
  }

  async function getWorkerDatas() {
    setIsLoading(true);
    try {
      let res = await getWorkersByMonthAndSupervisor(selectedSupervisorId, {
        date: month,
      });

      if (res.data) {
        let newData = res.data;
        if (worker_ts !== undefined && worker_ts.length > 0) {
          newData = newData.map((item: any) => {
            const findFold = worker_ts.find((i) => i.id === item.id);
            if (findFold !== -1) {
              return { ...item, isFold: findFold.isFold };
            }
          });
        }

        set_Data(newData);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const FoldAll = () => {
    if (noOfFold > 0) {
      setNoOfFold(0);
      const reset_Fold = worker_ts.map((obj: WorkerTimesheetModel) => {
        return { ...obj, isFold: true };
      });
      set_Data(reset_Fold);
    } else {
      setNoOfFold(worker_ts.length);
      const reset_Fold = worker_ts.map((obj: WorkerTimesheetModel) => {
        return { ...obj, isFold: false };
      });
      set_Data(reset_Fold);
    }
  };

  return (
    <div className='class-content' style={{ backgroundColor: '#f5f6f7' }}>
      <div className='flex flex-row  mb-2'>
        <label style={{ width: 150 }} className=' ml-2'>Supervisor :</label>
        <Select
          value={selectedSupervisorId}
          style={{ width: '100%' }}
          onChange={(e: any) => {
            setSelectedSupervisorId(e);
          }}
        >
          {supervisors.map((supervisor) => (
            <Select.Option value={supervisor.id}>
              {supervisor.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className='monthly-report-toolbar'>
        <Row>
          <Col span={3}>
            <Button
              shape='circle'
              icon={<ArrowLeftOutlined />}
              style={{ marginTop: '5px', float: 'left', marginLeft: '5px' }}
              onClick={() => history.push('/worker')}
            ></Button>
          </Col>

          <Col span={7}>
            <DatePicker
              format='MMM-YYYY'
              size='small'
              picker='month'
              defaultValue={moment(moment(), 'MMM-YYYY')}
              style={{ marginTop: '9px', textAlign: 'center', width: '110px' }}
              disabledDate={disabledDate}
              onChange={onChange}
            />
          </Col>
          <Col span={6}>
            <div
              className={
                not_keyin
                  ? 'monthly-report-toolbar-text-click'
                  : 'monthly-report-toolbar-text'
              }
              onClick={() => toggle_not_keyin()}
            >
              Not KeyIn
            </div>
          </Col>
          <Col
            span={6}
            style={{ marginTop: '10px' }}
            onClick={() => setIsModalVisible(true)}
          >
            Timecard
          </Col>
          <Col span={2}>
            <Button
              shape='circle'
              type={noOfFold > 0 ? 'primary' : 'default'}
              icon={
                noOfFold > 0 ? <DoubleLeftOutlined /> : <DoubleRightOutlined />
              }
              style={{ marginTop: '5px', float: 'right', marginRight: '5px' }}
              onClick={() => FoldAll()}
            ></Button>
          </Col>
        </Row>
      </div>

      <Spin spinning={isLoading}>
        <div style={{ marginTop: '55px' }}>
          {worker_ts.map((item: WorkerTimesheetModel) => (
            <div key={item.id}>
              <WorkerTimesheet
                info={item}
                noOfFold={noOfFold}
                setNoOfFold={setNoOfFold}
              />
            </div>
          ))}
        </div>
      </Spin>

      <CreateTimecardPdf
        toggle_refresh={toggle_refresh}
        month={month}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        worker_ts={worker_ts}
      />
    </div>
  );
};

export default MonthlyReport;
