import React, { FunctionComponent, useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Button, Col, Row, Space } from 'antd';

import {
  DownOutlined,
  UpOutlined,
  TableOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';

// import { LocaleProvider } from "antd-mobile";
// import enUS from "antd-mobile/lib/locale-provider/en_US";

import { worker_ts_store } from '../../../store/workerStore';

import WorkerDailyTimeCard from './WorkerDailyTimeCard';

import '../../../scss/module_Worker/monthlyReport.scss';
import { dateInfo, WorkerTimesheetModel } from '../models/WorkerTimeSheetModel';
import { worker_shown_not_keyin_store } from '../../../store/workerStore';

import { apiGetPublicUrlS3 } from '../api/setWorkingTimeApi';

type Props = {
  info: any;
  noOfFold: number;
  setNoOfFold: (val: number) => void;
};

const WorkerTimesheet: React.FC<Props> = ({ info, noOfFold, setNoOfFold }) => {
  const { toggleIsFold } = worker_ts_store();
  const { not_keyin } = worker_shown_not_keyin_store();

  const onClickFold = () => {
    toggleIsFold(info.id);
    if (info.isFold) {
      setNoOfFold(noOfFold + 1);
    } else {
      setNoOfFold(noOfFold - 1);
    }
  };

  return (
    <div className='timesheet-worker-card'>
      <div
        className={
          info.isCompleted
            ? 'timesheet-header-completed'
            : 'timesheet-header-not-completed'
        }
      >
        <Row>
          <Col span={16}>
            <div style={{ margin: '5px' }}>
              <strong>{info.workerName} </strong>
              {info.isCompleted ? (
                <div> Completed </div>
              ) : (
                <div>
                  <strong>{info.no_of_not_submit} days</strong> not key in
                </div>
              )}
            </div>
          </Col>
          <Col span={8} className='h-full'>
            <div
              className=' flex flex-row items-center h-full '
              style={{ height: '100%' }}
            >
              <Button
                className='mr-2'
                shape='circle'
                type={'default'}
                icon={info.isCreatedTimecard ? <TableOutlined /> : null}
              ></Button>

              <Button
                onClick={() => {
                  console.log('info', info);
                  apiGetPublicUrlS3({
                    key: info.timeCardData.Key,
                    type: info.timeCardData.Bucket,
                    expire: 100,
                  }).then((r: any) => {
                    console.log('r', r);
                    window.open(r);
                  });
                }}
                disabled={!info.timeCardData}
                className='mr-2'
                shape='circle'
                type={'default'}
                icon={<FilePdfOutlined />}
              ></Button>
              <Button
                shape='circle'
                type={info.isFold ? 'default' : 'primary'}
                icon={info.isFold ? <DownOutlined /> : <UpOutlined />}
                onClick={() => onClickFold()}
              ></Button>
            </div>
          </Col>
        </Row>
      </div>

      {!info.isFold && (
        <div>
          <div>
            <Row style={{ height: '20px' }}>
              <Col span={3}>
                <h3
                  style={{
                    marginTop: '-3px',
                    marginLeft: '10px',
                    fontFamily: 'sans-serif',
                    fontStretch: 'extra-condensed',
                  }}
                >
                  Date
                </h3>
              </Col>
              <Col span={9}>
                <div className='worker-daily-timecard-time'>
                  <Space>
                    <h3
                      style={{
                        marginTop: '-3px',
                        marginLeft: '35px',
                        fontFamily: 'sans-serif',
                        fontStretch: 'extra-condensed',
                      }}
                    >
                      Start
                    </h3>
                    <h3
                      style={{
                        marginTop: '-3px',
                        marginLeft: '15px',
                        fontFamily: 'sans-serif',
                        fontStretch: 'extra-condensed',
                      }}
                    >
                      hubble
                    </h3>
                  </Space>
                </div>
              </Col>

              <Col span={9}>
                <Space>
                  <h3
                    style={{
                      marginTop: '-3px',
                      marginLeft: '30px',
                      fontFamily: 'sans-serif',
                      fontStretch: 'extra-condensed',
                    }}
                  >
                    End
                  </h3>
                  <h3
                    style={{
                      marginTop: '-3px',
                      marginLeft: '10px',
                      fontFamily: 'sans-serif',
                      fontStretch: 'extra-condensed',
                    }}
                  >
                    hubble
                  </h3>
                </Space>
              </Col>

              <Col span={3}></Col>
            </Row>
          </div>
          <div>
            {info.dateInfo.map((item: any) => {
              if (!not_keyin) {
                return (
                  <div>
                    <WorkerDailyTimeCard daily_Info={item} />
                  </div>
                );
              } else {
                if (item.isSubmit == null) {
                  return (
                    <div>
                      <WorkerDailyTimeCard daily_Info={item} />
                    </div>
                  );
                }
              }
            })}
          </div>
        </div>
      )}
      {/* <BackTop visibilityHeight={700} /> */}
    </div>
  );
};

export default WorkerTimesheet;
