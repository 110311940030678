import { Drawer, Input } from "antd";
import { Button, Flex, Icon } from "antd-mobile";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fetchAllRows, getDmsStateRow } from "../../../features/dms/dmsRowSlice";
import "../../../scss/module_DMS/style.scss";
import PanelFormTypeOne from "../../module_DMS/components/PanelFormTypeOne";
import { colorType } from "../../module_DMS/constants";
import {
   arrangePanelOutputFinal,
   getAllTreeNodeAbove,
   getDataForRfaSheet,
   getDisciplineOfRefString,
} from "../../module_DMS/utils";
import Loading from "../../module_DMS/components/Loading";
import { FlatList, ScrollView } from "native-base";
import DatePickerComp from "../../module_DMS/components/DatePickerComp";
import {
   apiMobileFetchDataThisMultiFormThisDiscipline,
   apiMobileFetchDataThisRfaFormThisDiscipline,
} from "../../module_DMS/api/dmsApi";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Center } from "native-base";

const DmsPageAllForm = (props: any) => {
   const { history } = props;

   const {
      location: { pathname, state: historyState },
   } = history;

   const { projectId, companies, disciplineForm } = historyState;

   const { email, token } = useSelector((state: any) => {
      return {
         email: state.user?.value?.email,
         token: state.user?.value?.token,
      };
   });

   const partsPath = pathname.split("/");
   const tabDiscipline = partsPath[partsPath.length - 1];
   const refType = partsPath[partsPath.length - 2];

   const [modeFilter, setModeFilter] = useState(() => {
      const saved: any = localStorage.getItem(`dms-mode-filter-${refType}-${tabDiscipline}`);

      const initialValue = JSON.parse(saved);
      return (
         initialValue || {
            statusArray: [],
            disArray: [],
            overdueArray: [],
            searchText: "",
            dateFilter: null,
         }
      );
   });

   const data: any = {
      rowsAll: [],
      disciplineForm,
      companies,
      modeFilter,
   };

   const { isLoading, isError, error, isRefetching } = useQuery(
      `query-dms-form-${refType}-${tabDiscipline}`,
      () => {
         return refType === "rfa"
            ? apiMobileFetchDataThisRfaFormThisDiscipline({ projectId, email, token, tabDiscipline })
            : apiMobileFetchDataThisMultiFormThisDiscipline(refType, { projectId, email, token, tabDiscipline });
      },
      {
         select: (resData) => {
            if (refType === "rfa") {
               const { rowsAll, dataRfa } = resData.data;

               data["rowsAll"] = rowsAll;
               data["rfaData"] = dataRfa;
            } else {
               const { rowsData } = resData.data;
               data["rowsAll"] = rowsData;
            }
         },
      }
   );

   const [drawOpen, setDrawOpen] = useState(false);

   const processFilter = (modeFilter: any) => {
      localStorage.setItem(`dms-mode-filter-${refType}-${tabDiscipline}`, JSON.stringify(modeFilter));
      setModeFilter(modeFilter);
      setDrawOpen(false);
   };

   let dataTableInput = null;
   if (data && !_.isEmpty(data)) {
      dataTableInput = arrangePanelOutputFinal(data, `page-${refType}`).filter(
         (item: any) => item["arrayBtnVersion"] && item["arrayBtnVersion"].length > 0
      );
   }

   const [currentWindow, setCurrentWindow] = useState({ width: window.innerWidth, height: window.innerHeight });
   useEffect(() => {
      const handleResize = () => {
         setCurrentWindow({ width: window.innerWidth, height: window.innerHeight });
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
   });

   return (
      <div>
         {isLoading ? (
            <div style={{ position: "fixed", top: 0, height: currentWindow.height, width: "100%" }}>
               <Center height={"100%"} justifyContent="center">
                  <Loading />
               </Center>
            </div>
         ) : (
            <div
               className="flex-container"
               style={{
                  margin: 0,
                  padding: 10,
                  background: "#d3d3d3",
                  height: "100%",
                  minHeight: currentWindow.height,
                  touchAction: "pan-down",
                  overscrollBehaviorY: "contain",
               }}
            >
               <Flex justify="center">
                  <Icon
                     type="ellipsis"
                     onClick={() =>
                        history.push({
                           pathname: `/dms/${projectId}`,
                           state: { ...historyState },
                        })
                     }
                     size={"lg"}
                     style={{
                        position: "fixed",
                        bottom: 15,
                        left: 15,
                        zIndex: 10,
                        padding: 3,
                        background: colorType.primaryMobile,
                        borderRadius: 5,
                     }}
                  />

                  <Icon
                     type="search"
                     onClick={() => setDrawOpen(!drawOpen)}
                     size={"lg"}
                     style={{
                        position: "fixed",
                        bottom: 15,
                        left: 60,
                        zIndex: 10,
                        padding: 3,
                        background: colorType.primaryMobile,
                        borderRadius: 5,
                     }}
                  />

                  <div
                     style={{
                        position: "fixed",
                        bottom: 15,
                        left: 105,
                        zIndex: 10,
                        padding: 5,
                        background: colorType.primaryMobile,
                        borderRadius: 5,
                        fontWeight: "bold",
                        fontSize: 17,
                     }}
                  >
                     {refType.toUpperCase()}
                  </div>

                  <Drawer
                     placement="bottom"
                     closable={false}
                     onClose={() => setDrawOpen(false)}
                     visible={drawOpen}
                     height={currentWindow.height * 0.6}
                     bodyStyle={{ padding: 0 }}
                     destroyOnClose={true}
                  >
                     <SearchPanel stateRow={data} processFilter={processFilter} refType={refType} />
                  </Drawer>

                  {dataTableInput && dataTableInput.length > 0 && (
                     <FlatList
                        data={dataTableInput}
                        renderItem={({ item }) => {
                           return (
                              <PanelFormTypeOne
                                 cardData={item}
                                 history={history}
                                 stateRow={data}
                                 pageSheetTypeName={`page-${refType}`}
                              />
                           );
                        }}
                        keyExtractor={(item, i) => i.toString()}
                     />
                  )}
               </Flex>
            </div>
         )}
      </div>
   );
};

export default DmsPageAllForm;

const SearchPanel = (props: any) => {
   const { stateRow, processFilter, refType } = props;

   const { modeFilter, disciplineForm } = stateRow;

   const [refDisciplineArray, setRefDisciplineArray] = useState<any>([]);

   const [disArray, setDisArray] = useState<any>(modeFilter["disArray"]);

   const [statusArray, setStatusArray] = useState<any>(modeFilter["statusArray"]);

   const [overdueArray, setOverdueArray] = useState<any>(modeFilter["overdueArray"]);

   const [searchText, setSearchText] = useState<string>(modeFilter["searchText"]);

   const [dateFilter, setDateFilter] = useState<any>(modeFilter["dateFilter"]);

   useEffect(() => {
      if (refType === "rfa") {
         let allDiscipline: string[] = [];

         disciplineForm.forEach((ref: string) => {
            allDiscipline = [...allDiscipline, ...getAllTreeNodeAbove(ref)];
         });

         allDiscipline = [...new Set(allDiscipline)].sort();
         allDiscipline.sort((a, b) => {
            const partsCountA = a.split("/");
            const partsCountB = b.split("/");

            return (partsCountA.length > partsCountB.length ? 1 : -1) && (a > b ? 1 : -1);
         });

         setRefDisciplineArray(allDiscipline);
      } else {
         let allDiscipline: string[] = [];

         disciplineForm.forEach((ref: string) => {
            allDiscipline = [...allDiscipline, ...getAllTreeNodeAbove(ref)];
         });

         allDiscipline = [...new Set(allDiscipline)].sort();
         allDiscipline.sort((a, b) => {
            const partsCountA = a.split("/");
            const partsCountB = b.split("/");

            return (partsCountA.length > partsCountB.length ? 1 : -1) && (a > b ? 1 : -1);
         });

         setRefDisciplineArray(allDiscipline);
      }
   }, []);

   return (
      <>
         <div
            style={{
               position: "absolute",
               top: 0,
               height: 40,
               background: "white",
               zIndex: 10,
               padding: 7,
               width: "100%",
               display: "flex",
               justifyContent: "space-between",
            }}
         >
            <Button
               style={{
                  marginRight: 5,
                  textAlign: "center",
                  backgroundColor: colorType.primaryMobile,
                  color: "black",
                  fontWeight: "bold",
                  width: "79%",
               }}
               onClick={() => processFilter({ statusArray, disArray, overdueArray, searchText, dateFilter })}
            >
               SEARCH
            </Button>

            <Button
               style={{
                  marginRight: 5,
                  textAlign: "center",
                  backgroundColor: colorType.primaryMobile,
                  color: "black",
                  fontWeight: "bold",
                  width: "18%",
               }}
               onClick={() =>
                  processFilter({
                     statusArray: [],
                     disArray: [],
                     overdueArray: [],
                     searchText: "",
                     dateFilter: null,
                  })
               }
            >
               CLEAR
            </Button>
         </div>

         <div style={{ padding: 7, paddingBottom: 10, marginTop: 55, borderBottom: `1px solid ${colorType.grey4}` }}>
            <div style={{ fontWeight: "bold", fontSize: 16 }}>Text</div>
            <Input
               className="dms-input"
               style={{ padding: 5 }}
               placeholder="Search"
               value={searchText}
               onChange={(e: any) => setSearchText(e.target.value)}
            />
         </div>

         {refDisciplineArray.length > 0 && (
            <PickItemArea
               title={"Discipline"}
               itemArrayInit={refDisciplineArray}
               itemArray={disArray}
               setItemArray={setDisArray}
            />
         )}

         <PickItemArea
            title={"Status"}
            itemArrayInit={[
               "Approved for Construction",
               "Approved with Comment, no submission Required",
               "Approved with comments, to Resubmit",
               "Reject and resubmit",
               "Consultant reviewing",
            ]}
            itemArray={statusArray}
            setItemArray={setStatusArray}
         />

         <PickItemArea
            title={"Due"}
            itemArrayInit={["Overdue", "Due in the next 1 - 3 days", "Due in the next 4 - 14 days", "Replied"]}
            itemArray={overdueArray}
            setItemArray={setOverdueArray}
         />

         <div style={{ padding: 5 }}>
            <div style={{ display: "flex" }}>
               <div style={{ fontWeight: "bold", fontSize: 16, marginRight: 15 }}>Pick Date</div>

               {dateFilter && (
                  <div
                     style={{
                        background: colorType.primaryMobile,
                        paddingRight: 5,
                        paddingLeft: 5,
                        fontWeight: "bold",
                        display: "flex",
                     }}
                  >
                     <div style={{ paddingTop: 2 }}>{moment(dateFilter).format("DD/MM/YY")}</div>
                     <Icon
                        style={{ paddingTop: 5, marginLeft: 5 }}
                        type="cross-circle"
                        size={"sm"}
                        onClick={() => setDateFilter(null)}
                     />
                  </div>
               )}
            </div>
            <DatePickerComp pickDateFilter={(date: any) => setDateFilter(date)} />
         </div>
      </>
   );
};

const PickItemArea = (props: any) => {
   const { itemArray, setItemArray, itemArrayInit, title } = props;

   return (
      <div style={{ width: "100%", borderBottom: `1px solid ${colorType.grey4}`, padding: 7, marginBottom: 10 }}>
         <div style={{ fontWeight: "bold", fontSize: 16, marginBottom: 3 }}>{title}</div>

         <div style={{}}>
            {itemArrayInit.map((btn: string, i: number) => {
               const borderColor =
                  title === "Discipline" && btn.split("/").length === 1
                     ? `1px solid ${colorType.primaryMobile}`
                     : "none";

               return (
                  <Button
                     key={i}
                     style={{
                        display: "inline-block",
                        marginRight: 5,
                        textAlign: "center",
                        backgroundColor: itemArray.indexOf(btn) !== -1 ? colorType.primaryMobile : colorType.grey4,
                        border: borderColor,
                        color: "black",
                     }}
                     size="small"
                     onClick={() => {
                        if (title === "Discipline") {
                           if (itemArray.indexOf(btn) !== -1) {
                              setItemArray(itemArray.filter((x: string) => x !== btn));
                           } else {
                              const btnPartsCount = btn.split("/").length;

                              const btnFiltered = itemArray.filter((x: string) => {
                                 const xPartsCount = x.split("/").length;
                                 return !((x.includes(btn) || btn.includes(x)) && xPartsCount !== btnPartsCount);
                              });

                              setItemArray([...btnFiltered, btn]);
                           }
                        } else {
                           if (itemArray.indexOf(btn) !== -1) {
                              setItemArray(itemArray.filter((x: string) => x !== btn));
                           } else {
                              setItemArray([...itemArray, btn]);
                           }
                        }
                     }}
                  >
                     {btn}
                  </Button>
               );
            })}
         </div>
      </div>
   );
};
