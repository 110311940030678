import { Button } from "antd-mobile";
import { Center } from "native-base";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../../scss/module_DMS/style.scss";
import Loading from "../../module_DMS/components/Loading";
import { getTabArrayNameForPage } from "../../module_DMS/utils";
import { Icon } from "antd-mobile";
import { colorType } from "../../module_DMS/constants";

const DmsPageAllDiscipline = (props: any) => {
   const { history } = props;

   const {
      location: { pathname, state: historyState },
   } = history;

   const { projectId, disciplineForm } = historyState;

   const parts = pathname.split("/");

   const formType = parts[parts.length - 1];
   const arrayPage = getTabArrayNameForPage(disciplineForm, formType);

   return (
      <div style={{ position: "fixed", height: "100%", width: "100%" }}>
         <Icon
            type="ellipsis"
            onClick={() =>
               history.push({
                  pathname: `/dms/${projectId}`,
                  state: { ...historyState },
               })
            }
            size={"lg"}
            style={{
               position: "fixed",
               bottom: 15,
               left: 15,
               zIndex: 10,
               padding: 3,
               background: colorType.primaryMobile,
               borderRadius: 5,
            }}
         />

         <Center height={"100%"} justifyContent="center">
            {arrayPage.map((btn: string) => (
               <Button
                  key={btn}
                  onClick={() => {
                     history.push({
                        pathname: `/dms/${formType}/${btn}`,
                        state: {
                           ...historyState,
                        },
                     });
                  }}
                  style={{
                     width: "70%",
                     marginBottom: 10,
                     background: "rgb(234, 179, 8)",
                     color: "black",
                     fontWeight: "bold",
                  }}
               >
                  {btn.toUpperCase()}
               </Button>
            ))}
         </Center>
      </div>
   );
};

export default DmsPageAllDiscipline;
