


export const colorType = {
   yellow: '#fff200',
   dark: '#1e272e',
   grey0: '#ecf0f1',
   grey1: '#bdc3c7',
   grey2: '#636e72',
   grey3: '#dfe4ea',
   grey4: '#f1f2f6',
   red: '#b33939',
   green: '#009432',
   blue: '#0984e3',
   orange: '#cc8e35',
   purple: '#303952',
   tableBorderColor: '#A9A9A9',
   cellHighlighted: '#B0C4DE',
   primary: '#34495e',
   secondary: '#46607a',
   lockedCell: '#FDFFE6',
   greenDark: '#006400',
   orange2: '#ff6600',
   rose: '#e84118',
   black: 'black',
   pp: '#9b59b6',
   pp2: '#bf95d0',

   grey9: '#b3cccc',

   primaryMobile: 'rgb(234, 179, 8)'
};


export const statusOrderArray = [
   'Approved for Construction',
   'Approved with Comment, no submission Required',
   'Approved with comments, to Resubmit',
   'Reject and resubmit',
   'Consultant reviewing',
   'Submitted'
];

export const colorTextCode = {
   yellow: '#fff200',
   dark: '#1e272e',
   grey0: '#ecf0f1',
   grey1: '#bdc3c7',
   grey2: '#636e72',
   grey3: '#dfe4ea',
   grey4: '#f1f2f6',
   red: '#b33939',
   green: '#90EE90',
   greenDark: '#006400',
   blue: '#0984e3',
   orange: '#cc8e35',
   orange2: '#ff6600',
   purple: '#303952',
   rose: '#e84118',
   black: 'black',
   pp: '#9b59b6',
   pp2: '#bf95d0',
   grey9: '#b3cccc',
};
export const colorTextRow: any = {
   'Overdue': colorTextCode.red,
   'Due in the next 1 - 3 days': '#cc9900',
   'Due in the next 4 - 14 days': colorTextCode.grey4,

   'Approved with Comment, no submission Required': colorTextCode.green,
   'Approved for Construction': colorTextCode.greenDark,
   'Approved with comments, to Resubmit': colorTextCode.orange2,
   'Reject and resubmit': colorTextCode.red,
   'Revise In-Progress': colorTextCode.yellow,

   'Consultant reviewing': colorTextCode.blue,

   'Not Started': colorTextCode.orange,

   '1st cut of drawing in-progress': colorTextCode.pp2,
   '1st cut of model in-progress': colorTextCode.pp,
   'Pending design': colorTextCode.grey9,

   'replied': colorTextCode.green,
   'Replied': colorTextCode.green,
   'Total': colorTextCode.green,

   'Information / Action': colorTextCode.greenDark,
   'Comments / Approval': colorType.pp2,
   'Construction': colorType.pp,
   'Record': colorTextCode.green,

   'ARCHI': colorTextCode.green,
   'C&S': colorTextCode.greenDark,
   'M&E': colorTextCode.orange,
   'PRECAST': colorTextCode.grey4,



   'Project Progress Meeting': colorType.pp2,
   'Technical Meeting': colorType.pp,
   'ICE Meeting': colorTextCode.green,
   'PROJECT PROGRESS MEETING': colorType.pp2,
   'TECHNICAL MEETING': colorType.pp,
   'ICE MEETING': colorTextCode.green
};