import { useEffect, useState } from 'react';
import {
  VStack,
  Image,
  Text,
  Center,
  Box,
  Stack,
  Heading,
  Pressable,
  HStack,
  Spinner,
} from 'native-base';
import axios from 'axios';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectUser, login } from '../../features/app/userSlice';
import _ from 'lodash';
import { getPublicUrl } from '../module_BimApp/AdminFunction';
import { sortProjectName } from '../module_BimApp/SortFunction';
import { format } from 'date-fns';
import { setProject } from '../../features/app/projectSlice';

function CardProject({ item, ...props }: any) {
  const dispatch = useAppDispatch();
  const [imgProject, setImgProject] = useState('');
  useEffect(() => {
    if (item.image !== '') {
      getPublicUrl(item.image, 300 * 1000)
        .then((res: any) => {
          setImgProject(res);
        })
        .catch((err) => {
          setImgProject('');
        });
    }
  }, []);
  const handleProject = () => {
    props.history.push({
      pathname: `project/${item.project.projectId}`,
      state: {
        userCreatedProject: item.userCreated,
        projectId: item.project.projectId,
        projectName: item.project.projectName,
        sharePointId: item.sharePointId,
        issueKey: item.issueKey,
        typeProject: item.typeProject,
        trimbleKey: item.trimbleKey,
        useRfa: item.useRfa,
        isDetail: true,
      },
    });
  };
  return (
    <Pressable cursor='pointer' onPress={handleProject} alignItems='center'>
      <Box bg='white' shadow={2} rounded='lg' maxWidth='90%' width={350}  >
        <Image
          source={{
            uri:
              imgProject !== ''
                ? imgProject
                : 'https://rawcdn.githack.com/Woh-Hup/Resource/81c2b83561ed9d81a5d267265e0432d44cbd9e8f/image/WH_Logo_Text.png',
          }}
          alt='project image'
          resizeMode='contain'
          height={150}
          roundedTop='md'
        />
        <Stack space={2} p={[4, 4, 4]}>
          {/* <Text color='gray.400'>
            {format(new Date(item.uploadDate), 'dd-MM-yyyy')}
          </Text> */}
          <Heading size={'lg'} noOfLines={10}>
            {item.projectDisplayName
              ? item.projectDisplayName
              : item.project.projectName}
          </Heading>
          {/* <Text lineHeight={[5, 5, 7]} noOfLines={4} color='gray.700'>
            {`User created: ${item.userCreated}`}
          </Text> */}
        </Stack>
      </Box>
    </Pressable>
  );
}

export default function ProjectsPage(props: any) {
  const [projects, setProjects] = useState([]);
  const user = useAppSelector<any>(selectUser);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/projects`, {
        params: {
          token: user.token,
          email: user.email,
        },
      })
      .then((res: any) => {
        if (res.data.user !== null) login(res.data.user);
        _.forEach(res.data.project, (v) => {
          v.uploadDate = v.uploadDate;
          v.createdDate = v.createdDate;
        });
        res.data.project.sort(sortProjectName);
        setProjects(res.data.project);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
      });
  }, []);
  return (
    <>
      {loading ? (
        <div style={{ position: 'fixed', height: '100%', width: '100%' }}>
          <Center height={'100%'} justifyContent='center'>
            <HStack space={2}>
              <Heading color='yellow.600'>Idd App</Heading>
              <Spinner color='yellow.600' accessibilityLabel='Loading posts' />
            </HStack>
          </Center>
        </div>
      ) : (
        <Center>
          <VStack space={5} alignItems='center' marginTop={5}>
            {projects.map((i: any) => (
              <CardProject item={i} {...props} />
            ))}
          </VStack>
        </Center>
      )}
    </>
  );
}
