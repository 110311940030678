import React, { FunctionComponent, useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  Select,
  DatePicker,
  Button,
  Result,
  Empty,
  Skeleton,
  notification,
} from 'antd';

import { useSelector } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectProject, setProject } from '../../../features/app/projectSlice';
import BaseTypeModel from '../models/BaseTypeModel';
import WorkerShifts from './WorkerShifts';
import Calender from './Calender';
import DayNotSubmitBox from './DayNotSubmitBox';

import { getSupervisorByEmail } from '../../module_Worker/api/setWorkingTimeApi';

import {
  getMyInfomation,
  searchAllSupervisor,
  searchLevelZone,
  getWorkersByDateAndSupervisor,
  setSummits,
  searchAllCostCode,
  updateIsDayOff,
  updateIsWorkOvernight,
  updateIsWorkOverLunch,
  updateIsWorkOverTeaTime,
  updateShiftsToWorker,
  updateRemark,
  searchBlockUnit,
} from '../api/setWorkingTimeApi';
const { Option } = Select;

const format = 'HH:mm';
const dateFormat = 'YYYY-MM-DD';

const WorkingTime: FunctionComponent<any> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reloadDaysNotSubmit, setReloadDaysNotSubmit] = useState<number>(0);
  const [role, setRole] = useState<any>('');
  const [selectedDay, setSelectedDay] = useState<any>(moment());
  const [selectedSupervisorId, setSelectedSupervisorId] = useState<any>(null);
  const [isShowSetting, setIsShowSetting] = useState(false);
  const [save, setSave] = useState(0);
  const [date, setDate] = useState(moment().format(dateFormat));
  const [supervisors, setSupervisors] = useState(new Array<BaseTypeModel>());
  const [levels, setLevels] = useState(new Array<BaseTypeModel>());
  const [blocks, setBlocks] = useState(new Array<BaseTypeModel>());
  const [units, setUnits] = useState(new Array<any>());
  const [zones, setZones] = useState(new Array<BaseTypeModel>());
  const [allCostCodes, setAllCostCodes] = useState(new Array<any>());
  const [workerDatas, setWorkerDatas] = useState(new Array<any>());
  const [workerDataFilters, setDataFilter] = useState(new Array<any>());
  const projectId = useSelector((state: any) => state.project.value.projectId);
  const projectName = useSelector(
    (state: any) => state.project.value.projectName
  );

  const [supervisor, setSupervisor] = useState<any>();

  const dispatch = useAppDispatch();
  const project = useAppSelector<any>(selectProject);
  const email = useSelector((state: any) => state.user?.value?.email);

  useEffect(() => {
    getLevelZones();
    getBlockUnits();
    getSupervisors();
    getCostCodes();
  }, []);

  useEffect(() => {
    getMyInfomation().then((e) => {
      if (e && e.user && e.user.contractors) {
        let item = e.user.contractors.find(
          (x: any) => x.projectId === projectId
        );

        if (item) {
          setRole(item.role);
        } else {
          setRole('');
        }
      }
    });
  }, [projectId]);

  //need change here to get idsupervisor.
  useEffect(() => {
    //Quyet add
    async function getSupervisor() {
      if (project.projectId) {
        try {
          let res = await getSupervisorByEmail({
            projectId: project.projectId,
            email,
          });
          setSupervisor(res.data);
          if (res.data !== undefined) {
            if (res.data.id !== undefined) {
              setSelectedSupervisorId(res.data.id);
            }
          }
        } catch (error) {
          return null;
        }
      }
    }
    getSupervisor();
  }, [projectId]);

  // useEffect(() => {
  //    setSelectedSupervisorId(props.idSupervisor);
  // }, [props.idSupervisor]);

  useEffect(() => {
    if (selectedSupervisorId) {
      getWorkerDatas();
    }
  }, [date, selectedSupervisorId]);

  function handleOnChangeDate(date: any, dateString: any) {
    setDate(dateString);
  }

  //
  async function getCostCodes() {
    let payload = {
      filterBy: '',
      pageIndex: 1,
      pageSize: 1000,
      idWorker: props.idWorker,
      projectId: projectId,
    };

    let res = await searchAllCostCode(payload);
    let datas = res.data.items;
    setAllCostCodes(datas);
  }

  async function getSupervisors(isLevel: boolean = true) {
    let payload = {
      filterBy: '',
      pageIndex: 1,
      pageSize: 1000,
      type: isLevel ? 1 : 2,
      projectId,
    };

    let res = await searchAllSupervisor(payload);
    let lvs = res.data.items.map((x: any) => ({
      id: x.id,
      name: x.name,
    })) as BaseTypeModel[];

    setSupervisors(lvs);
  }

  async function getLevelZones() {
    let payload = {
      filterBy: '',
      pageIndex: 1,
      pageSize: 1000,
      projectId,
    };

    let res = await searchLevelZone(payload);

    let listLevels = res.data.items.filter((x: any) => x.type == 1);

    listLevels.forEach((x: any) => {
      if (!x.isMaster && x.masterId) {
        let master = listLevels.find((m: any) => m.id == x.masterId);
        if (master) {
          x.keyPlan = master.keyPlan;
        }
      }
    });

    let lvs = listLevels
      .filter((x: any) => x.type == 1)
      .map((x: any) => ({
        id: x.id,
        name: x.name,
        add: x.keyPlan,
        more: res.data.items.filter(
          (z: any) => z.type == 2 && z.parentId == x.id
        ),
      })) as BaseTypeModel[];

    setLevels(lvs);
  }

  async function getBlockUnits() {
    let payload = {
      filterBy: '',
      pageIndex: 1,
      pageSize: 1000,
      projectId,
    };

    let res = await searchBlockUnit(payload);

    setUnits(res.data.items);

    let blocks = res.data.items.map((x: any) => x.blockName);
    blocks = _.uniq(blocks);
    blocks.sort();
    blocks = blocks.map((block: any, index: any) => ({
      name: block,
      id: index,
      more: res.data.items.filter((item: any) => item.blockName === block),
    })) as BaseTypeModel[];

    // console.log("blocks", blocks);
    setBlocks(blocks);
  }

  async function getWorkerDatas() {
    try {
      let res = await getWorkersByDateAndSupervisor(selectedSupervisorId, {
        date: date,
        projectId,
        projectName,
      });

      let dts = res.data.filter((x: any) => x.delete != true);
      try {
        dts = _.orderBy(dts, 'name');
      } catch (error) {}

      setWorkerDatas(dts);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  function updateShifts(id: any, newShifts: any): void {
    let newData = _.clone(workerDatas);
    let item = newData.find((x) => x.id === id);
    if (item) {
      item.shifts = newShifts;
    }

    //check worker shifts conflict
    for (let workerData of newData) {
      if (workerData.shifts.workingShifts?.length > 1) {
        //check time intersect
        var ss = workerData.shifts.workingShifts.filter(
          (x: any) => x.action != 3
        );
        if (isTimeIntersectInShifts(ss, true)) {
          // ss.forEach((x: any) => (x.isConflict = true));
        } else {
          // ss.forEach((x: any) => (x.isConflict = false));
        }
      }
    }

    setWorkerDatas(newData);
  }

  async function onSave(isCallRefesh = true) {
    // check time intersection
    let shifts = workerDatas.map((x) => x.shifts).flat();

    //check activitiy
    if (
      workerDatas
        .map((x) => x.shifts.workingShifts)
        .flat()
        .filter((x) => x.action != 3)
        .some((x) => !_.isNumber(x.idTask))
    ) {
      notification['error']({
        message: 'Activity',
        description:
          'Activity is required . Please choose activity for all worker!',
      });

      return false;
    }

    console.log('workerDatas', workerDatas);
    //check qm
    workerDatas.forEach((workerData) => {
      if (
        workerData.trade == 'QM' &&
        workerData.shifts.workingShifts
          .filter((x: any) => x.action != 3)
          .some((x: any) => !_.isNumber(x.idBlockUnit))
      ) {
        //block , unit require
        notification['error']({
          message: 'Activity',
          description: `Block, unit are required . Please choose block, unit for all workers' trade are QM!`,
        });
      }
    });

    if (
      workerDatas
        .map((x) => x.shifts.workingShifts)
        .flat()
        .filter((x) => x.action != 3)
        .some((x) => !_.isNumber(x.idTask))
    ) {
      notification['error']({
        message: 'Activity',
        description:
          'Activity is required . Please choose activity for all worker!',
      });

      return false;
    }

    // check time intersection
    for (let workerData of shifts) {
      if (workerData.isDayOff != true) {
        if (workerData.workingShifts.length > 1) {
          //check time intersect
          var ss = workerData.workingShifts.filter((x: any) => x.action != 3);
          if (isTimeIntersectInShifts(ss)) {
            notification['error']({
              message: 'Activity',
              description:
                workerData.name +
                "'s " +
                'Time shifts are conflict each other!',
            });

            return false;
          }
        }

        let shifts = workerData.workingShifts.filter((x: any) => x.action < 3);

        //check start must be before end
        for (const shift of shifts) {
          console.log('shift', shift);
          let sTime = moment(shift.start, 'HH:mm');
          let startMoment = moment().set({
            hour: sTime.get('hour'),
            minute: sTime.get('minute'),
          });

          if (shift.startDate == 'tomorrow') {
            startMoment = startMoment.add(1, 'days');
          }

          let eTime = moment(shift.end, 'HH:mm');
          let endMoment = moment().set({
            hour: eTime.get('hour'),
            minute: eTime.get('minute'),
          });

          if (shift.endDate == 'tomorrow') {
            endMoment = endMoment.add(1, 'days');
          }

          if (startMoment.isAfter(endMoment)) {
            //show message
            notification['error']({
              message: 'Start Time',
              description:
                workerData.name + "'s " + 'Start time must be before end time',
            });

            return;
          }
        }

        //check first shift must be started in today

        if (shifts.length > 0 && workerData.isDayOff != true) {
          let first = shifts[0];
          if (first.startDate == 'tomorrow') {
            notification['error']({
              message: 'Start Time',
              description:
                workerData.name + "'s " + 'Start time must be started in today',
            });

            return false;
          }
        }

        break;
      }
    }

    let workingShifts = workerDatas
      .map((x) => x.shifts.workingShifts)
      .flat()
      .filter((x) => x.action > 0);

    if (workingShifts.length > 0) {
      await updateShiftsToWorker(workingShifts);
    }

    let arrayShifts = workerDatas.map((x) => x.shifts);

    let arrayShiftsUpdateOverLunch = arrayShifts.filter(
      (x) => x.isEditWorkOverLunch
    );

    let arrayShiftsUpdateTeaTime = arrayShifts.filter(
      (x) => x.isEditWorkTeaTime
    );

    let arrayShiftsUpdateDayOff = arrayShifts.filter((x) => x.isEditWorkDayOff);
    let arrayShiftsUpdateWorkOvernight = arrayShifts.filter(
      (x) => x.isEditWorkOvernight
    );

    let arrayShiftsUpdateRemark = arrayShifts.filter((x) => x.isEditRemark);

    if (arrayShiftsUpdateOverLunch.length > 0) {
      for (let item of arrayShiftsUpdateOverLunch) {
        await updateIsWorkOverLunch({
          id: item.id,
          isWorkOverLunch: item.isWorkOverLunch,
        });
      }
    }

    if (arrayShiftsUpdateTeaTime.length > 0) {
      for (let item of arrayShiftsUpdateTeaTime) {
        await updateIsWorkOverTeaTime({
          id: item.id,
          isWorkOverTeaTime: item.isWorkOverTeaTime,
        });
      }
    }

    if (arrayShiftsUpdateDayOff.length > 0) {
      for (let item of arrayShiftsUpdateDayOff) {
        await updateIsDayOff({
          id: item.id,
          isDayOff: item.isDayOff,
        });
      }
    }

    if (arrayShiftsUpdateWorkOvernight.length > 0) {
      for (let item of arrayShiftsUpdateWorkOvernight) {
        await updateIsWorkOvernight({
          id: item.id,
          isWorkOvernight: item.isWorkOvernight,
        });
      }
    }

    if (arrayShiftsUpdateRemark.length > 0) {
      for (let item of arrayShiftsUpdateRemark) {
        await updateRemark({
          id: item.id,
          remark: item.remark,
        });
      }
    }

    if (isCallRefesh) {
      await getWorkerDatas();
    }

    return true;
  }

  function isTimeIntersectInShifts(lists: any[], isSetConflict = false) {
    let flag = false;
    let ar: any[] = [];
    lists.forEach((x) => (x.isConflict = false));
    for (let item of lists) {
      let momentStart = moment(item.start, formatTime);
      let momentEnd = moment(item.end, formatTime);

      if (momentEnd.isBefore(momentStart)) {
        ar.push({ ...item, start: item.start, end: '24:00:00' });
        ar.push({ ...item, start: '0:00:00', end: item.end });
      } else {
        ar.push({ ...item });
      }
    }

    for (let index = 0; index < ar.length - 1; index++) {
      const item1 = ar[index];

      for (let index2 = index + 1; index2 < ar.length; index2++) {
        const item2 = ar[index2];

        let s = intersectTimeRange(
          item1.start,
          item1.end,
          item2.start,
          item2.end
        );

        if (s > 0.1) {
          flag = true;
          if (isSetConflict) {
            lists.find((x) => x.id == item1.id).isConflict = true;
            lists.find((x) => x.id == item2.id).isConflict = true;
          } else {
            return true;
          }
        }
      }
    }

    return flag;
  }

  const formatTime = 'HH:mm:ss';

  function intersectTimeRange(start1: any, end1: any, start2: any, end2: any) {
    let mStart1 = moment(start1, formatTime);
    let mEnd1 = moment(end1, formatTime);
    let mStart2 = moment(start2, formatTime);
    let mEnd2 = moment(end2, formatTime);

    if (mStart1.isAfter(mStart2)) {
      let tempStart1 = mStart1;
      let tempEnd1 = mEnd1;
      mStart1 = mStart2;
      mEnd1 = mEnd2;

      mStart2 = tempStart1;
      mEnd2 = tempEnd1;
    }

    let delta = null;

    if (mEnd1.isBefore(mStart2)) {
    } else if (mEnd1.isSameOrAfter(mEnd2)) {
      delta = mEnd2.subtract(
        moment.duration(mStart2.format(formatTime), 'hour')
      );
    } else if (mEnd1.isBefore(mEnd2)) {
      delta = mEnd1.subtract(
        moment.duration(mStart2.format(formatTime), 'hour')
      );
    }

    if (delta) {
      let h = moment.duration(delta.format(formatTime)).asHours();

      return h;
    }

    return 0;
  }

  function handleSelectWorkerChange(e: any) {
    if (e > 0) {
      setDataFilter(workerDatas.filter((x) => x.id == e));
    }

    setDataFilter(workerDatas);
  }

  async function handleSummit() {
    if (await onSave()) {
      if (workerDatas) {
        try {
          await setSummits(workerDatas.map((x) => x.id));
          await getWorkerDatas();
          setReloadDaysNotSubmit(reloadDaysNotSubmit + 1);
          notification['success']({
            message: 'Submit',
            description: 'Submitted Successfully !',
          });
        } catch (error) {
          notification['error']({
            message: 'Submit',
            description: 'Submit failed !',
          });
        }
      }
    }
  }

  function handleCalendarChangeDate(e: any) {
    setDate(e.format(dateFormat));
  }

  function isCanSubmit() {
    return true;
    return workerDatas.some((x) => x.isDisabled != true);
  }

  return (
    <div className='class-content'>
      <div className='worker-time'>
        <Skeleton loading={isLoading}>
          {/* <LocaleProvider locale={enUS}> */}
          {selectedSupervisorId && (
            <DayNotSubmitBox
              onChange={handleCalendarChangeDate}
              idSupervisor={selectedSupervisorId}
              isShowSetting={isShowSetting}
              setIsShowSetting={setIsShowSetting}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
              reloadDaysNotSubmit={reloadDaysNotSubmit}
            />
          )}

          {isShowSetting && (
            <div>
              <div className=' grid grid-cols-1 mb-4 gap-4'>
                <div className='flex flex-row'>
                  <label style={{ width: 150 }}>Supervisor :</label>
                  <Select
                    value={selectedSupervisorId}
                    style={{ width: '100%' }}
                    onChange={(e: any) => {
                      setSelectedSupervisorId(e);
                    }}
                  >
                    {supervisors.map((supervisor) => (
                      <Option value={supervisor.id}>{supervisor.name}</Option>
                    ))}
                  </Select>
                </div>
                <Calender
                  onChange={handleCalendarChangeDate}
                  idSupervisor={selectedSupervisorId}
                  selectedDay={selectedDay}
                  setSelectedDay={setSelectedDay}
                />
              </div>
            </div>
          )}

          <Skeleton loading={isLoading} active>
            {workerDatas.length > 0 ? (
              <div>
                {workerDatas.map((x) => (
                  <div key={x.id} className='mb-8'>
                    <WorkerShifts
                      startHubble={x.startHubble}
                      endHubble={x.endHubble}
                      id={x.id}
                      trade={x.trade}
                      doSave={save}
                      idAllocation={x.id}
                      idWorker={x.idWorker}
                      isDisabled={x.isDisabled}
                      date={new Date(date)}
                      levels={levels}
                      blocks={blocks}
                      supervisors={supervisors}
                      allCostCodes={allCostCodes}
                      shifts={x.shifts}
                      updateShifts={updateShifts}
                    />
                  </div>
                ))}

                <div className=' grid grid-cols-2 gap-4'>
                  <Button
                    disabled={!isCanSubmit()}
                    type='primary'
                    className='h50px'
                    size='large'
                    onClick={() => onSave()}
                  >
                    Save
                  </Button>

                  <Button
                    className='h50px'
                    disabled={!isCanSubmit()}
                    type='primary'
                    size='large'
                    onClick={handleSummit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <Empty />
              </div>
            )}
          </Skeleton>
          {/* </LocaleProvider> */}
        </Skeleton>
      </div>
    </div>
  );
};

export default WorkingTime;
