import { Button, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { notification } from 'antd';
import moment from 'moment';

import WorkerShifts from '../WorkingTime/WorkerShifts';
import BaseTypeModel from '../models/BaseTypeModel';
import { useSelector } from 'react-redux';
import { worker_ts_store } from '../../../store/workerStore';
import { worker_refresh_store } from '../../../store/workerStore';
import '../../../scss/module_Worker/monthlyReport.scss';

import {
  getMyInfomation,
  searchAllSupervisor,
  searchLevelZone,
  getWorkersByDateAndSupervisor,
  setSummits,
  searchAllCostCode,
  updateIsDayOff,
  updateIsWorkOvernight,
  updateIsWorkOverLunch,
  updateIsWorkOverTeaTime,
  updateShiftsToWorker,
  updateRemark,
  searchBlockUnit,
} from '../api/setWorkingTimeApi';
import da from 'date-fns/esm/locale/da/index.js';

type Props = {
  isModalVisible: boolean;
  daily_Info: any;
  setIsModalVisible: (val: boolean) => void;
};

const EditWorkerTimeSheet: React.FC<Props> = ({
  isModalVisible,
  daily_Info,
  setIsModalVisible,
}) => {
  const [save, setSave] = useState(0);
  const [levels, setLevels] = useState(new Array<BaseTypeModel>());
  const [blocks, setBlocks] = useState(new Array<BaseTypeModel>());
  const [units, setUnits] = useState(new Array<any>());
  const [allCostCodes, setAllCostCodes] = useState(new Array<any>());
  const [supervisors, setSupervisors] = useState(new Array<BaseTypeModel>());

  const { update_Shift } = worker_ts_store();
  const { refresh, toggle_refresh } = worker_refresh_store();
  const projectId = useSelector((state: any) => state.project.value.projectId);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getLevelZones();
    getBlockUnits();
    getSupervisors();
    getCostCodes();
  }, []);

  async function getLevelZones() {
    let payload = {
      filterBy: '',
      pageIndex: 1,
      pageSize: 1000,
      projectId,
    };

    let res = await searchLevelZone(payload);

    let listLevels = res.data.items.filter((x: any) => x.type == 1);

    listLevels.forEach((x: any) => {
      if (!x.isMaster && x.masterId) {
        let master = listLevels.find((m: any) => m.id == x.masterId);
        if (master) {
          x.keyPlan = master.keyPlan;
        }
      }
    });

    let lvs = listLevels
      .filter((x: any) => x.type == 1)
      .map((x: any) => ({
        id: x.id,
        name: x.name,
        add: x.keyPlan,
        more: res.data.items.filter(
          (z: any) => z.type == 2 && z.parentId == x.id
        ),
      })) as BaseTypeModel[];

    setLevels(lvs);
  }

  async function getBlockUnits() {
    let payload = {
      filterBy: '',
      pageIndex: 1,
      pageSize: 1000,
      projectId,
    };

    let res = await searchBlockUnit(payload);

    setUnits(res.data.items);

    let blocks = res.data.items.map((x: any) => x.blockName);
    blocks = _.uniq(blocks);
    blocks.sort();
    blocks = blocks.map((block: any, index: any) => ({
      name: block,
      id: index,
      more: res.data.items.filter((item: any) => item.blockName === block),
    })) as BaseTypeModel[];

    // console.log("blocks", blocks);
    setBlocks(blocks);
  }

  async function getSupervisors(isLevel: boolean = true) {
    let payload = {
      filterBy: '',
      pageIndex: 1,
      pageSize: 1000,
      type: isLevel ? 1 : 2,
      projectId,
    };

    let res = await searchAllSupervisor(payload);
    let lvs = res.data.items.map((x: any) => ({
      id: x.id,
      name: x.name,
    })) as BaseTypeModel[];

    setSupervisors(lvs);
  }

  async function getCostCodes() {
    let payload = {
      filterBy: '',
      pageIndex: 1,
      pageSize: 1000,
      idWorker: daily_Info.idWorker,
      projectId: projectId,
    };

    let res = await searchAllCostCode(payload);
    let datas = res.data.items;
    setAllCostCodes(datas);
  }

  function updateShifts(id: any, newShifts: any): void {
    console.log('To run function');
    update_Shift(id, newShifts);
  }

  async function onSaveByWorkerByDay(isCallRefesh = true) {
    // check time intersection
    // let shifts = workerDatas.map((x) => x.shifts).flat();
    let shifts = [daily_Info.shifts].flat();

    //check activitiy
    // if (
    //    workerDatas
    //       .map((x) => x.shifts.workingShifts)
    //       .flat()
    //       .filter((x) => x.action != 3)
    //       .some((x) => !_.isNumber(x.idTask))
    // ) {
    //    notification["error"]({
    //       message: "Activity",
    //       description: "Activity is required . Please choose activity for all worker!",
    //    });

    //    return false;
    // }

    const workingShiftArr = [daily_Info.shifts.workingShifts].flat();
    if (
      workingShiftArr
        .filter((x) => x.action != 3)
        .some((x) => !_.isNumber(x.idTask))
    ) {
      notification['error']({
        message: 'Activity',
        description:
          'Activity is required . Please choose activity for all worker!',
      });

      return false;
    }

    // check time intersection
    for (let workerData of shifts) {
      if (workerData.isDayOff != true) {
        if (workerData.workingShifts.length > 1) {
          //check time intersect
          var ss = workerData.workingShifts.filter((x: any) => x.action != 3);
        }

        let shifts = workerData.workingShifts.filter((x: any) => x.action < 3);

        //check start must be before end
        for (const shift of shifts) {
          console.log('shift', shift);
          let sTime = moment(shift.start, 'HH:mm');
          let startMoment = moment().set({
            hour: sTime.get('hour'),
            minute: sTime.get('minute'),
          });

          if (shift.startDate == 'tomorrow') {
            startMoment = startMoment.add(1, 'days');
          }

          let eTime = moment(shift.end, 'HH:mm');
          let endMoment = moment().set({
            hour: eTime.get('hour'),
            minute: eTime.get('minute'),
          });

          if (shift.endDate == 'tomorrow') {
            endMoment = endMoment.add(1, 'days');
          }

          if (startMoment.isAfter(endMoment)) {
            //show message
            notification['error']({
              message: 'Start Time',
              description:
                workerData.name + "'s " + 'Start time must be before end time',
            });

            return;
          }
        }

        //check first shift must be started in today

        if (shifts.length > 0 && workerData.isDayOff != true) {
          let first = shifts[0];
          if (first.startDate == 'tomorrow') {
            notification['error']({
              message: 'Start Time',
              description:
                workerData.name + "'s " + 'Start time must be started in today',
            });

            return false;
          }
        }

        break;
      }
    }

    let workingShifts = workingShiftArr.filter((x) => x.action > 0);

    if (workingShifts.length > 0) {
      await updateShiftsToWorker(workingShifts);
    }

    // let arrayShifts = workerDatas.map((x) => x.shifts);
    let arrayShifts = [daily_Info.shifts];

    let arrayShiftsUpdateOverLunch = arrayShifts.filter(
      (x) => x.isEditWorkOverLunch
    );

    let arrayShiftsUpdateDayOff = arrayShifts.filter((x) => x.isEditWorkDayOff);
    let arrayShiftsUpdateWorkOvernight = arrayShifts.filter(
      (x) => x.isEditWorkOvernight
    );

    console.log('arrayShifts', arrayShifts);

    let arrayShiftsUpdateWorkTeaTime = arrayShifts.filter(
      (x) => x.isEditWorkTeaTime
    );

    let arrayShiftsUpdateRemark = arrayShifts.filter((x) => x.isEditRemark);

    if (arrayShiftsUpdateOverLunch.length > 0) {
      for (let item of arrayShiftsUpdateOverLunch) {
        await updateIsWorkOverLunch({
          id: item.id,
          isWorkOverLunch: item.isWorkOverLunch,
        });
      }
    }

    if (arrayShiftsUpdateWorkTeaTime.length > 0) {
      for (let item of arrayShiftsUpdateWorkTeaTime) {
        await updateIsWorkOverTeaTime({
          id: item.id,
          isWorkOverTeaTime: item.isWorkOverTeaTime,
        });
      }
    }

    if (arrayShiftsUpdateDayOff.length > 0) {
      for (let item of arrayShiftsUpdateDayOff) {
        await updateIsDayOff({
          id: item.id,
          isDayOff: item.isDayOff,
        });
      }
    }

    if (arrayShiftsUpdateWorkOvernight.length > 0) {
      for (let item of arrayShiftsUpdateWorkOvernight) {
        await updateIsWorkOvernight({
          id: item.id,
          isWorkOvernight: item.isWorkOvernight,
        });
      }
    }

    if (arrayShiftsUpdateRemark.length > 0) {
      for (let item of arrayShiftsUpdateRemark) {
        await updateRemark({
          id: item.id,
          remark: item.remark,
        });
      }
    }

    if (isCallRefesh) {
      // await getWorkerDatas();
      // toggle_refresh();
    }

    return true;
  }

  async function handleSummit() {
    if (await onSaveByWorkerByDay()) {
      if (daily_Info) {
        try {
          await setSummits([daily_Info.id]);
          toggle_refresh();

          // notification["success"]({
          //    message: "Submit",
          //    description: "Submitted Successfully !",
          // });
        } catch (error) {
          notification['error']({
            message: 'Submit',
            description: 'Submit failed !',
          });
        }
      }
    }
    setIsModalVisible(false);
  }

  return (
    <>
      <Modal
        title='Update Timesheet'
        visible={isModalVisible}
        onOk={handleSummit}
        onCancel={handleCancel}
      >
        <WorkerShifts
          id={daily_Info.id}
          trade={daily_Info.trade}
          doSave={save}
          idAllocation={daily_Info.id}
          idWorker={daily_Info.idWorker}
          isDisabled={daily_Info.isDisabled}
          date={new Date(daily_Info.date)}
          levels={levels}
          blocks={blocks}
          supervisors={supervisors}
          allCostCodes={allCostCodes}
          shifts={daily_Info.shifts}
          updateShifts={updateShifts}
        />
      </Modal>
    </>
  );
};

export default EditWorkerTimeSheet;
