import { Center, Heading, HStack, Spinner } from 'native-base';
import React from 'react';





const Loading = (props: any) => {


   return (
      <Center height={'100%'} justifyContent='center'>
         <HStack space={2}>
            <Heading color='yellow.600'>Idd App</Heading>
            <Spinner color='yellow.600' accessibilityLabel='Loading posts' />
         </HStack>
      </Center>
   );
};



export default Loading;








