import _ from 'lodash';
import moment from 'moment';






const getPartsCombine = (index: number, parts: Array<any>) => {
   let output = '';
   for (let i = 2; i <= index; i++) {
      output += `/${parts[i]}`;
   };
   if (output[0] === '/') {
      output = output.slice(1, output.length);
   };
   return output;
};

const convertTradeName = (name: string) => {
   if (name === 'ARC') return 'ARCHI';
   if (name === 'MEP') return 'M&E';
   if (name === 'C&S') return 'C&S';
   if (name === 'PC') return 'PRECAST';
   if (name === 'PRO') return 'PROJECT PROGRESS MEETING';
   if (name === 'TEC') return 'TECHNICAL MEETING';
   if (name === 'ICE') return 'ICE MEETING';
};

export const getRefStringWithVersion = (obj: any, refType: string) => {
   return obj['revision'] === '0' ? obj[`${refType}Ref`] : obj[`${refType}Ref`] + obj['revision'];
};



export const getDataForRfaSheet = (rowsAllAndHistory: Array<any>, rfaData: Array<any>) => {


   const allTradeAndOverall = ['Overall', ...tradeArrayForm];

   let refStatistics: any = {};

   allTradeAndOverall.forEach((item: any) => {
      refStatistics[item] = {
         'Overdue': 0,
         'Due in the next 1 - 3 days': 0,
         'Due in the next 4 - 14 days': 0,
         'Replied': 0
      };
   });



   let rowsWithRFACurrentAndHistory = rowsAllAndHistory.filter(x => x.rfaNumber);

   let treeViewRfaOutput: any[] = [];

   const allSubmittedRfa = [...new Set(rowsWithRFACurrentAndHistory.map(r => r['rfaNumber']))];

   allSubmittedRfa.forEach(rfaNumber => {
      const allParts = rfaNumber.split('/');

      allParts.forEach((part: string, i: number) => {
         if (i >= 2 && i < allParts.length - 1) {
            const nodeId = getPartsCombine(i, allParts);

            const allPartsNodeId = nodeId.split('/');

            if (!treeViewRfaOutput.find(x => x.id === nodeId)) {
               treeViewRfaOutput.push({
                  id: nodeId,
                  title: allPartsNodeId[allPartsNodeId.length - 1],
                  parentId: i === 2 ? 'PROJECT_ID' : nodeId.slice(0, nodeId.length - part.length - 1),
                  treeLevel: i,
                  expanded: true,
               });
            };
         };
      });


      let allDwgs = rowsWithRFACurrentAndHistory.filter(dwg => dwg['rfaNumber'] === rfaNumber);
      const allRfaRef = [...new Set(allDwgs.map(x => x['RFA Ref'] || '').filter(x => x))].sort();

      let btnTextArray = allRfaRef.map(rfa => {
         return rfa.slice(rfaNumber.length, rfa.length) || '0';
      });
      treeViewRfaOutput.push({
         id: rfaNumber,
         'rfaNumber': rfaNumber,
         'btn': [...new Set(btnTextArray)].sort(),
         treeLevel: allParts.length - 1,
         expanded: true,
         parentId: getPartsCombine(allParts.length - 2, allParts)
      });


      const rfaRef = allRfaRef[allRfaRef.length - 1]; // GET LATEST VERSION


      const disciplineLevel1 = rfaRef.split('/')[2];
      const disciplineLevel1Name = convertTradeName(disciplineLevel1);

      if (disciplineLevel1Name) {

         const oneRowInRFA = allDwgs.find(dwg => dwg['RFA Ref'] === rfaRef) || {};
         const rfaFound = rfaData.find(x => getRefStringWithVersion(x, 'rfa') === rfaRef);

         if (rfaFound) {
            const consultantLeadName = (getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'consultantMustReply') || [])[0];
            if (consultantLeadName) {
               let consultantLeadReplyStatus = getInfoValueFromRefDataForm(oneRowInRFA, 'reply', 'rfa', 'status', consultantLeadName);
               if (consultantLeadReplyStatus) {
                  refStatistics[disciplineLevel1Name]['Replied']++;
                  refStatistics['Overall']['Replied']++;
               } else {
                  const nosOfDate = compareDatesForm(moment(oneRowInRFA['Consultant Reply (T)'], 'DD/MM/YY').toDate());
                  if (nosOfDate >= 0 && nosOfDate < 3) {
                     refStatistics[disciplineLevel1Name]['Due in the next 1 - 3 days']++;
                     refStatistics['Overall']['Due in the next 1 - 3 days']++;
                  } else if (nosOfDate < 0) {
                     refStatistics[disciplineLevel1Name]['Overdue']++;
                     refStatistics['Overall']['Overdue']++;
                  } else {
                     refStatistics[disciplineLevel1Name]['Due in the next 4 - 14 days']++;
                     refStatistics['Overall']['Due in the next 4 - 14 days']++;
                  };
               };
            };
         };
      };
   });

   treeViewRfaOutput = treeViewRfaOutput.sort((a, b) => a.id > b.id ? 1 : -1);

   rowsWithRFACurrentAndHistory = _.sortBy(rowsWithRFACurrentAndHistory, ['Rev', 'Drawing Number']);

   return {
      rowsDataRFA: rowsWithRFACurrentAndHistory,
      treeViewRFA: treeViewRfaOutput,
      refStatistics,
   };

};


export const convertRowHistoryData = (dataRowsHistory: Array<any>, headers: Array<any>) => {
   return dataRowsHistory.map(r => {
      const { history } = r;
      if (history) {
         let data: any = { id: r._id, row: r.row };
         Object.keys(history).forEach(key => {
            if (key === 'rfaNumber' || key.includes('reply-rfa-') || key.includes('submission-rfa-')) {
               data[key] = history[key];
            } else {
               const header = headers.find(hd => hd.key === key);
               if (header) {
                  data[header.text] = history[key];
               };
            };
         });
         return data;
      };
   });
};

export const compareDatesForm = (date: Date) => {
   if (!date) return 1;
   else {
      const delta = moment(date).diff(moment(), 'minutes') / (60 * 24);
      return delta;
   };
};

export const getKeyTextForSheet = (pageSheetTypeName: string) => {
   return pageSheetTypeName === 'page-rfa' ? 'rfa'
      : pageSheetTypeName === 'page-rfam' ? 'rfam'
         : pageSheetTypeName === 'page-cvi' ? 'cvi'
            : pageSheetTypeName === 'page-rfi' ? 'rfi'
               : pageSheetTypeName === 'page-dt' ? 'dt'
                  : pageSheetTypeName === 'page-mm' ? 'mm'
                     : 'n/a';
};

export const getFileNameFromLink = (link: string) => {
   if (!link) return '';
   const parts = link.split('/');
   return parts[parts.length - 1];
};


export const tradeArrayForm = ['ARCHI', 'C&S', 'M&E', 'PRECAST'];

export const tradeArrayMeetingMinutesForm = ['PROJECT PROGRESS MEETING', 'TECHNICAL MEETING', 'ICE MEETING'];

export const convertTradeCodeInverted = (trade: string) => {
   if (trade === 'ARC') return 'ARCHI';
   if (trade === 'C&S') return 'C&S';
   if (trade === 'MEP') return 'M&E';
   if (trade === 'PC') return 'PRECAST';
};

export const getInfoValueFromRefDataForm = (obj: any, typeSubmit: string, typeForm: string, info: string, company: string = '') => {
   for (const key in obj) {
      if (key.includes(
         typeSubmit === 'reply'
            ? `${typeSubmit}-${typeForm}-${info}-${company}`
            : `${typeSubmit}-${typeForm}-${info}-` // must have '-' ending 
      )) {
         return obj[key];
      };
   };
};

export const getDrawingStatusByConsultant = (row: any, pageSheetTypeName: string, rfaData: any) => {

   const refType = getKeyTextForSheet(pageSheetTypeName);

   if (pageSheetTypeName === 'page-rfa' || pageSheetTypeName === 'page-spreadsheet') {
      const rfaFound = rfaData.find((x: any) => getRefStringWithVersion(x, 'rfa') === row['RFA Ref']);
      if (rfaFound) {
         const consultantLead = (getInfoValueFromRefDataForm(rfaFound, 'submission', 'rfa', 'consultantMustReply') || [])[0];
         if (consultantLead) {
            return getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'status', consultantLead) || 'Consultant reviewing';
         };
      };
   } else {
      const consultantLead = (getInfoValueFromRefDataForm(row, 'submission', refType, 'consultantMustReply') || [])[0];
      if (consultantLead) {
         return getInfoValueFromRefDataForm(row, 'reply', refType, 'status', consultantLead) || 'Consultant reviewing';
      };
   };
};

export const getDataForMultiFormSheet = (rows: Array<any>, pageSheetTypeName: string) => {

   const allTradeAndOverall = ['Overall', ...(pageSheetTypeName === 'page-mm' ? tradeArrayMeetingMinutesForm : tradeArrayForm)];

   let refStatistics: any = {};
   allTradeAndOverall.forEach(item => {
      refStatistics[item] = {
         'Overdue': 0,
         'Due in the next 1 - 3 days': 0,
         'Due in the next 4 - 14 days': 0,
         'Replied': 0
      };
   });


   const refType = getKeyTextForSheet(pageSheetTypeName);

   let rowsOutput: any[] = [];
   let treeViewRefOutput: any[] = [];

   const allSubmittedRef = [...new Set(rows.map(r => r[`${refType}Ref`]))].sort();

   allSubmittedRef.forEach(refText => {
      const allParts = refText.split('/');
      allParts.forEach((part: string, i: number) => {
         if (i >= 2 && i < allParts.length - 1) {
            const nodeId = getPartsCombine(i, allParts);

            const allPartsNodeId = nodeId.split('/');

            if (!treeViewRefOutput.find(x => x.id === nodeId)) {
               treeViewRefOutput.push({
                  id: nodeId,
                  treeLevel: i,
                  expanded: true,
                  title: allPartsNodeId[allPartsNodeId.length - 1],
                  parentId: i === 2 ? 'PROJECT_ID' : nodeId.slice(0, nodeId.length - part.length - 1)
               });
            };
         };
      });

      let allDwgs = rows.filter(row => row[`${refType}Ref`] === refText);

      const btnTextArray = [...new Set(allDwgs.map(x => x['revision']))].sort();


      allDwgs = allDwgs.map(row => {
         const rowOutput = { ...row };
         rowOutput['btn'] = btnTextArray;
         rowOutput['parentId'] = getDisciplineOfRefString(refText);
         return rowOutput;
      });
      rowsOutput = [...rowsOutput, ...allDwgs];


      const disciplineLevel1 = refText.split('/')[2];
      const disciplineLevel1Name = convertTradeName(disciplineLevel1);

      if (disciplineLevel1Name) {
         if (pageSheetTypeName !== 'page-mm') {
            const latestVersion = btnTextArray[btnTextArray.length - 1];
            const rowFound = allDwgs.find(x => x.revision === latestVersion);
            if (rowFound) {
               const listConsultantMustReply = getInfoValueFromRefDataForm(rowFound, 'submission', refType, 'consultantMustReply') || [];
               const consultantLead = listConsultantMustReply[0];
               if (consultantLead) {
                  const consultantLeadStatus = getInfoValueFromRefDataForm(rowFound, 'reply', refType, 'status', consultantLead);

                  if (consultantLeadStatus) {
                     refStatistics[disciplineLevel1Name]['Replied']++;
                     refStatistics['Overall']['Replied']++;
                  } else {

                     const dueDate = getInfoValueFromRefDataForm(rowFound, 'submission', refType, 'due');
                     const nosOfDate = compareDatesForm(dueDate);

                     if (nosOfDate < 0) {
                        refStatistics[disciplineLevel1Name]['Overdue']++;
                        refStatistics['Overall']['Overdue']++;

                     } else if (nosOfDate >= 0 && nosOfDate < 3) {
                        refStatistics[disciplineLevel1Name]['Due in the next 1 - 3 days']++;
                        refStatistics['Overall']['Due in the next 1 - 3 days']++;
                     } else {
                        refStatistics[disciplineLevel1Name]['Due in the next 4 - 14 days']++;
                        refStatistics['Overall']['Due in the next 4 - 14 days']++;
                     };
                  };
               };
            };
         } else {
            refStatistics[disciplineLevel1Name]['Replied']++;
            refStatistics['Overall']['Replied']++;
         };
      };
   });

   treeViewRefOutput = treeViewRefOutput.sort((a, b) => a.id > b.id ? 1 : -1);

   return {
      rowsData: rowsOutput,
      treeView: treeViewRefOutput,
      refStatistics
   };
};



export const arrangePanelOutputFinal = (stateRow: any, pageSheetTypeName: string) => {

   let output: object[] = [];

   if (pageSheetTypeName === 'page-rfa') {

      const { rowsAll, rfaData, modeFilter } = stateRow;

      const rowsFiltered = getFilterRows(rowsAll, modeFilter, pageSheetTypeName, rfaData);


      const listRef = [... new Set(rowsFiltered.map((x: any) => x['rfaNumber']))].sort();

      listRef.forEach((ref: any) => {
         const rowsInThisRef = rowsFiltered.filter((r: any) => r['rfaNumber'] === ref);

         let arrayBtnVersion: Array<string> = [];

         rowsInThisRef.map((row: any) => {
            const rfaRef = row['RFA Ref'];
            if (!row['rfaNumber'] || !rfaRef) return;

            if (row['rfaNumber'] === rfaRef) {
               arrayBtnVersion.push('0');
            } else {
               arrayBtnVersion.push(rfaRef.slice(rfaRef.length - 1, rfaRef.length) || '0');
            };
         });

         arrayBtnVersion = [...new Set(arrayBtnVersion)].sort();

         output.push({
            arrayBtnVersion,
            rowsInThisRef,
            refNumber: ref,
            rfaData
         });
      });

   } else {
      const refType = getKeyTextForSheet(pageSheetTypeName);

      const { rowsAll, modeFilter } = stateRow;

      const rowsFiltered = getFilterRows(rowsAll, modeFilter, pageSheetTypeName, null);

      const listRef = [... new Set(rowsFiltered.map((x: any) => x[`${refType}Ref`]).filter((x: any) => x))].sort();

      listRef.forEach((ref: any) => {
         const rowsInThisRef = rowsFiltered.filter((r: any) => r[`${refType}Ref`] === ref);

         let arrayBtnVersion: Array<string> = [];

         rowsInThisRef.map((row: any) => {
            arrayBtnVersion.push(row['revision']);
         });

         arrayBtnVersion = [...new Set(arrayBtnVersion)].sort();

         output.push({
            arrayBtnVersion,
            rowsInThisRef,
            refNumber: ref,
         });
      });
   };
   return output;
};



export const getDisciplineOfRefString = (str: string) => {
   const allParts = str.split('/');
   let discp = '';
   allParts.forEach((item, i) => {
      if (i >= 2 && i < allParts.length - 1) {
         discp += (i === 2 ? `${item}` : `/${item}`);
      };
   });
   return discp;
};

export const getAllTreeNodeAbove = (str: any) => {
   let output: string[] = [];

   const allParts = str.split('/');

   allParts.forEach((part: string, i: number) => {
      let text = '';
      for (let j = 0; j <= i; j++) {
         text += j === 0 ? allParts[0] : `/${allParts[j]}`;
      };
      output.push(text);
   });
   return output;
};


export const getCompanyNameShort = (name: string) => {

   let str = name.trim().replace(/[^a-zA-Z0-9 ]/g, ''); // keep number and letter only

   const parts = str.split(' ');
   if (parts.length >= 3) {
      return parts[0].slice(0, 1).toUpperCase() + parts[1].slice(0, 1).toUpperCase() + parts[2].slice(0, 1).toUpperCase();
   } else if (parts.length === 2) {
      if (parts[0].length === 1) {
         return parts[0].slice(0, 1).toUpperCase() + parts[1].slice(0, 2).toUpperCase();
      } else {
         return parts[0].slice(0, 2).toUpperCase() + parts[1].slice(0, 1).toUpperCase();
      };
   } else if (parts.length === 1) {
      
      return str.slice(0, 3).toUpperCase();
   };
};



const getFilterRows = (rowsAll: object[], modeFilter: any, pageSheetTypeName: string, rfaData: any) => {

   const refType = getKeyTextForSheet(pageSheetTypeName);

   const { statusArray, disArray, overdueArray, searchText, dateFilter } = modeFilter;

   return rowsAll
      .filter((row: any) => {
         return row['rfaNumber'] || refType !== 'rfa'
      })
      .filter((row: any) => {
         if (!(disArray.length > 0)) return true;

         let isMetFilterCondition = false;

         const refNum = refType === 'rfa' ? row['rfaNumber'] : row[`${refType}Ref`];
         disArray.forEach((flt: string) => {
            if (refNum.includes(flt)) {
               isMetFilterCondition = true;
            };
         });
         return isMetFilterCondition;
      })
      .filter((row: any) => {
         if (!(statusArray.length > 0)) return true;

         let leadStatus;

         if (refType === 'rfa') {
            const rfaInfo = rfaData.find((x: any) => getRefStringWithVersion(x, 'rfa') === row['RFA Ref']) || {};
            const leadConsultant = (getInfoValueFromRefDataForm(rfaInfo, 'submission', 'rfa', 'consultantMustReply') || [])[0];
            leadStatus = getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'status', leadConsultant);
         } else {
            const leadConsultant = (getInfoValueFromRefDataForm(row, 'submission', refType, 'consultantMustReply') || [])[0];
            leadStatus = getInfoValueFromRefDataForm(row, 'reply', refType, 'status', leadConsultant);
         };
         if (leadStatus) {
            return statusArray.indexOf(leadStatus) !== -1;
         } else {
            return statusArray.indexOf('Consultant reviewing') !== -1;
         };

      })
      .filter((row: any) => {
         if (!(overdueArray.length > 0)) return true;

         let leadStatus;

         if (refType === 'rfa') {
            const rfaInfo = rfaData.find((x: any) => getRefStringWithVersion(x, 'rfa') === row['RFA Ref']) || {};
            const leadConsultant = (getInfoValueFromRefDataForm(rfaInfo, 'submission', 'rfa', 'consultantMustReply') || [])[0];
            leadStatus = getInfoValueFromRefDataForm(row, 'reply', 'rfa', 'status', leadConsultant);
         } else {
            const leadConsultant = (getInfoValueFromRefDataForm(row, 'submission', refType, 'consultantMustReply') || [])[0];
            leadStatus = getInfoValueFromRefDataForm(row, 'reply', refType, 'status', leadConsultant);
         };


         if (leadStatus) {
            return overdueArray.indexOf('Replied') !== -1;
         } else {
            let nosOfDate;
            if (refType === 'rfa') {
               nosOfDate = compareDatesForm(moment(row['Consultant Reply (T)'], 'DD/MM/YY').toDate());
            } else {
               nosOfDate = compareDatesForm(getInfoValueFromRefDataForm(row, 'submission', refType, 'due'));
            };


            if (nosOfDate >= 0 && nosOfDate < 3) {
               return overdueArray.indexOf('Due in the next 1 - 3 days') !== -1;
            } else if (nosOfDate < 0) {
               return overdueArray.indexOf('Overdue') !== -1;
            } else {
               return overdueArray.indexOf('Due in the next 4 - 14 days') !== -1;
            };
         };
      })
      .filter((row: any) => {
         if (!dateFilter) return true;


         const dateFilterFormat = moment(dateFilter).format('DD/MM/YY');
         for (const key in row) {
            if (key.includes('-date-')) {
               if (moment(row[key]).format('DD/MM/YY') === dateFilterFormat) {
                  return true;
               };
            };
         };
      })
      .filter((row: any) => {
         if (!searchText) return true;

         let emailTitle, refText;

         if (refType === 'rfa') {
            const rfaInfo = rfaData.find((x: any) => getRefStringWithVersion(x, 'rfa') === row['RFA Ref']) || {};
            refText = row['RFA Ref'] || '';
            emailTitle = getInfoValueFromRefDataForm(rfaInfo, 'submission', 'rfa', 'emailTitle') || '';
         } else {
            refText = row[`${refType}Ref`];
            emailTitle = getInfoValueFromRefDataForm(row, 'submission', refType, 'emailTitle') || '';
         };

         return emailTitle.toLowerCase().includes(searchText.toLowerCase()) || refText.toLowerCase().includes(searchText.toLowerCase());
      });
};



export const getTabArrayNameForPage = (disciplineForm: Array<any>, refType: string) => {

   if (refType === 'rfc') return [];

   let output: any = [];
   disciplineForm.forEach(text => {
      const parts = text.split('/');
      const dis = parts[0];
      output.push(dis);
   });

   output = [...new Set(output)];

   const arrayMm = ['PRO', 'TEC', 'ICE'];

   if (refType === 'mm') {
      return output.filter((x: string) => arrayMm.indexOf(x) !== -1);
   } else {
      return output.filter((x: string) => arrayMm.indexOf(x) === -1);
   };
};