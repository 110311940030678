import { useState } from 'react';
import {
  Box,
  Heading,
  VStack,
  FormControl,
  Input,
  Button,
  useColorMode,
  Center,
} from 'native-base';
import { loginAdmin } from '../../features/app/userAPI';
import { login } from '../../features/app/userSlice';
import { message } from 'antd';
import { useAppDispatch } from '../../app/hooks';
import {checkWohhupUser} from '../module_BimApp/AdminFunction'
export default function LoginPage(props: any) {
  const dispatch = useAppDispatch();
  const [formData, setData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const { colorMode } = useColorMode();
  const handleLogin = () => {
    if (validate()) {
      setErrors({});
      setLoading(true);
      loginAdmin(formData)
        .then((res: any) => {
          dispatch(login(res));
          props.history.push('/projects');
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          message.warning(err.response.data.errors.global);
        });
    }
  };
  const validate = () => {
    if (
      formData.email === undefined ||
      formData.email === '' ||
      formData.password === undefined ||
      formData.password === ''
    ) {
      if (formData.email === undefined || formData.email === '')
        setErrors({
          ...errors,
          email: 'Email is required',
        });
      if (formData.password === undefined || formData.password === '')
        setErrors({
          ...errors,
          password: 'Password is required',
        });

      return false;
    } else if (
      formData.email !== 'admin@wohhup.com' &&
      checkWohhupUser(formData.email)
    ) {
      setErrors({
        ...errors,
        email: `Can't login with wohhup account`,
      });
      return false;
    }

    return true;
  };
  return (
    <div style={{position:'fixed', height:'100%', width:'100%'}}>
    <Center height={'100%'} justifyContent='center' >
      <Box
        p={2}
        w='90%'
        mx='auto'
        bg={colorMode === 'light' ? 'gray.50' : 'gray.900'}
      >
        <Heading size='lg' color='yellow.600'>
          IDD APP
        </Heading>
        <Heading color='muted.400' size='xs'>
          Sign in to continue!
        </Heading>
        <VStack space={2} mt={5}>
          <FormControl isRequired isInvalid={'email' in errors}>
            <FormControl.Label
              _text={{ color: 'muted.700', fontSize: 'sm', fontWeight: 600 }}
            >
              Email ID
            </FormControl.Label>
            <Input
              onChangeText={(value) => setData({ ...formData, email: value })}
            />

            {'email' in errors && (
              <FormControl.ErrorMessage
                _text={{ fontSize: 'xs', color: 'error.500', fontWeight: 500 }}
              >
                {errors.email}
              </FormControl.ErrorMessage>
            )}
          </FormControl>
          <FormControl mb={5} isRequired isInvalid={'password' in errors}>
            <FormControl.Label
              _text={{ color: 'muted.700', fontSize: 'sm', fontWeight: 600 }}
            >
              Password
            </FormControl.Label>
            <Input
              type='password'
              onChangeText={(value) =>
                setData({ ...formData, password: value })
              }
            />
            {'password' in errors && (
              <FormControl.ErrorMessage
                _text={{ fontSize: 'xs', color: 'error.500', fontWeight: 500 }}
              >
                {errors.password}
              </FormControl.ErrorMessage>
            )}
          </FormControl>
          <VStack space={2}>
            <Button
              isLoading={loading}
              onPress={handleLogin}
              colorScheme='orange'
              bg='yellow.600'
              _text={{ color: 'white' }}
            >
              Login
            </Button>
          </VStack>
        </VStack>
      </Box>
    </Center>
    </div>
  );
}
