import BaseResponse from '../models/BaseResponse';
import axios from './axiosClient';
import axiosOld from './axiosOldClient';

async function getMyInfomation(): Promise<any> {
  return await axiosOld.post('/api/users/get-me-information');
}

async function searchAllSupervisor(payload: any): Promise<BaseResponse> {
  return await axios.get('/employee/supervisor', {
    params: payload,
  });
}

async function getSupervisorByEmail(payload: any): Promise<BaseResponse> {
  return await axios.post('/employee/supervisor-by-email', payload);
}

async function searchLevelZone(payload: any): Promise<BaseResponse> {
  return await axios.get('/position', { params: payload });
}

async function searchCostCode(payload: any): Promise<BaseResponse> {
  return await axios.get('/cost/by-worker', { params: payload });
}

async function searchAllCostCode(payload: any): Promise<BaseResponse> {
  return await axios.get('/cost', { params: payload });
}

async function getWorkersByDateAndSupervisor(
  id: number,
  payload: any
): Promise<BaseResponse> {
  return await axios.get(`/worker-shift/${id}/get-workers-by-date-supervisor`, {
    params: payload,
  });
}

async function getWorkersByMonthAndSupervisor(
  id: number,
  payload: any
): Promise<BaseResponse> {
  return await axios.get(
    `/worker-shift/${id}/get-workers-by-month-supervisor`,
    { params: payload }
  );
}

async function getAllShiftsOfAWorker(
  id: number,
  payload: Date
): Promise<BaseResponse> {
  return await axios.get(`/worker-shift/${id}/all-shifts-of-worker`, {
    params: payload,
  });
}

async function addShiftToWorker(payload: any): Promise<BaseResponse> {
  return await axios.post(`/worker-shift/add-shift-to-worker`, payload);
}

async function updateShiftToWorker(
  id: number,
  payload: any
): Promise<BaseResponse> {
  return await axios.put(`/worker-shift/${id}/update-shift-to-worker`, payload);
}

async function updateShiftsToWorker(payload: any): Promise<BaseResponse> {
  return await axios.put(`/worker-shift/update-shifts-to-worker`, {
    data: payload,
  });
}

async function updateIsWorkOverLunch(payload: any): Promise<BaseResponse> {
  return await axios.put(
    `/worker-allocation/update-is-work-over-lunch`,
    payload
  );
}

async function updateIsWorkOverTeaTime(payload: any): Promise<BaseResponse> {
  return await axios.put(
    `/worker-allocation/update-is-work-over-tea-time`,
    payload
  );
}

async function updateIsDayOff(payload: any): Promise<BaseResponse> {
  return await axios.put(`/worker-allocation/update-is-day-off`, payload);
}

async function updateIsWorkOvernight(payload: any): Promise<BaseResponse> {
  return await axios.put(
    `/worker-allocation/update-is-work-overnight`,
    payload
  );
}

async function updateRemark(payload: any): Promise<BaseResponse> {
  return await axios.put(`/worker-allocation/update-remark`, payload);
}

async function setTradeForWorker(id: any, payload: any): Promise<BaseResponse> {
  return await axios.put(
    `/worker-allocation/${id}/set-trade-for-worker`,
    payload
  );
}

async function deleteShiftOfWorker(idShift: number): Promise<BaseResponse> {
  return await axios.delete(`/worker-shift/${idShift}/delete-shift-of-worker`);
}

async function setBorrowSupervisor(
  id: number,
  payload: any
): Promise<BaseResponse> {
  return await axios.put(`/worker-allocation/${id}/set-supervisor`, payload);
}

async function setSummits(payload: any): Promise<BaseResponse> {
  return await axios.post(`/worker-allocation/set-summits`, {
    data: payload,
  });
}

async function getSubmitStatusForRangeDays(
  payload: any
): Promise<BaseResponse> {
  return await axios.get(`/worker-allocation/get-submit-status-by-supervisor`, {
    params: payload,
  });
}



async function searchBlockUnit(payload: any): Promise<BaseResponse> {
  return await axios.get('/block', { params: payload });
}

async function apiCreateTimeCard(payload: any): Promise<BaseResponse> {
  return await axios.post('/dashboard-data/create-time-card', payload);
}

async function apiGetPublicUrlS3(payload: any): Promise<BaseResponse> {
  return await axiosOld.get('/api/issue/get-public-url', { params: payload });
}

async function getAvatar(payload: any): Promise<BaseResponse> {
  return await axiosOld.get('/api/helper/get-avatar-worker', { params: payload });
}
export {
  getMyInfomation,
  searchAllSupervisor,
  searchLevelZone,
  searchCostCode,
  getWorkersByDateAndSupervisor,
  getWorkersByMonthAndSupervisor,
  getAllShiftsOfAWorker,
  addShiftToWorker,
  deleteShiftOfWorker,
  updateShiftToWorker,
  updateShiftsToWorker,
  setBorrowSupervisor,
  setSummits,
  updateIsWorkOverLunch,
  updateIsWorkOverTeaTime,
  getSubmitStatusForRangeDays,
  getSupervisorByEmail,
  getAvatar,
  searchAllCostCode,
  setTradeForWorker,
  updateIsDayOff,
  updateRemark,
  updateIsWorkOvernight,
  searchBlockUnit,
  apiCreateTimeCard,
  apiGetPublicUrlS3,
};
