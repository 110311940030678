import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface ProjectState {
  value: Object;
}

const initialState: ProjectState = {
  value: {},
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setProject: (state, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
  },
});

export const {setProject} = projectSlice.actions;

export const selectProject = (state: RootState) => state.project.value;

export default projectSlice.reducer;
