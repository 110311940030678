import { Route, Redirect } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { selectUser } from '../features/app/userSlice';
type UserRouteProps = {
  path: string;
  Component: any;
  exact: boolean;
};
const UserRoute = ({ Component, ...rest }: UserRouteProps) => {
  const user = useAppSelector<any>(selectUser);
  const isAuthenticated = !!user.email;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to='/' />
      }
    />
  );
};

export default UserRoute;
