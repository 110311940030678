import create from "zustand";
import _ from "lodash";
import { WorkerTimesheetModel } from "../components/module_Worker/models/WorkerTimeSheetModel";

interface worker_ts_State {
   worker_ts: any[];
   set_Data: (datas: any[]) => void;
   add_Object: (obj: any) => void;
   delete_Object: (id: string) => void;
   update_Shift: (id: any, newShift: any) => void;
   toggleIsFold: (id: string) => void;
}

const worker_ts_store = create<worker_ts_State>((set) => ({
   // initial state
   worker_ts: [],
   // methods for manipulating state
   set_Data: (datas) => set((prevState) => ({ worker_ts: datas.map((obj) => ({ ...obj })) })),
   add_Object: (obj) => set((prevState) => ({ worker_ts: [...prevState.worker_ts, obj] })),
   delete_Object: (id) => set((prevState) => ({ worker_ts: prevState.worker_ts.filter((obj) => obj.id !== id) })),
   update_Shift: (id, newShift) =>
      set((prevState) => {
         const newData = _.clone(prevState);
         newData.worker_ts.map((item) => {
            {
               item.dateInfo.map((info: any) => {
                  if (info.id === id) {
                     info.shifts = newShift;
                  }
               });
            }
         });
         // newData.worker_ts.forEach((element: any) => {
         //    const daily = element.dateInfo;
         //    let item = daily.find((x: any) => x.id === id);
         //    if (item !== undefined && item !== -1) {
         //       item.shifts = newShift;
         //       console.log(item);
         //    }
         // });
         // let item = newData.worker_ts.find((x) => {
         //    x.dailyInfo.find((y: any) => y.id === id);
         // });
         // if (item) {
         //    item.shifts = newShift;
         // }

         return newData;
      }),

   toggleIsFold: (id) => {
      set((prevState) => ({
         worker_ts: prevState.worker_ts.map((ts) => (ts.id === id ? ({ ...ts, isFold: !ts.isFold } as any) : ts)),
      }));
   },
}));

interface worker_refresh {
   refresh: boolean;
   toggle_refresh: () => void;
}

const worker_refresh_store = create<worker_refresh>((set) => ({
   refresh: false,
   toggle_refresh: () => set((prevState) => ({ refresh: !prevState.refresh })),
}));

interface worker_shown_not_keyin {
   not_keyin: boolean;
   toggle_not_keyin: () => void;
}

const worker_shown_not_keyin_store = create<worker_shown_not_keyin>((set) => ({
   not_keyin: false,
   toggle_not_keyin: () => set((prevState) => ({ not_keyin: !prevState.not_keyin })),
}));

export { worker_ts_store, worker_refresh_store, worker_shown_not_keyin_store };
